import { Dropdown, Menu, Checkbox, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { COOKIE_NAME_DASHBOARD_EXCLUDED_COLUMNS } from "common/constants";

export function ColumnPickerDropdown({
  isColumnDropdownVisible,
  columns,
  excludedColumns,
  visibleColumns,
  onToggle,
  onChange,
  organisationDetails,
}) {
  const hiddenColumnCount = columns.length - visibleColumns.length;

  function onCheckboxChange(e, column) {
    e.stopPropagation();

    let newExcludedColumns = {
      ...excludedColumns,
      [column.key]: !excludedColumns[column.key],
    };

    onChange(newExcludedColumns, () => {
      window.localDatabase.setItem(
        `${COOKIE_NAME_DASHBOARD_EXCLUDED_COLUMNS}-${organisationDetails.id}`,
        JSON.stringify(excludedColumns)
      );
    });
  }

  return (
    <Dropdown
      open={isColumnDropdownVisible}
      className="columns-dropdown-button"
      overlayClassName="table-column-dropdown-overlay"
      overlay={
        <Menu>
          {[...columns]
            .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
            .map((column, index) => {
              let label = column.title;
              if (index === 0) {
                label = "Row number";
              }
              return (
                <Menu.Item key={column.key}>
                  <Checkbox checked={!excludedColumns[column.key]} onChange={(e) => onCheckboxChange(e, column)}>
                    {label}
                  </Checkbox>
                </Menu.Item>
              );
            })}
        </Menu>
      }
    >
      <Button icon={<DownOutlined />} onClick={onToggle}>
        Columns {hiddenColumnCount > 0 && <span style={{ marginLeft: "0.2rem" }}>({hiddenColumnCount} hidden)</span>}
      </Button>
    </Dropdown>
  );
}
