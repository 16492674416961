import { useState } from "react";
import { Form, Modal, Button, Input, Space, Select, Checkbox } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import "./TaskStatusModal.scss";

export function TaskStatusModal({ onClose, visible, apiUser, organisationDetails, statusDetails, index }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ name, type, canSkipReview }) {
    setIsLoading(true);

    await callGraphQLSimple({
      message: `Could not create ${getSimpleLabel("task")} status`,
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: apiUser.organisation,
          taskStatuses: organisationDetails.taskStatuses?.map((crtStatus, crtIndex) => {
            if (crtIndex === index) {
              return {
                name,
                type,
                canSkipReview,
              };
            } else {
              return crtStatus;
            }
          }),
        },
      },
    });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title={`Edit ${getSimpleLabel("task")} status`}
      open={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="edit-task-status-modal"
    >
      <Form form={form} initialValues={statusDetails} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            label="Name"
            name="name"
            className="task-status-name"
            rules={[
              {
                required: true,
                message: `You must choose a name for the ${getSimpleLabel("task")} status`,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Type" name="type" className="task-status-type">
            <Select>
              <Select.Option value="TO_DO">To Do</Select.Option>
              <Select.Option value="IN_PROGRESS">In Progress</Select.Option>
              <Select.Option value="DONE">Done</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Skip internal review" name="canSkipReview" valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: TaskStatusModal,
  subscriptions: ["organisationDetails"],
});
