import { useState } from "react";
import { Button } from "antd";
import { withRouter } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";

import AttachmentTemplateList from "../AttachmentTemplateList/AttachmentTemplateList";
import ProjectMainSettings from "./ProjectMainSettings/ProjectMainSettings";
import ProjectStatusList from "./ProjectStatusList/ProjectStatusList";
import Card from "Card/Card";
import ProjectStatusModal from "Modals/ProjectStatusModal/ProjectStatusModal";

import "./ProjectSettingsPage.scss";

export function ProjectSettingsPage(props) {
  const { organisationDetails } = props;
  const [isProjectStatusModalVisible, setIsProjectStatusModalVisible] = useState(false);

  function displayProjectStatuses() {
    const { setProps, context } = props;

    if (!organisationDetails.projectStatuses || organisationDetails.projectStatuses.length === 0) {
      return null;
    }

    return <ProjectStatusList organisationDetails={organisationDetails} setProps={setProps} context={context} />;
  }

  return (
    <div>
      <ProjectMainSettings />
      <Card
        withSpace
        title={`${getSimpleLabel("Project")} statuses`}
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            data-cy="add-task-status-button"
            onClick={() => setIsProjectStatusModalVisible(true)}
          >
            Add {getSimpleLabel("project")} status
          </Button>
        }
      >
        {displayProjectStatuses()}
      </Card>
      <AttachmentTemplateList organisationDetails={organisationDetails} type="project" />
      {isProjectStatusModalVisible && (
        <ProjectStatusModal visible={true} onClose={() => setIsProjectStatusModalVisible(false)} />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ProjectSettingsPage,
    subscriptions: ["organisationDetails"],
  })
);
