import React from "react";
import moment from "moment";
import cx from "classnames";
import { withRouter, Link } from "react-router-dom";
import { Select, Button, Typography, Modal, message, Checkbox, InputNumber, Radio } from "antd";
import { PlusCircleOutlined, CheckCircleOutlined, EditOutlined, CopyOutlined, FileOutlined } from "@ant-design/icons";

import { isAuthorised, getGroupNamesForUser } from "common/permissions";
import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { formatAddress, Marker } from "../../Map/Map";
import changeProjectStatus from "common/changeProjectStatus";

import DatePicker from "DatePicker/DatePicker";
import Input from "Input/Input";
import InfoItem from "InfoItem/InfoItem";
import Explanation from "Explanation/Explanation";
import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import UsersFilter from "UsersFilter/UsersFilter";
import ClientLogo from "ClientLogo/ClientLogo";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import CreatePurchaseOrderModal from "Modals/CreatePurchaseOrderModal/CreatePurchaseOrderModal";
import ChangeClientModal from "Modals/ChangeClientModal/ChangeClientModal";
import MapWrapper from "MapWrapper/MapWrapper";

import "./ProjectSidebar.scss";

export class ProjectSidebar extends React.Component {
  state = {
    isCatLevelModalVisible: false,
    isLinkTaskModalVisible: false,
    isRequestReviewModalVisible: false,
    isCreateTaskModalVisible: false,
    isCreatePurchaseOrderModalVisible: false,
    isChangeClientModalVisible: false,
  };

  getExcludedAssigneeList = () => {
    const { task, users } = this.props;

    if (!task.catLevel) {
      return [];
    }
    const result = users.filter((x) => x.catLevelDesign < task.catLevel).map((x) => x.id);

    return result;
  };

  changeCustomFieldValue = async (field, value) => {
    const { project } = this.props;

    let parent = project;
    let queryName = "updateProject";

    if (Array.isArray(value)) {
      value = JSON.stringify(value);
    }

    let customFields = [...(parent.customFields || [])];
    let fieldWasAlreadySet = customFields.find((x) => x.id === field.id);
    if (fieldWasAlreadySet) {
      customFields.forEach((crtField) => {
        if (crtField.id === field.id) {
          crtField.value = value;
        }
      });
    } else {
      customFields.push({ id: field.id, value });
    }

    await callGraphQLSimple({
      message: "Failed to update field value",
      queryName,
      variables: {
        input: {
          id: parent.id,
          customFields,
        },
      },
    });
  };

  displayCustomFields = () => {
    const { project, organisationDetails, groups, apiUser } = this.props;
    let parent = project;

    const groupNamesForUser = getGroupNamesForUser(apiUser.id, groups);

    return (organisationDetails.settings?.project?.customFields || [])
      .filter((field) => {
        if (!field.groupsThatCanSee || field.groupsThatCanSee.length === 0) {
          return true;
        }

        return field.groupsThatCanSee.some((group) => groupNamesForUser.includes(group));
      })
      .map((field) => {
        let fieldInParent = (parent.customFields || []).find((x) => x.id === field.id);
        let fieldElement = null;

        let labelElement = (
          <>
            {field.label}
            {field.formula && (
              <CopyOutlined
                onClick={async () => {
                  await navigator.clipboard.writeText(fieldInParent.value);
                  message.success(`${field.label} copied`);
                }}
              />
            )}
          </>
        );
        let isDisabled = parent.isArchived || parent.isFinished;

        switch (field.type) {
          case "TEXT":
            fieldElement = (
              <Input
                defaultValue={fieldInParent ? fieldInParent.value : field.defaultValue || ""}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                fireOnChangeWithoutBlurWithDebounce
                disabled={isDisabled || field.isStatic}
                showBorder={!fieldInParent?.value && !field?.defaultValue}
                fullWidth
              />
            );
            break;
          case "TEXTAREA":
            fieldElement = (
              <Input
                defaultValue={fieldInParent ? fieldInParent.value : field.defaultValue || ""}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                fireOnChangeWithoutBlurWithDebounce
                disabled={isDisabled || field.isStatic}
                showBorder={!fieldInParent?.value && !field?.defaultValue}
                multiLine
                fullWidth
                minRows={2}
              />
            );
            break;

          case "CHECKBOX":
            let isChecked = fieldInParent && fieldInParent.value === "checked";
            labelElement = (
              <>
                {field.label}{" "}
                <Checkbox
                  className="custom-field-checkbox"
                  checked={isChecked}
                  onChange={(e) => this.changeCustomFieldValue(field, e.target.checked ? "checked" : null)}
                  disabled={isDisabled}
                >
                  {isChecked ? "Yes" : ""}
                </Checkbox>
              </>
            );
            break;

          case "NUMBER":
            fieldElement = (
              <InputNumber
                defaultValue={fieldInParent ? fieldInParent.value : field.defaultValue || 0}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                disabled={isDisabled}
              />
            );
            break;

          case "RADIO_LIST":
            fieldElement = (
              <Radio.Group
                value={fieldInParent ? fieldInParent.value : field.options[0].value}
                onChange={(e) => this.changeCustomFieldValue(field, e.target.value)}
                disabled={isDisabled}
                options={field.options}
              />
            );
            break;

          case "CHECKBOX_LIST":
            let parsedValue = fieldInParent && fieldInParent.value ? JSON.parse(fieldInParent.value) : null;

            fieldElement = (
              <Checkbox.Group
                value={parsedValue}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                disabled={isDisabled}
                options={field.options}
              />
            );
            break;

          case "SINGLE_FILE":
            labelElement = null;

            fieldElement = (
              <>
                <InfoItem
                  inline
                  label={field.label}
                  value={
                    <>
                      <Button
                        type="dark"
                        icon={<PlusCircleOutlined />}
                        onClick={() => {
                          document.querySelector(`#custom-field-upload-${field.id}`).click();
                        }}
                      >
                        <span>{fieldInParent?.value ? "Replace file" : "Upload file"}</span>
                      </Button>

                      <input
                        id={`custom-field-upload-${field.id}`}
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => {
                          this.onFileInputChange(e, field);
                        }}
                        data-cy="upload-input"
                      />
                    </>
                  }
                  extraContent={
                    fieldInParent?.value && (
                      <Typography.Text
                        className="file-item"
                        onClick={(e) => this.openSingleAttachment(e, fieldInParent?.value)}
                      >
                        <span style={{ marginRight: "0.5rem" }}>
                          <FileOutlined />
                        </span>
                        {fieldInParent?.value?.split("/").slice(-1)[0]}
                      </Typography.Text>
                    )
                  }
                />
              </>
            );
            break;

          case "GEOGRAPHICAL_LOCATION":
            labelElement = null;

            let fullAddressDetails;
            let address;
            let coordinates;

            if (fieldInParent?.value) {
              fullAddressDetails = JSON.parse(fieldInParent?.value);
              address = formatAddress(fullAddressDetails);

              if (fullAddressDetails.coordinates) {
                coordinates = fullAddressDetails.coordinates;
              }
            }

            fieldElement = (
              <>
                <>
                  <InfoItem
                    inline
                    label={field.label}
                    className="custom-field-map-wrapper"
                    value={
                      <div>
                        <Button
                          onClick={() => {
                            this.setState({ isMapModalVisible: true });
                            this.setState({ selectedField: field });
                          }}
                          className="edit-address-button"
                          type="clear"
                          icon={<EditOutlined />}
                        >
                          {fieldInParent?.value ? "Edit address" : "Add address"}
                        </Button>
                      </div>
                    }
                    extraContent={
                      fieldInParent?.value ? (
                        <MapWrapper
                          coordinates={coordinates}
                          isReadOnly={true}
                          address={address}
                          width="100%"
                          height={200}
                        />
                      ) : null
                    }
                  />
                </>
              </>
            );
            break;

          case "USER":
            fieldElement = (
              <UsersFilter
                activateOnHover={true}
                value={fieldInParent?.value}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                disabled={isDisabled}
                maxLabelLength={22}
              />
            );
            break;

          case "DATE":
            fieldElement = (
              <DatePicker
                format="DD-MM-YYYY"
                className="active-on-hover"
                defaultValue={fieldInParent?.value ? moment(fieldInParent?.value) : undefined}
                onChange={(value) => this.changeCustomFieldValue(field, value)}
                placeholder="Not set"
                suffixIcon={null}
              />
            );
            break;

          default:
            fieldElement = null;
            break;
        }

        return (
          <div className={cx("stat", { static: field.isStatic })} key={field.id}>
            <Typography.Text className="stat-label">{labelElement}</Typography.Text>
            {fieldElement && <Typography.Text className="stat-value">{fieldElement}</Typography.Text>}
          </div>
        );
      });
  };

  changeAssignedTo = async (newAssignedTo, project, users) => {
    await callGraphQLSimple({
      message: `Failed to assign ${getSimpleLabel("project")}`,
      queryName: "updateProject",
      variables: {
        input: {
          id: project.id,
          assignedTo: newAssignedTo,
        },
      },
    });
    const targetUser = users.find((x) => x.id === newAssignedTo);

    if (targetUser) {
      message.success({
        content: (
          <Typography.Text>
            {getSimpleLabel("Project")} has been assigned to{" "}
            <b>
              {targetUser.firstName} {targetUser.lastName}
            </b>
          </Typography.Text>
        ),
        className: "update-cat-level-success",
      });
    } else {
      message.success({
        content: <Typography.Text>Project unassigned</Typography.Text>,
        className: "update-cat-level-success",
      });
    }

    await callGraphQLSimple({
      mutation: "createActivityItem",
      message: "Failed to create activity item",
      variables: {
        input: {
          parentId: project.id,
          content: JSON.stringify({
            oldAssignedTo: project.assignedTo,
            assignedTo: targetUser ? targetUser.id : null,
            type: "ASSIGNEE_CHANGED",
          }),
          type: "LIFECYCLE_EVENT",
          organisation: project.organisation,
          author: window.apiUser.id,
        },
      },
    });
  };

  assignProjectToUser = async ({ project, user }) => {
    await callGraphQLSimple({
      message: `Failed to change senior engineer on ${getSimpleLabel("project")}`,
      queryName: "updateProject",
      variables: {
        input: {
          id: project.id,
          assignedTo: user ? user.id : null,
        },
      },
    });

    await callGraphQLSimple({
      message: `Failed to refresh ${getSimpleLabel("project")} details`,
      queryName: "updateProject",
      variables: {
        input: {
          id: project.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      },
    });
  };

  onTeamChange = async (newTeam) => {
    if (!isAuthorised(["PROJECT.CHANGE_TEAM"])) {
      Modal.error({
        title: "Not authorised",
        content: <>You are not authorised to change the team of a {getSimpleLabel("project")}.</>,
      });
      return;
    }
    await window.callGraphQLSimple({
      message: "Failed to change team",
      queryCustom: "updateProject",
      variables: {
        input: {
          id: this.props.project.id,
          team: newTeam,
        },
      },
    });
  };

  confirmFinish = () => {
    const { project, tasks, quotes, history } = this.props;

    let activeTasksInProject = tasks.filter(
      (x) => x.projectId === project.id && !x.isArchived && !x.isFinished && !x.isExternalReference && !x.isHidden
    );

    let activeQuotesInProject = quotes.filter(
      (x) => x.projectId === project.id && !x.isArchived && x.status !== "REJECTED" && x.status !== "ACCEPTED"
    );

    if (activeTasksInProject.length > 0 || activeQuotesInProject.length > 0) {
      let modal = Modal.error({
        title: `Cannot finish ${getSimpleLabel("project")}`,
        className: "cannot-finish-project-modal",
        content: (
          <div className="cannot-finish-project-modal-content">
            {activeTasksInProject.length > 0 ? (
              <div>
                <b>{project.title}</b> currently has active {getSimpleLabel("tasks")} in it and cannot be finished:
                <ul className="projects-which-use-client">
                  {activeTasksInProject.map((task) => (
                    <li
                      key={task.id}
                      onClick={() => {
                        modal.destroy();
                        history.push(`/tasks/${task.id}`);
                      }}
                    >
                      {task.id} - {task.title}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {activeQuotesInProject.length > 0 ? (
              <div>
                <b>{project.title}</b> currently has active {getSimpleLabel("quotes")} in it and cannot be finished:
                <ul className="projects-which-use-client">
                  {activeQuotesInProject.map((quote) => (
                    <li
                      key={quote.id}
                      onClick={() => {
                        modal.destroy();
                        history.push(`/quotes/${quote.id}`);
                      }}
                    >
                      {quote.id} - {quote.title}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        ),
      });
      return;
    } else {
      Modal.confirm({
        title: `Mark ${getSimpleLabel("project")} as finished`,
        icon: <CheckCircleOutlined />,
        content: (
          <>
            Are you sure you want to finish <b>{project.title}</b>?
          </>
        ),
        okButtonProps: { "data-cy": "finish-project-modal-ok" },
        cancelButtonProps: { "data-cy": "finish-project-modal-cancel" },
        onOk: async () => {
          await callGraphQLSimple({
            message: `Failed to mark ${getSimpleLabel("project")} as finished`,
            queryName: "updateProject",
            variables: {
              input: {
                id: project.id,
                isFinished: true,
                finishedAt: new Date().toISOString(),
              },
            },
          });
        },
      });
    }
  };

  confirmResume = () => {
    const { project } = this.props;
    Modal.confirm({
      title: `Resume ${getSimpleLabel("project")}`,
      icon: <CheckCircleOutlined />,
      okButtonProps: { "data-cy": "resume-project-modal-ok" },
      cancelButtonProps: { "data-cy": "resume-project-modal-cancel" },
      content: (
        <>
          Are you sure you want to resume <b>{project.title}</b>?
        </>
      ),
      onOk: async () => {
        await callGraphQLSimple({
          message: `Failed to resume ${getSimpleLabel("project")}`,
          queryName: "updateProject",
          variables: {
            input: {
              id: project.id,
              isFinished: false,
            },
          },
        });
      },
    });
  };

  displayFinishButton = () => {
    const { project } = this.props;

    if (project.isArchived) {
      return null;
    }
    if (project.isFinished) {
      return (
        <Button
          icon={<CheckCircleOutlined />}
          className="mark-as-not-finished"
          onClick={this.confirmResume}
          type="primary"
          data-cy="button-resume-project"
        >
          Resume
        </Button>
      );
    } else {
      return (
        <ButtonWithPermissions
          permissions={["PROJECT.FINISH"]}
          icon={<CheckCircleOutlined />}
          className="mark-as-finished"
          type="dark"
          onClick={this.confirmFinish}
          data-cy="button-finish-project"
        >
          Finish
        </ButtonWithPermissions>
      );
    }
  };

  render() {
    const { splitLayout, project, user, users, match, clients, organisationDetails, apiUser } = this.props;
    const { isCreateTaskModalVisible, isCreatePurchaseOrderModalVisible, isChangeClientModalVisible } = this.state;

    const client = clients.find((client) => client?.id === project.clientId);
    const authorData = users.find((x) => x.id === project.author);

    return (
      <>
        <Card className={cx("project-sidebar", { "split-layout": splitLayout })}>
          <div className="top-buttons">
            {!project.isArchived && !project.isFinished && (
              <ButtonWithPermissions
                type="primary"
                permissions={["CREATE_TASK"]}
                className="create-task"
                onClick={() => this.setState({ isCreateTaskModalVisible: true })}
                icon={<PlusCircleOutlined />}
              >
                Create {getSimpleLabel("task")} in {getSimpleLabel("project")}
              </ButtonWithPermissions>
            )}
            {!project.isArchived &&
              !project.isFinished &&
              organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
                <ButtonWithPermissions
                  type="primary"
                  permissions={["CREATE_PURCHASE_ORDER"]}
                  className="create-purchase-order"
                  onClick={() => this.setState({ isCreatePurchaseOrderModalVisible: true })}
                  icon={<PlusCircleOutlined />}
                >
                  Create purchase order in {getSimpleLabel("project")}
                </ButtonWithPermissions>
              )}
            {this.displayFinishButton()}
          </div>
          {organisationDetails.settings?.general?.usesTeams && (
            <div className="stat team-picker">
              <Typography.Text className="stat-label">Team</Typography.Text>
              <Typography.Text className="stat-value">
                <Select defaultValue={project.team} onChange={this.onTeamChange} className="active-on-hover">
                  {(apiUser.teams || []).map((teamId) => {
                    let teamDetails = organisationDetails.teams?.find((x) => x.id === teamId);
                    return (
                      <Select.Option value={teamDetails.id} key={teamDetails.id}>
                        {teamDetails.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Typography.Text>
            </div>
          )}
          {this.displayCustomFields()}
          {organisationDetails.projectStatuses?.length > 0 && (
            <InfoItem
              label="Status"
              value={
                <Select
                  onChange={(status) => changeProjectStatus({ projectId: project.id, status })}
                  defaultValue={project.status}
                  placeholder="Choose a status"
                  style={{ width: "100%" }}
                >
                  {organisationDetails.projectStatuses?.map((status) => (
                    <Select.Option value={status.name} key={status.name}>
                      {status.name}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
          )}
          <div className="stat">
            <div className={cx("assigned-to-container")}>
              <Typography.Text className="stat-label">Responsible senior engineer:</Typography.Text>
              {match.params.projectId && !project.isArchived ? (
                <UsersFilter
                  className="assigned-to-picker"
                  activateOnHover={true}
                  value={project.assignedTo}
                  onChange={(newAssignedTo) => this.changeAssignedTo(newAssignedTo, project, users)}
                />
              ) : (
                <Avatar user={users.find((x) => x.id === project.assignedTo)} showLabel />
              )}
            </div>
          </div>

          {client && (
            <Link to={`/clients/${client?.id}`}>
              <div className="stat clickable link ">
                <Typography.Text className="stat-label">{getSimpleLabel("Client")}</Typography.Text>
                <Typography.Text className="stat-value">
                  <ClientLogo client={client} />
                  <br />
                  <Button
                    icon={<EditOutlined />}
                    type="danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.setState({ isChangeClientModalVisible: true });
                    }}
                    disabled={!isAuthorised(["PROJECT.CHANGE_CLIENT"])}
                  >
                    Change {getSimpleLabel("client")}
                  </Button>
                </Typography.Text>
              </div>
            </Link>
          )}

          <div className="stat">
            <Typography.Text className="stat-label">PO Number</Typography.Text>
            <Typography.Text className="stat-value created-by">
              <Input
                defaultValue={project.poNumber}
                showBorder
                onChange={async (poNumber) => {
                  await callGraphQLSimple({
                    message: "Failed to update PO number",
                    queryName: "updateProject",
                    variables: {
                      input: {
                        id: project.id,
                        poNumber,
                      },
                    },
                  });

                  await callGraphQLSimple({
                    mutation: "createActivityItem",
                    message: "Failed to create activity item",
                    variables: {
                      input: {
                        parentId: project.id,
                        content: JSON.stringify({
                          oldPoNumber: project.poNumber,
                          poNumber,
                          type: "PO_NUMBER_CHANGED",
                        }),
                        type: "LIFECYCLE_EVENT",
                        organisation: project.organisation,
                        author: window.apiUser.id,
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Typography.Text>
          </div>

          <div className="stat">
            <Typography.Text className="stat-label">
              <Explanation
                title={`The unique number allocated to every ${getSimpleLabel(
                  "task"
                )} is calculated by starting from the current ${getSimpleLabel(
                  "task"
                )} number. This number is incremented by 1 each time a new ${getSimpleLabel(
                  "task"
                )} gets created. This process may have additional steps depending on the naming scheme of your organisation.`}
              />
              &nbsp; Current {getSimpleLabel("task")} number
            </Typography.Text>
            <Typography.Text className="stat-value created-by">
              <Input
                defaultValue={project.taskCount || "0"}
                showBorder
                onChange={async (taskCount) => {
                  await callGraphQLSimple({
                    message: `Failed to update current ${getSimpleLabel("task")} number`,
                    queryName: "updateProject",
                    variables: {
                      input: {
                        id: project.id,
                        taskCount: parseInt(taskCount || 0),
                      },
                    },
                  });
                  await callGraphQLSimple({
                    mutation: "createActivityItem",
                    message: "Failed to create activity item",
                    variables: {
                      input: {
                        parentId: project.id,
                        content: JSON.stringify({
                          oldTaskCount: project.taskCount,
                          taskCount: parseInt(taskCount || 0),
                          type: "TASK_COUNT_CHANGED",
                        }),
                        type: "LIFECYCLE_EVENT",
                        organisation: project.organisation,
                        author: window.apiUser.id,
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Typography.Text>
          </div>

          {organisationDetails.settings?.invoice?.usesInvoices && (
            <div className="stat">
              <Typography.Text className="stat-label">
                <Explanation
                  title={`The unique number allocated to every invoice is calculated by starting from the current invoice number. This number is incremented by 1 each time a new invoice gets created. This process may have additional steps depending on the naming scheme of your organisation.`}
                />
                &nbsp; Current invoice number
              </Typography.Text>
              <Typography.Text className="stat-value created-by">
                <Input
                  defaultValue={project.invoiceCount || "0"}
                  showBorder
                  onChange={async (invoiceCount) => {
                    await callGraphQLSimple({
                      message: "Failed to update current invoice number",
                      queryName: "updateProject",
                      variables: {
                        input: {
                          id: project.id,
                          invoiceCount: parseInt(invoiceCount || 0),
                        },
                      },
                    });
                    await callGraphQLSimple({
                      mutation: "createActivityItem",
                      message: "Failed to create activity item",
                      variables: {
                        input: {
                          parentId: project.id,
                          content: JSON.stringify({
                            oldInvoiceCount: project.invoiceCount,
                            invoiceCount: parseInt(invoiceCount || 0),
                            type: "INVOICE_COUNT_CHANGED",
                          }),
                          type: "LIFECYCLE_EVENT",
                          organisation: project.organisation,
                          author: window.apiUser.id,
                        },
                      },
                    });
                  }}
                  fullWidth
                />
              </Typography.Text>
            </div>
          )}
          {organisationDetails.settings?.quote?.usesQuotes && (
            <div className="stat">
              <Typography.Text className="stat-label">
                <Explanation
                  title={`The unique number allocated to every ${getSimpleLabel(
                    "quote"
                  )} is calculated by starting from the current ${getSimpleLabel(
                    "quote"
                  )} number. This number is incremented by 1 each time a new ${getSimpleLabel(
                    "quote"
                  )} gets created. This process may have additional steps depending on the naming scheme of your organisation.`}
                />
                &nbsp; Current {getSimpleLabel("quote")} number
              </Typography.Text>
              <Typography.Text className="stat-value created-by">
                <Input
                  defaultValue={project.quoteCount || "0"}
                  showBorder
                  onChange={async (quoteCount) => {
                    await callGraphQLSimple({
                      message: `Failed to update current ${getSimpleLabel("quote")} number`,
                      queryName: "updateProject",
                      variables: {
                        input: {
                          id: project.id,
                          quoteCount: parseInt(quoteCount || 0),
                        },
                      },
                    });

                    await callGraphQLSimple({
                      mutation: "createActivityItem",
                      message: "Failed to create activity item",
                      variables: {
                        input: {
                          parentId: project.id,
                          content: JSON.stringify({
                            oldQuoteCount: project.quoteCount,
                            quoteCount: parseInt(quoteCount || 0),
                            type: "QUOTE_COUNT_CHANGED",
                          }),
                          type: "LIFECYCLE_EVENT",
                          organisation: project.organisation,
                        },
                      },
                    });
                  }}
                  fullWidth
                />
              </Typography.Text>
            </div>
          )}
          {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
            <div className="stat">
              <Typography.Text className="stat-label">
                <Explanation
                  title={`The unique number allocated to every purchase order is calculated by starting from the current purchase order number. This number is incremented by 1 each time a new purchase order gets created. This process may have additional steps depending on the naming scheme of your organisation.`}
                />
                &nbsp; Current purchase order number
              </Typography.Text>
              <Typography.Text className="stat-value">
                <Input
                  defaultValue={project.purchaseOrderCount || "0"}
                  showBorder
                  onChange={async (purchaseOrderCount) => {
                    await callGraphQLSimple({
                      message: "Failed to update current purchase order number",
                      queryName: "updateProject",
                      variables: {
                        input: {
                          id: project.id,
                          purchaseOrderCount: parseInt(purchaseOrderCount || 0),
                        },
                      },
                    });

                    await callGraphQLSimple({
                      mutation: "createActivityItem",
                      message: "Failed to create activity item",
                      variables: {
                        input: {
                          parentId: project.id,
                          content: JSON.stringify({
                            oldPurchaseOrderCount: project.purchaseOrderCount,
                            purchaseOrderCount: parseInt(purchaseOrderCount || 0),
                            type: "PURCHASE_ORDER_COUNT_CHANGED",
                          }),
                          type: "LIFECYCLE_EVENT",
                          organisation: project.organisation,
                        },
                      },
                    });
                  }}
                  fullWidth
                />
              </Typography.Text>
            </div>
          )}

          <div className="stat created-by-container">
            <Typography.Text className="stat-label">Created by</Typography.Text>
            <Typography.Text className="stat-value created-by">
              <Avatar user={authorData} showLabel={true} maxLabelLength={22} />
            </Typography.Text>
          </div>

          <div className="stat">
            <Typography.Text className="stat-label">Created</Typography.Text>
            <Typography.Text className="stat-value">{moment(project.createdAt).fromNow()}</Typography.Text>
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">Last updated</Typography.Text>
            <Typography.Text className="stat-value">{moment(project.updatedAt).fromNow()}</Typography.Text>
          </div>

          {isCreateTaskModalVisible && (
            <CreateTaskModal
              onClose={() => this.setState({ isCreateTaskModalVisible: false })}
              apiUser={window.apiUser}
              predefinedFields={{ projectId: project.id }}
            />
          )}
          {isCreatePurchaseOrderModalVisible && (
            <CreatePurchaseOrderModal
              onClose={() => this.setState({ isCreatePurchaseOrderModalVisible: false })}
              apiUser={window.apiUser}
              predefinedFields={{ projectId: project.id }}
            />
          )}
          {isChangeClientModalVisible ? (
            <ChangeClientModal onClose={() => this.setState({ isChangeClientModalVisible: false })} project={project} />
          ) : null}
        </Card>
      </>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ProjectSidebar,
    subscriptions: ["organisationDetails", "sprints", "tasks", "users", "clients"],
  })
);
