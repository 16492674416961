import { useState, useEffect } from "react";
import { message, Form, Modal, Button, Select, Checkbox, Input, InputNumber } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { TwitterPicker } from "react-color";

import { useForceUpdate } from "common/helpers";
import { CUSTOM_FIELD_TYPES } from "common/constants";
import { callGraphQLSimple } from "common/apiHelpers";

import "./CreateCustomFieldModal.scss";

const COLOR_OPTIONS = [
  "#004c6d",
  "#ff0000",
  "#ffffff",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
];

type Props = {
  onClose?: any;
  organisationDetails: any;
  customField: any;
  target: string;
};

type Option = {
  label: string;
  value: string;
  color?: string;
  tagLabel?: string;
  showAsTag?: boolean;
};

export default function CreateCustomFieldModal({ onClose, organisationDetails, customField, target }: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [colorPickerOpenOnOption, setColorPickerOpenOnOption] = useState<number>();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (customField) {
      if (customField.options) {
        form.setFieldsValue({ optionCount: customField.options.length });
        let options: Option[] = [];

        for (let i = 0; i < customField.options.length; i++) {
          const currentOption = customField.options[i];

          options.push({
            label: currentOption.label,
            color: currentOption.color,
            tagLabel: currentOption.tagLabel,
            showAsTag: currentOption.showAsTag,
            value: currentOption.value,
          });
        }

        for (let i = 0; i < options.length; i++) {
          const fieldValue = options[i].label
            .toUpperCase()
            .split(" ")
            .join("_")
            .split(".")
            .join("_")
            .split(",")
            .join("_");

          form.setFieldsValue({
            [`option-${i + 1}-name`]: options[i].label,
            [`option-${i + 1}-color`]: options[i].color,
            [`option-${i + 1}-tag-label`]: options[i].tagLabel,
            [`option-${i + 1}-show-as-tag`]: options[i].showAsTag,
            [fieldValue]: options[i].value,
          });
        }
      }

      form.setFieldsValue({
        ...customField,
        groupsThatCanSee: customField?.groupsThatCanSee ? customField.groupsThatCanSee.join(",") : "",
      });
      forceUpdate();
    }
  }, []); // eslint-disable-line

  // we need to force the component to re-render when we change some form values

  async function onSubmit(formValues) {
    setIsLoading(true);

    let newField: {
      label: string;
      id: string;
      target: string;
      type: string;
      defaultValue?: string;
      showAsTag: boolean;
      options?: Option[];
      color?: string;
      displayTagIfChecked?: boolean;
      displayTagIfEmpty?: boolean;
      onlyVisibleForCertainUserGroups?: boolean;
      groupsThatCanSee?: string[];
      tagLabel?: string;
      mandatory: boolean;
    } = {
      label: formValues.label,
      id:
        customField?.id ||
        formValues.label.toUpperCase().split(" ").join("_").split(".").join("_").split(",").join("_") +
          "_" +
          Date.now(),
      target,
      type: formValues.type,
      defaultValue: formValues.defaultValue,
      showAsTag: !!formValues.showAsTag,
      color: formValues.color,
      displayTagIfChecked: formValues.displayTagIfChecked && formValues.type === "CHECKBOX",
      displayTagIfEmpty: formValues.displayTagIfEmpty,
      onlyVisibleForCertainUserGroups: !!formValues.onlyVisibleForCertainUserGroups,
      groupsThatCanSee: formValues.groupsThatCanSee ? formValues.groupsThatCanSee.split(", ").join(",").split(",") : [],
      tagLabel: formValues.tagLabel,
      mandatory: !!formValues.mandatory,
    };

    if (["RADIO_LIST", "CHECKBOX_LIST"].includes(form.getFieldValue("type"))) {
      let options: Option[] = [];

      for (let i = 0; i < formValues.optionCount; i++) {
        options.push({
          label: formValues[`option-${i + 1}-name`],
          color: formValues[`option-${i + 1}-color`],
          tagLabel: formValues[`option-${i + 1}-tag-label`],
          showAsTag: formValues[`option-${i + 1}-show-as-tag`],
          value: formValues[`option-${i + 1}-name`]
            .toUpperCase()
            .split(" ")
            .join("_")
            .split(".")
            .join("_")
            .split(",")
            .join("_"),
        });
      }
      newField.options = options;
    }

    if (!customField) {
      await callGraphQLSimple({
        message: "Failed to create custom field",
        queryName: "updateOrganisation",
        variables: {
          input: {
            id: organisationDetails.id,
            customFields: [...(organisationDetails.customFields || []), newField],
          },
        },
      });
    } else {
      await callGraphQLSimple({
        message: "Failed to update custom field",
        queryName: "updateOrganisation",
        variables: {
          input: {
            id: organisationDetails.id,
            customFields: organisationDetails.customFields.map((field) => {
              if (field.id === customField.id) {
                return newField;
              } else {
                return field;
              }
            }),
          },
        },
      });
    }

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  let optionFormElements: any[] | null = [];

  if (["RADIO_LIST", "CHECKBOX_LIST"].includes(form.getFieldValue("type"))) {
    optionFormElements = [];
    for (let i = 0; i < form.getFieldValue("optionCount"); i++) {
      let optionNameLabel = `Option ${i + 1} name `;
      if (i === 0 && ["RADIO_LIST"].includes(form.getFieldValue("type"))) {
        optionNameLabel += "(default)";
      }
      optionFormElements.push(
        <div className="option-item-container" data-testid={`option-${i + 1}`} key={`option-${i + 1}`}>
          <Form.Item
            label={optionNameLabel}
            name={`option-${i + 1}-name`}
            rules={[
              {
                required: true,
                message: "Each option needs a name",
              },
            ]}
          >
            <Input className="option-name" data-cy="option-name" />
          </Form.Item>
          {form.getFieldValue("showAsTag") && (
            <>
              <Form.Item label={`Option ${i + 1} tag color`} name={`option-${i + 1}-color`}>
                <>
                  <div
                    className="option-color-marker"
                    style={{
                      backgroundColor: form.getFieldValue(`option-${i + 1}-color`) || "black",
                    }}
                    onClick={() =>
                      setColorPickerOpenOnOption((colorPickerOpenOnOption) =>
                        colorPickerOpenOnOption === i ? undefined : i
                      )
                    }
                  >
                    {colorPickerOpenOnOption === i && (
                      <TwitterPicker
                        onChange={(newColor) => {
                          form.setFieldsValue({
                            [`option-${i + 1}-color`]: newColor.hex,
                          });
                          forceUpdate();
                        }}
                        value={form.getFieldValue(`option-${i + 1}-color`) || "black"}
                        colors={[...COLOR_OPTIONS, "#000000"]}
                      />
                    )}
                  </div>
                </>
              </Form.Item>
              <Form.Item label={`Option ${i + 1} tag label (optional)`} name={`option-${i + 1}-tag-label`}>
                <Input className="option-name" />
              </Form.Item>

              <Form.Item
                label={`Display option ${i + 1} as tag`}
                name={`option-${i + 1}-show-as-tag`}
                className="show-option-as-tag"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) => {
                    form.setFieldsValue({
                      [`option-${i + 1}-show-as-tag`]: e.target.checked,
                    });
                    forceUpdate();
                  }}
                />
              </Form.Item>
            </>
          )}
        </div>
      );
    }
  }

  let title;

  if (customField) {
    title = "Edit custom field";
    title += ` (field ID: ${customField.id})`;
    title = (
      <>
        {title}{" "}
        <Button
          type="clear"
          style={{ position: "relative", top: "1px" }}
          icon={<CopyOutlined />}
          onClick={async () => {
            await navigator.clipboard.writeText(customField.id);
            message.success({
              content: <span>Field ID copied to clipboard</span>,
            });
          }}
        />
      </>
    );
  } else {
    title = "Create custom field";
  }

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={true}
      onCancel={onClose}
      footer={null}
      className="create-custom-field-modal"
    >
      <Form
        form={form}
        initialValues={{
          optionCount: 2,
          displayTagIfChecked: true,
          displayTagIfEmpty: false,
          "option-1-show-as-tag": true,
          "option-2-show-as-tag": true,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Field type"
          name="type"
          className="field-type"
          rules={[
            {
              required: true,
              message: "You must choose a field type",
            },
          ]}
        >
          <Select
            className="field-type-select"
            data-cy="field-type-dropdown"
            onChange={(type) => {
              form.setFieldsValue({ type });
              forceUpdate();
            }}
          >
            {CUSTOM_FIELD_TYPES.map((fieldType) => (
              <Select.Option key={fieldType.value} value={fieldType.value}>
                {fieldType.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Field name"
          hidden={!form.getFieldValue("type")}
          name="label"
          className="field-name"
          rules={[
            {
              required: true,
              message: "You must choose a field name",
            },
            {
              validator: customField
                ? undefined
                : async (_, label) => {
                    let fieldWithSameLabel = (organisationDetails.customFields || []).find(
                      (field) => field.label === label
                    );
                    if (fieldWithSameLabel) {
                      throw new Error("Field name must be unique");
                    }
                  },
            },
          ]}
        >
          <Input data-cy="field-name-input" />
        </Form.Item>
        <Form.Item
          label="Field is mandatory"
          name="mandatory"
          className="mandatory"
          valuePropName="checked"
          hidden={!form.getFieldValue("type") || ["CHECKBOX", "CHECKBOX_LIST"].includes(form.getFieldValue("type"))}
        >
          <Checkbox
            data-cy="field-is-mandatory-checkbox"
            onChange={(e) => {
              form.setFieldsValue({ mandatory: e.target.checked });
              forceUpdate();
            }}
          />
        </Form.Item>

        <Form.Item
          label="Default value (optional)"
          hidden={!["TEXT", "TEXTAREA", "NUMBER"].includes(form.getFieldValue("type"))}
          name="defaultValue"
          className="default-value"
        >
          <Input data-cy="default-value-input" />
        </Form.Item>
        <Form.Item
          hidden={!form.getFieldValue("type")}
          label="Display tag"
          name="showAsTag"
          className="show-as-tag"
          valuePropName="checked"
        >
          <Checkbox
            data-cy="display-field-as-tag-checkbox"
            onChange={(e) => {
              form.setFieldsValue({ showAsTag: e.target.checked });
              forceUpdate();
            }}
          />
        </Form.Item>
        <Form.Item
          hidden={!form.getFieldValue("type")}
          label="Display tag only if there is no value"
          name="displayTagIfEmpty"
          className="display-tag-if-empty"
          valuePropName="checked"
        >
          <Checkbox
            data-cy="display-tag-if-empty-checkbox"
            onChange={(e) => {
              form.setFieldsValue({ displayTagIfEmpty: e.target.checked });
              forceUpdate();
            }}
          />
        </Form.Item>

        <Form.Item
          hidden={!form.getFieldValue("showAsTag")}
          label="Tag label (optional)"
          name="tagLabel"
          className="tag-label"
        >
          <Input />
        </Form.Item>
        <Form.Item
          hidden={!["CHECKBOX"].includes(form.getFieldValue("type")) || !form.getFieldValue("showAsTag")}
          label="Display tag if checked"
          name="displayTagIfChecked"
          className="display-tag-if-checked"
          valuePropName="checked"
        >
          <Checkbox
            onChange={(e) => {
              form.setFieldsValue({ displayTagIfChecked: e.target.checked });
              forceUpdate();
            }}
          />
        </Form.Item>

        <Form.Item hidden={!form.getFieldValue("showAsTag")} label="Tag color" name="color">
          <div
            className="option-color-marker"
            style={{ backgroundColor: form.getFieldValue("color") || "black" }}
            onClick={() =>
              setColorPickerOpenOnOption((colorPickerOpenOnOption) => (colorPickerOpenOnOption === 0 ? undefined : 0))
            }
          >
            {colorPickerOpenOnOption === 0 && (
              <TwitterPicker
                onChange={(newColor) => {
                  form.setFieldsValue({ color: newColor.hex });
                  forceUpdate();
                }}
                value={form.getFieldValue("color") || "black"}
                colors={[...COLOR_OPTIONS, "#000000"]}
              />
            )}
          </div>
        </Form.Item>

        <Form.Item
          hidden={!["RADIO_LIST", "CHECKBOX_LIST"].includes(form.getFieldValue("type"))}
          label="Number of options"
          name="optionCount"
        >
          <InputNumber
            min={2}
            className="option-count-input"
            onChange={(optionCount) => {
              form.setFieldsValue({ optionCount });
              for (let i = 0; i < (optionCount as number); i++) {
                if (form.getFieldValue(`option-${i + 1}-show-as-tag`) === undefined) {
                  form.setFieldsValue({
                    [`option-${i + 1}-show-as-tag`]: true,
                  });
                }
              }
              forceUpdate();
            }}
          />
        </Form.Item>
        {optionFormElements}

        <Form.Item
          hidden={!form.getFieldValue("type")}
          label="Field is only visible for certain user groups"
          name="onlyVisibleForCertainUserGroups"
          className="only-visible-for-certain-user-groups"
          valuePropName="checked"
        >
          <Checkbox
            onChange={(e) => {
              form.setFieldsValue({ onlyVisibleForCertainUserGroups: e.target.checked });
              forceUpdate();
            }}
          />
        </Form.Item>
        {form.getFieldValue("onlyVisibleForCertainUserGroups") && (
          <Form.Item
            label="Groups that can see this field (comma-separated)"
            name="groupsThatCanSee"
            rules={[
              {
                required: true,
                message: "You must mention at least one group",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="custom-field-modal-submit-button">
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
