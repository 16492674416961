import moment from "moment";
import { roundToQuarter, roundToDecimals } from "common/mathHelpers";

const fields = [
  {
    id: "projectInitials",
    fieldTypes: ["textfield"],
    label: "Initials",
    value: ({ project }) => {
      return project?.initials;
    },
  },
  {
    id: "projectNumber",
    fieldTypes: ["textfield"],
    label: "Project number (assuming regular project ID format)",
    value: ({ project }) => project.id.split("-")[1],
  },
  {
    id: "tasks",
    fieldTypes: ["repeatFor"],
    label: "Each task in project",
    repeatForFieldName: "task",
    value: async ({ project }) => {
      if (!project) {
        return [];
      }

      let tasksForProject;

      if (global.isBrowser) {
        tasksForProject = (
          await window.callGraphQLSimple({
            message: "Failed to fetch tasks for project",
            queryCustom: "listTasksByProject",
            variables: {
              projectId: project.id,
            },
          })
        ).data.listTasksByProject.items;
      } else {
        tasksForProject = (
          await global.callGraphQLSimple({
            message: "Failed to fetch tasks for project",
            queryName: "listTasksByProject",
            variables: {
              projectId: project.id,
            },
          })
        ).data.listTasksByProject.items;
      }
      return tasksForProject.filter((task) => !task.isArchived && !task.isHidden);
    },
  },
  {
    id: "projectId",
    fieldTypes: ["textfield"],
    label: "Project ID",
    value: ({ project }) => project?.id,
  },
  {
    id: "projectIdWithoutOrganisation",
    fieldTypes: ["textfield"],
    label: "Project ID without organisation",
    value: ({ project }) => {
      if (!project) {
        return "";
      }

      return project?.id.split("-").slice(1).join("-");
    },
  },
  {
    id: "projectTitle",
    fieldTypes: ["textfield"],
    label: "Project title",
    value: ({ project }) => project?.title,
  },
  {
    id: "projectTitleAndId",
    fieldTypes: ["textfield"],
    label: "Project title & ID",
    value: ({ project }) => `${project?.id} - ${project?.title}`,
  },
  {
    id: "projectDescription",
    fieldTypes: ["textfield"],
    label: "Project description",
    value: ({ project }) => project?.description,
  },
  {
    id: "projectSeniorEngineer",
    fieldTypes: ["textfield"],
    label: "Project senior engineer email",
    value: ({ project }) => project?.assignedTo,
  },
  {
    id: "projectSeniorEngineerFullName",
    fieldTypes: ["textfield"],
    label: "Project senior engineer full name",
    value: ({ project, users }) => {
      let user = users.find((x) => x.id === project.assignedTo);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "projectFullAddress",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Full address details",
    value: ({ project }) => {
      if (!project) {
        return "";
      }
      const { houseName, streetName, streetNumber, postcode, county, city, country } = project?.address || {};
      let address = "";
      if (houseName) {
        address += `${houseName}\n`;
      }
      if (streetName || streetNumber) {
        if (streetNumber) {
          address += `${streetNumber} `;
        }
        if (streetName) {
          address += `${streetName} `;
        }

        address += "\n";
      }

      if (city) {
        address += `${city}\n`;
      }
      if (county) {
        address += `${county}\n`;
      }
      if (postcode) {
        address += `${postcode}\n`;
      }
      if (country) {
        address += `${country}\n`;
      }
      return address;
    },
  },
  {
    id: "projectClientId",
    fieldTypes: ["textfield"],
    label: "Client ID",
    value: ({ project }) => {
      if (!project || !project.client) {
        return "";
      }
      return project.client.id;
    },
  },
  {
    id: "projectClientName",
    fieldTypes: ["textfield"],
    label: "Client name",
    value: ({ project }) => {
      if (!project || !project.client) {
        return "";
      }
      return project.client.name;
    },
  },
  {
    id: "projectClientLogo",
    fieldTypes: ["textfield"],
    label: "Client logo",
    value: ({ project }) => {
      if (!project || !project.client) {
        return "";
      }
      return project.client.key;
    },
  },
  {
    id: "projectAmountBudget",
    fieldTypes: ["textfield"],
    label: "Budget amount",
    value: ({ project }) => {
      if (!project) {
        return "";
      }
      return project.amountBudget || 0;
    },
  },
  {
    id: "projectAmountSpent",
    fieldTypes: ["textfield"],
    label: "Spent amount",
    value: ({ project }) => {
      if (!project) {
        return "";
      }
      return project.amountSpent || 0;
    },
  },
  {
    id: "projectSpentPercentage",
    fieldTypes: ["textfield"],
    label: "Spent percentage",
    value: ({ project }) => {
      if (!project) {
        return "";
      }

      let amountSpent = project.amountSpent || 0;
      let amountBudget = project.amountBudget || 0;
      if (amountBudget === 0) {
        if (amountSpent === 0) {
          return 0;
        }
        return "";
      } else {
        return Math.round((amountSpent / amountBudget) * 100);
      }
    },
  },
  {
    id: "projectProfitAmount",
    fieldTypes: ["textfield"],
    label: "Profit amount",
    value: ({ project }) => {
      if (!project) {
        return "";
      }

      let amountSpent = project.amountSpent || 0;
      let amountBudget = project.amountBudget || 0;
      return amountBudget - amountSpent;
    },
  },
  {
    id: "projectProfitPercentage",
    fieldTypes: ["textfield"],
    label: "Profit percentage",
    value: ({ project }) => {
      if (!project) {
        return "";
      }

      let amountSpent = project.amountSpent || 0;
      let amountBudget = project.amountBudget || 0;
      if (amountBudget === 0) {
        if (amountSpent === 0) {
          return 0;
        }
        return "";
      } else {
        return Math.round(((amountBudget - amountSpent) / amountBudget) * 100);
      }
    },
  },
  {
    id: "invoicesInProject",
    fieldTypes: ["repeatFor"],
    label: "Each invoice in project",
    repeatForFieldName: "invoice",
    value: async ({ project, organisationDetails }) => {
      if (!project || !organisationDetails) {
        return [];
      }

      let invoices = await listInvoicesByProject({ organisation: organisationDetails.id, projectId: project.id });
      return invoices;
    },
  },
  {
    id: "projectAmountOutstanding",
    label: "Outstanding amount",
    value: async ({ project }) => {
      if (!project) {
        return "";
      }

      return roundToDecimals(project.amountOutstanding || 0);
    },
  },
  {
    id: "projectAmountInvoiced",
    label: "Total invoiced amount",
    value: async ({ project, organisationDetails }) => {
      if (!project) {
        return "";
      }

      return project.amountInvoiced || 0;
    },
  },
  {
    id: "timesheetBlocksInProject",
    fieldTypes: ["repeatFor"],
    label: "Each timesheet block in project",
    repeatForFieldName: "timesheetBlock",
    value: async ({ project }) => {
      if (!project || !project.timesheetBlocks) {
        return [];
      }

      return project.timesheetBlocks;
    },
  },
  {
    id: "timesheetBlocksInProjectTotalDuration",
    label: "Timesheet blocks - total duration",
    value: async ({ project }) => {
      if (!project || !project.timesheetBlocks) {
        return [];
      }

      return project.timesheetBlocks.reduce((sum, timesheetBlock) => {
        let durationHours = roundToQuarter(
          moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
        );
        return roundToQuarter(sum + durationHours);
      }, 0);
    },
  },
];

async function listInvoicesByProject({ projectId, organisation }) {
  let items = [];
  let params = {
    organisation,
    limit: 1000,
  };

  while (true) {
    let response;
    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: "Failed to list the invoices",
        queryCustom: "listInvoicesByOrganisation",
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: "Failed to list the invoices",
        queryName: "listInvoicesByOrganisation",
        variables: params,
      });
    }

    const pageOfItems = response.data.listInvoicesByOrganisation.items;
    params.nextToken = response.data.listInvoicesByOrganisation.nextToken;
    items = [...items, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  items = items.filter((item) => !item.isArchived && item.projectId === projectId);

  return items;
}

export function getFields({ organisationDetails }) {
  let extraFields = [];

  if (
    organisationDetails?.settings?.project?.customFields &&
    organisationDetails.settings?.project?.customFields.length > 0
  ) {
    organisationDetails.settings.project.customFields.forEach((fieldDefinition) => {
      extraFields.push({
        id: `customProjectField_${fieldDefinition.id}`,
        fieldTypes: ["textfield"],
        label: fieldDefinition.label,
        value: ({ project }) => {
          if (!project || !project.customFields) {
            return "";
          }
          let targetField = project.customFields.find((field) => field.id === fieldDefinition.id);
          if (!targetField) {
            return "";
          }
          return targetField.value;
        },
      });
    });
  }

  return [...fields, ...extraFields];
}

export const label = "Project";
