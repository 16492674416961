import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Typography, Button, Empty, Table, Select } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { getFilteredProjects } from "common/filterHelpers";
import { isAuthorised } from "common/permissions";
import { formatCurrency } from "common/shared";
import { getTableColumns } from "./tableColumns";
import { getLabel, downloadBase64, sanitiseCSVValue, hasParentWithClass } from "common/helpers";
import { getSimpleLabel } from "common/labels";
import changeProjectStatus from "common/changeProjectStatus";

import ProjectFilters from "ProjectFilters/ProjectFilters";
import ClientLogo from "ClientLogo/ClientLogo";
import ProjectIdTag from "ProjectIdTag/ProjectIdTag";
import TaskListItem from "TaskListItem/TaskListItem";
import BudgetBar from "BudgetBar/BudgetBar";

import "./ProjectsPage.scss";

export class ProjectsPage extends React.Component {
  _isMounted = false;

  state = {
    filter: {},
  };

  componentDidMount() {
    this.props.setBoxedLayout(false);

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "PROJECTS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
  }

  export = async () => {
    const { projects, tasks, quotes, invoices, purchaseOrders, clients, organisationDetails } = this.props;
    const { filter } = this.state;

    const columns = [...getTableColumns(organisationDetails)];

    let csvContent = columns.map((column) => column.title).join(",") + "\n";
    let filteredProjects = getFilteredProjects({
      filter,
      projects,
    })
      .sort((a, b) => (a.id < b.id ? -1 : 1))
      .filter((project) => !project.id.includes("TEMPLATES"));

    csvContent += filteredProjects
      .map((project, i) => {
        const fullProjectDetails = {
          ...project,
          tasks: tasks.filter((task) => task.projectId === project.id),
          quotes: quotes.filter((quote) => quote.projectId === project.id),
          invoices: invoices.filter((invoice) => invoice.projectId === project.id),
          purchaseOrders: purchaseOrders.filter((purchaseOrder) => purchaseOrder.projectId === project.id),
          client: clients.find((client) => client.id === project.clientId),
        };

        return columns
          .map((column) => {
            if (column.fieldFunction) {
              return sanitiseCSVValue(column.fieldFunction(fullProjectDetails));
            } else {
              return sanitiseCSVValue(project[column.dataIndex]);
            }
          })
          .join(",");
      })
      .join("\n");

    let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
    await downloadBase64({
      base64String: base64CSV,
      fileName: `projects ${moment().format("DD-MM-YYYY")}.csv`,
    });
  };

  getProjectNumber = (id) => {
    return Number(id.split("-").slice(-1)[0]);
  };

  getSortedProjects = ({ projects, sortBy }) => {
    if (sortBy === "title") {
      const sortedProjects = projects.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
      return sortedProjects;
    } else if (sortBy === "id") {
      const sortedProjects = projects.sort((a, b) =>
        this.getProjectNumber(a.id) < this.getProjectNumber(b.id) ? -1 : 1
      );
      return sortedProjects;
    } else {
      return projects;
    }
  };

  getTasksInProject = (project) => {
    const { tasks } = this.props;
    const { filter } = this.state;
    let filteredTasks = tasks.filter((x) => x.projectId === project.id && !x.isExternalReference && !x.isHidden);

    if (filter && !filter?.includeArchivedTasks) {
      filteredTasks = filteredTasks.filter((x) => !x.isArchived);
    }

    if (filter && !filter?.includeFinishedTasks) {
      filteredTasks = filteredTasks.filter((x) => !x.isFinished);
    }

    if (project.dueDateStart && project.dueDateEnd) {
      filteredTasks = filteredTasks.filter(
        (x) => x.dueDate && moment(x.dueDate).isBetween(project.dueDateStart, project.dueDateEnd)
      );
    }

    return filteredTasks.sort((a, b) => {
      let aParts = a.id.split("-");
      let bParts = b.id.split("-");

      let aTaskNumber = parseInt(aParts[2]);
      let bTaskNumber = parseInt(bParts[2]);

      return aTaskNumber > bTaskNumber ? 1 : -1;
    });
  };

  displayTasks = (project) => {
    const { organisationDetails, windowWidth } = this.props;
    const tasksInProject = this.getTasksInProject(project);
    return (
      <div className="project-task-list">
        {tasksInProject.map((task) => (
          <TaskListItem
            key={task.id}
            {...task}
            includeClientLogo={false}
            includeProjectTitle={false}
            organisationDetails={organisationDetails}
            windowWidth={windowWidth}
          />
        ))}
      </div>
    );
  };

  onRowClick = (e, project) => {
    if (hasParentWithClass(e.target, "expand-button") || hasParentWithClass(e.target, "collapse-button")) {
      return;
    }

    this.props.history.push(`/projects/${project.id}`);
  };

  displayProjectList = () => {
    const { projects, organisationDetails, clients } = this.props;
    const { filter } = this.state;
    let quotesInHours = organisationDetails.settings?.quote?.quotesInHours;

    let projectsWithBudgetAndSpent = projects.map((project) => {
      let spent;
      let budget;
      if (quotesInHours) {
        spent = project.hoursSpent;
        budget = project.hoursBudget;
      } else {
        spent = project.amountSpent;
        budget = project.amountBudget;
      }
      return {
        ...project,
        spent,
        budget,
      };
    });

    let filteredProjects = getFilteredProjects({ projects: projectsWithBudgetAndSpent, filter }).filter(
      (project) => !project.id.includes("TEMPLATES")
    );

    let sortedProjects = this.getSortedProjects({ projects: filteredProjects, sortBy: filter.sortBy });

    if (!filteredProjects || filteredProjects.length === 0) {
      return (
        <Empty
          description={`No ${getLabel({
            organisationDetails,
            id: "projects",
            defaultValue: "projects",
          })} found`}
        />
      );
    }

    const columns = [
      {
        title: `${getSimpleLabel("Client")}`,
        key: "client",
        width: 150,
        align: "center",
        render: (_, project) => {
          const client = clients.find((x) => x.id === project.clientId);
          return <ClientLogo client={client} size="small" />;
        },
      },
      {
        title: `${getSimpleLabel("Project")} ID`,
        key: "id",
        width: 100,
        render: (_, project) => {
          const client = clients.find((x) => x.id === project.clientId);
          return <ProjectIdTag project={{ ...project, client }} includeTitle={false} />;
        },
      },
      {
        title: `${getSimpleLabel("Project")} title`,
        key: "title",
        dataIndex: "title",
        align: "left",
        render: (_, project) => <Typography.Text data-cy="project-title">{project.title}</Typography.Text>,
      },
      organisationDetails?.projectStatuses?.length > 0 && {
        title: "Status",
        key: "status",
        dataIndex: "status",
        align: "left",
        width: 170,
        render: (_, project) => (
          <Select
            data-cy="project-status"
            defaultValue={project.status}
            onChange={(status) => changeProjectStatus({ projectId: project.id, status })}
            placeholder="No status selected"
            style={{ width: "100%" }}
            onClick={(e) => e.stopPropagation()}
          >
            {organisationDetails.projectStatuses?.map((status) => (
              <Select.Option value={status.name} key={status.name}>
                {status.name}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      isAuthorised(["PROJECT.VIEW_BUDGET_BAR"]) && {
        title: "Budget used %",
        width: 100,
        fieldFunction: ({ row }) => {
          if (!row.budget && row.spent) {
            return "";
          }
          return Math.round((row.spent / row.budget) * 100);
        },
        sorter: {
          compare: (a, b) => {
            let aBudgetUsed = 0;
            let bBudgetUsed = 0;
            if (a.spent) {
              aBudgetUsed = Math.round((a.spent / a.budget) * 100);
            }
            if (b.spent) {
              bBudgetUsed = Math.round((b.spent / b.budget) * 100);
            }
            return aBudgetUsed < bBudgetUsed ? -1 : 1;
          },
        },
        render: (_, row) => {
          if (row.spent && !row.budget) {
            return <>∞</>;
          }

          return (
            <BudgetBar
              hideFinancials={window.organisationDetails.settings?.quote?.quotesInHours}
              size="large"
              amountSpent={row.spent}
              amountBudget={row.budget}
              includePercentage
              includeLabel={false}
              includeBudgetUsedLabel={false}
            />
          );
        },
      },
      isAuthorised(["PROJECT.VIEW_BUDGET_BAR"]) && {
        title: "Spent",
        width: 100,
        fieldFunction: ({ row }) => {
          return row.spent || 0;
        },
        sorter: {
          compare: (a, b) => ((a.spent || 0) < (b.spent || 0) ? -1 : 1),
        },
        render: (_, row) => {
          if (organisationDetails.settings?.quote?.quotesInHours) {
            return `${row.spent || 0} hour${row.spent === 1 ? "" : "s"}`;
          }
          return formatCurrency("GBP", row.spent);
        },
      },
      isAuthorised(["PROJECT.VIEW_BUDGET_BAR"]) && {
        title: "Budget",
        width: 100,
        fieldFunction: ({ row }) => {
          return row.budget || 0;
        },
        sorter: {
          compare: (a, b) => ((a.budget || 0) < (b.budget || 0) ? -1 : 1),
        },
        render: (_, row) => {
          if (organisationDetails.settings?.quote?.quotesInHours) {
            return `${row.budget || 0} hour${row.budget === 1 ? "" : "s"}`;
          }
          return formatCurrency("GBP", row.budget);
        },
      },
    ].filter((x) => x);

    return (
      <>
        <Typography.Text className="project-count">
          {filteredProjects.length}{" "}
          {filteredProjects.length === 1 ? getSimpleLabel("project") : getSimpleLabel("projects")}
        </Typography.Text>

        <Table
          columns={columns}
          dataSource={sortedProjects}
          pagination={{ pageSize: 50, hideOnSinglePage: true }}
          sticky={{
            offsetHeader: 5,
          }}
          rowKey="id"
          expandable={{
            expandedRowRender: this.displayTasks,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button className="expand-button" icon={<DownOutlined />} onClick={(e) => onExpand(record, e)} />
              ) : (
                <Button className="collapse-button" icon={<RightOutlined />} onClick={(e) => onExpand(record, e)} />
              ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
          onRow={(project) => {
            return {
              onClick: (e) => {
                this.onRowClick(e, project);
              },
            };
          }}
        />
      </>
    );
  };

  render() {
    return (
      <div className="projects-page">
        <ProjectFilters onChange={(filter) => this.setState({ filter })} export={this.export} />
        <div className="main-content">{this.displayProjectList()}</div>
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ProjectsPage,
    subscriptions: ["users", "projects", "organisations", "tasks", "quotes", "invoices", "purchaseOrders", "clients"],
  })
);
