import { Form, Input, InputNumber, Checkbox, Radio } from "antd";

import { getGroupNamesForUser } from "common/permissions";
import { CUSTOM_TASK_FIELD_MARKER } from "common/constants";

import DatePicker from "DatePicker/DatePicker";
import UsersFilter from "UsersFilter/UsersFilter";

export function displayCustomFieldsInCreationModal({ customFieldDefinitions, apiUser, groups }) {
  const groupNamesForUser = getGroupNamesForUser(apiUser.id, groups);

  return customFieldDefinitions
    .filter((field) => {
      if (field.isStatic) {
        return false;
      }

      if (!field.groupsThatCanSee || field.groupsThatCanSee.length === 0) {
        return true;
      }

      return field.groupsThatCanSee.some((group) => groupNamesForUser.includes(group));
    })
    .map((field) => {
      let formInputProps = {};
      let labelElement = field.label;
      let inputElement = null;

      switch (field.type) {
        case "TEXT":
          inputElement = <Input autoComplete="off" className={field.id} />;
          break;
        case "TEXTAREA":
          inputElement = (
            <Input.TextArea autoComplete="off" className={field.id} autoSize={{ minRows: 2, maxRows: 6 }} />
          );
          break;

        case "CHECKBOX":
          formInputProps.valuePropName = "checked";
          inputElement = <Checkbox className={field.id} />;
          break;

        case "NUMBER":
          inputElement = <InputNumber className={field.id} />;
          break;

        case "RADIO_LIST":
          inputElement = <Radio.Group className={field.id} options={field.options} />;
          break;

        case "CHECKBOX_LIST":
          inputElement = <Checkbox.Group className={field.id} options={field.options} />;
          break;

        case "DATE":
          inputElement = <DatePicker format="DD-MM-YYYY" className="field.id" />;
          break;
        case "USER":
          inputElement = <UsersFilter className={field.id} />;
          break;

        default:
          break;
      }

      if (!inputElement) {
        return null;
      }

      return (
        <Form.Item
          key={field.id}
          label={labelElement}
          name={`${CUSTOM_TASK_FIELD_MARKER}${field.id}`}
          rules={[
            {
              required: field.mandatory,
              message: "This field is mandatory",
            },
          ]}
          {...formInputProps}
        >
          {inputElement}
        </Form.Item>
      );
    });
}

export function extractCustomFieldsFromSubmitParams({ params, customFieldDefinitions }) {
  let customFields = [];
  for (let fieldName in params) {
    if (fieldName.startsWith(CUSTOM_TASK_FIELD_MARKER)) {
      let fieldId = fieldName.substring(CUSTOM_TASK_FIELD_MARKER.length);
      let fieldDefinition = customFieldDefinitions.find((x) => x.id === fieldId);
      let value = params[fieldName];

      if (fieldDefinition.type === "CHECKBOX") {
        if (value) {
          value = "checked";
        } else {
          value = undefined;
        }
      } else if (fieldDefinition.type === "CHECKBOX_LIST" && value) {
        value = JSON.stringify(value);
      }
      customFields.push({
        id: fieldId,
        value,
      });
    }
  }

  return customFields;
}

export function addCustomFieldsToInitialValues({ initialValues, customFieldDefinitions }) {
  customFieldDefinitions.forEach((fieldDefinition) => {
    if (initialValues[`${CUSTOM_TASK_FIELD_MARKER}${fieldDefinition.id}`]) {
      return;
    }
    if (fieldDefinition.defaultValue) {
      initialValues[`${CUSTOM_TASK_FIELD_MARKER}${fieldDefinition.id}`] = fieldDefinition.defaultValue;
    } else if (fieldDefinition.type === "RADIO_LIST") {
      initialValues[`${CUSTOM_TASK_FIELD_MARKER}${fieldDefinition.id}`] = fieldDefinition.options[0].value;
    }
  });
}
