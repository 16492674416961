import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { getUppercaseStatus } from "common/helpers";

export default async function changeTaskStatus({
  status,
  taskId,
  organisationDetails,
  order,
  recordActivity = true,
  authorIsUnknown = false,
}) {
  let task = (
    await callGraphQLSimple({
      queryName: "getTaskSimple",
      variables: {
        id: taskId,
      },
    })
  ).data.getTask;

  const input = getUpdatedTaskDetailsAfterChangingStatus({ status, taskId, organisationDetails });

  if (order) {
    input.order = order;
  }

  if (!task.isFinished && input.isFinished) {
    if (task.revisions && task.revisions.items.length > 0) {
      await callGraphQLSimple({
        message: `Failed to update old ${getSimpleLabel("task revision")}`,
        queryName: "updateTaskRevision",
        variables: {
          input: {
            id: task.revisions.items.slice(-1)[0].id,
            isReadOnly: true,
          },
        },
      });
    }
  } else if (task.isFinished && !input.isFinished) {
    if (task.revisions && task.revisions.items.length > 0) {
      let latestTaskRevision = task.revisions.items.slice(-1)[0];
      if (!latestTaskRevision.reviewAcceptDate) {
        await callGraphQLSimple({
          message: `Failed to update old ${getSimpleLabel("task revision")}`,
          queryName: "updateTaskRevision",
          variables: {
            input: {
              id: task.revisions.items.slice(-1)[0].id,
              isReadOnly: false,
            },
          },
        });
      }
    }
  }

  await callGraphQLSimple({
    queryCustom: "updateTask",
    message: `Failed to update ${getSimpleLabel("task")} status`,
    variables: {
      input,
    },
  });

  if (recordActivity) {
    await callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId,
          author: authorIsUnknown ? null : window.apiUser.id,
          organisation: organisationDetails.id,
          type: "STATUS_CHANGED",
          content: JSON.stringify({
            newStatus: input.status,
          }),
        },
      },
    });
  }
}

export function getUpdatedTaskDetailsAfterChangingStatus({ taskId, status, organisationDetails }) {
  let input = {
    id: taskId,
  };

  let newStatusDetails = (organisationDetails.taskStatuses || []).find(
    (statusDetails) => getUppercaseStatus(statusDetails.name) === getUppercaseStatus(status)
  );
  let newStatusMeansDone = false;
  if (newStatusDetails && newStatusDetails.type === "DONE") {
    newStatusMeansDone = true;
  }

  if (newStatusMeansDone) {
    input.isFinished = true;
    input.finishedAt = new Date().toISOString();
    input.archivedAt = null;
    input.isReadOnly = true;
  } else {
    input.isFinished = false;
    input.finishedAt = null;
  }

  input.status = newStatusDetails?.name || status;

  return input;
}
