import { useState, useEffect } from "react";
import { Tooltip, Typography } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import cx from "classnames";

import withSubscriptions from "common/withSubscriptions";

import ReviewCommentBox from "../ReviewCommentBox/ReviewCommentBox";
// import ReviewThreadItem from "../ReviewThreadItem/ReviewThreadItem";
import Switch from "Switch/Switch";
import ReviewActivityList from "ReviewPage/ReviewActivityList/ReviewActivityList";

import "./ReviewSheetActivity.scss";

export function ReviewSheetActivity({
  items,
  review,
  externalReview,
  isExternalReview,
  sheet,
  taskRevision,
  file,
  users,
  apiUser,
  zoom,
  task,
  onSubmitComment,
  onItemHoverStart,
  onItemHoverEnd,
  refreshDrawing,
  viewDocumentForComment,
  historicalVersionTimestamp,
  reviewIsActive,
  onHide,
  onShow,
  isOpen,
  highlightedAnnotationId,
  request,
}) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [oldItemCount, setOldItemCount] = useState(0);
  const [includeResolved, setIncludeResolved] = useState(true);

  // scroll to the bottom when new items come in and on first render
  useEffect(() => {
    const newItemCount = review.reviewThread.length;

    if (isFirstRender || newItemCount > oldItemCount) {
      setTimeout(() => {
        try {
          const activityList = document.querySelector(".review-sheet-activity .scrollable-content");
          activityList.scrollTop = activityList.scrollHeight;
        } catch (e) {
          // nothing we can do, ignoring this
        }
      }, 0);
    }
    setIsFirstRender(false);

    setOldItemCount(newItemCount);
  }, [isFirstRender, review]); // eslint-disable-line

  function onToggleClick() {
    if (isOpen) {
      onHide();
    } else {
      onShow();
    }
  }

  let userIsReviewer = apiUser.id === taskRevision.checkedBy;
  let userIsAuthor = apiUser.id === taskRevision.author;

  function displayActivity() {
    let itemsToDisplay = items.filter(
      (x) => !x.type.includes("ANNOTATION") || x.type === "ANNOTATION_TEXT" || x.type === "ANNOTATION_LEADER_LINE"
    );

    if (!includeResolved) {
      itemsToDisplay = itemsToDisplay.filter((x) => !x.resolved);
    }
    return (
      <ReviewActivityList
        items={itemsToDisplay}
        users={users}
        task={task}
        reviewIsActive={reviewIsActive}
        taskRevision={taskRevision}
        includeResolved={includeResolved}
        apiUser={apiUser}
        review={review}
        request={request}
        externalReview={externalReview}
        highlightedAnnotationId={highlightedAnnotationId}
        isExternalReview={isExternalReview}
        extraItemProps={{
          compact: true,
          review: review,
          users: users,
          sheet: sheet,
          taskRevision: taskRevision,
          file: file,
          revisionAuthor: taskRevision.author,
          revisionReviewer: taskRevision.checkedBy,
          onHoverStart: onItemHoverStart,
          onHoverEnd: onItemHoverEnd,
          refreshDrawing,
          zoom: zoom,
          isActivityBarOpen: isOpen,
          viewDocumentForComment: viewDocumentForComment,
          historicalVersionTimestamp: historicalVersionTimestamp,
        }}
      />
    );
  }

  return (
    <div className={cx("review-sheet-activity", { "is-closed": !isOpen })}>
      <Tooltip trigger={["hover", "click"]} title={isOpen ? "Close panel" : "Open panel"}>
        <div className="toggle-activity" onClick={onToggleClick}>
          {isOpen ? <CaretRightOutlined /> : <CaretLeftOutlined />}
        </div>
      </Tooltip>
      <Typography.Title level={3} className="activity-title">
        Activity so far
        <Switch
          className="include-resolved"
          checked={includeResolved}
          onChange={(checked) => {
            setIncludeResolved(checked);
          }}
          label="Include resolved"
          size="small"
        />
      </Typography.Title>
      {displayActivity()}
      <ReviewCommentBox
        task={task}
        apiUser={apiUser}
        users={users}
        onSubmitComment={onSubmitComment}
        userIsReviewer={userIsReviewer}
        userIsAuthor={userIsAuthor}
        taskRevision={taskRevision}
      />
    </div>
  );
}

export default withSubscriptions({
  Component: ReviewSheetActivity,
  subscriptions: ["users"],
});
