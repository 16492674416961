import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Typography, Input, Checkbox, Dropdown, Select, Menu } from "antd";
import query from "query-string";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";

import { COOKIE_NAME_REQUEST_FILTERS, REQUEST_STATUSES } from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import { getMonthlyDateRangePresets, getLabel } from "common/helpers";
import { getSimpleLabel } from "common/labels";

import DatePicker from "DatePicker/DatePicker";
import AvatarList from "AvatarList/AvatarList";

import moment from "moment";

import "./RequestFilters.scss";

export class RequestFilters extends React.Component {
  state = {
    containsText: "",
    selectedUsers: [],
    clientId: null,
    projectId: null,
    includeActive: true,
    includeOnHold: true,
    includeArchived: false,
    isDropdownVisible: false,
    createdAtStart: null,
    createdAtEnd: null,
    includeInitialRequests: true,
    includeChangeRequests: true,
  };

  async componentDidMount() {
    const { location } = this.props;

    const queryParams = query.parse(location.search);
    let newState = {};

    const cookieValue = await window.localDatabase.getItem(
      `${COOKIE_NAME_REQUEST_FILTERS}-${this.props.organisationDetails.id}`
    );

    if (cookieValue) {
      try {
        const cookieValueParsed = JSON.parse(cookieValue);
        const { isDropdownVisible, ...existingFilters } = cookieValueParsed;
        newState = existingFilters;
      } catch (e) {
        console.error("Failed to parse local database value for RequestFilters:", cookieValue);
      }
    }

    if (queryParams.containsText) {
      newState.containsText = queryParams.containsText;
    }

    if (queryParams.selectedUsers) {
      if (Array.isArray(queryParams.selectedUsers)) {
        newState.selectedUsers = queryParams.selectedUsers;
      } else {
        newState.selectedUsers = [queryParams.selectedUsers];
      }
    }

    if (newState.selectedUsers) {
      // eliminate users that are not found in the users array
      newState.selectedUsers = newState.selectedUsers.filter((userId) =>
        this.props.users.find((user) => user.id === userId)
      );
    }

    if (queryParams.includeActive) {
      newState.includeActive = queryParams.includeActive === "true";
    }

    if (queryParams.includeOnHold) {
      newState.includeOnHold = queryParams.includeOnHold === "true";
    }

    if (queryParams.includeInitialRequests) {
      newState.includeInitialRequests = queryParams.includeInitialRequests === "true";
    }

    if (queryParams.includeChangeRequests) {
      newState.includeChangeRequests = queryParams.includeChangeRequests === "true";
    }

    if (queryParams.includeArchived) {
      newState.includeArchived = queryParams.includeArchived === "true";
    }

    if (queryParams.includeFinished) {
      newState.includeFinished = queryParams.includeFinished === "true";
    }

    if (queryParams.clientId) {
      newState.clientId = queryParams.clientId;
    }

    if (queryParams.projectId) {
      newState.projectId = queryParams.projectId;
    }

    this.setState(newState, this.onChange);
  }

  changeDateFilterRange = (dates, datesStr) => {
    let createdAtStart = null;
    let createdAtEnd = null;

    if (dates) {
      createdAtStart = datesStr[0];
      createdAtEnd = datesStr[1];
    }

    this.setState(
      {
        createdAtStart,
        createdAtEnd,
      },
      this.onChange
    );
  };

  onChange = () => {
    const {
      selectedUsers,
      containsText,
      clientId,
      projectId,
      createdAtStart,
      createdAtEnd,
      includeActive,
      includeOnHold,
      includeArchived,
      includeFinished,
      includeInitialRequests,
      includeChangeRequests,
    } = this.state;

    const { isDropdownVisible, ...stateForQueryParams } = this.state;

    const { history, location, organisationDetails } = this.props;

    const nonNullKeys = Object.keys(stateForQueryParams).filter(
      (keyName) =>
        stateForQueryParams[keyName] !== null &&
        stateForQueryParams[keyName] !== undefined &&
        stateForQueryParams[keyName] !== "" &&
        stateForQueryParams[keyName] !== false
    );
    const queryParams = {};
    nonNullKeys.forEach((keyName) => {
      queryParams[keyName] = stateForQueryParams[keyName];
    });
    const queryString = query.stringify(queryParams);

    history.replace(`${location.pathname}?${queryString}`);

    let filter = {
      callbacks: [],
    };

    if (!includeActive) {
      filter.callbacks.push((request) => {
        let statusDetails = REQUEST_STATUSES.find((status) => status.value === request.status);
        if (statusDetails.meansOnHold) {
          return true;
        }
        return statusDetails?.meansFinished;
      });
    }

    if (!includeOnHold) {
      filter.callbacks.push((request) => {
        let statusDetails = REQUEST_STATUSES.find((status) => status.value === request.status);
        return !statusDetails.meansOnHold;
      });
    }

    if (!includeInitialRequests) {
      filter.callbacks.push((request) => !request.latestFormName?.startsWith("Initial"));
    }

    if (!includeChangeRequests) {
      filter.callbacks.push((request) => !request.latestFormName?.startsWith("Change"));
    }

    if (!includeArchived) {
      filter.callbacks.push((request) => !request.isArchived);
    }

    if (!includeFinished) {
      filter.callbacks.push((request) => {
        let statusDetails = REQUEST_STATUSES.find((status) => status.value === request.status);
        if (statusDetails.meansOnHold) {
          return true;
        }
        return !statusDetails?.meansFinished;
      });
    }

    if (clientId) {
      filter.callbacks.push((request) => {
        return request.clientId === clientId;
      });
    }

    if (projectId) {
      filter.callbacks.push((request) => {
        return request.projectId === projectId;
      });
    }

    if (!organisationDetails.settings?.request?.hideAssignedTo && selectedUsers && selectedUsers.length > 0) {
      filter.callbacks.push((task) => {
        if (selectedUsers.includes("unassigned") && !task.assignedTo) {
          return true;
        } else if (selectedUsers.includes(task.assignedTo)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (containsText) {
      filter.containsText = {
        contains: (containsText || "").toLowerCase(),
      };
    }

    if (createdAtStart && createdAtEnd) {
      filter.callbacks.push((request) => {
        return (
          moment(createdAtStart, "DD-MM-YYYY").isSameOrBefore(request.createdAt, "day") &&
          moment(createdAtEnd, "DD-MM-YYYY").isSameOrAfter(request.createdAt, "day")
        );
      });
    }

    window.localDatabase.setItem(
      `${COOKIE_NAME_REQUEST_FILTERS}-${this.props.organisationDetails.id}`,
      JSON.stringify(this.state)
    );

    this.props.onChange(filter);
  };

  onUserSelected = (_, user) => {
    if (!user) {
      user = { id: "unassigned" };
    }
    let selectedUsers = this.state.selectedUsers || [];
    if (selectedUsers.includes(user.id)) {
      selectedUsers = selectedUsers.filter((x) => x !== user.id);
    } else {
      selectedUsers.push(user.id);
    }
    this.setState({ selectedUsers }, this.onChange);
  };

  render() {
    const {
      containsText,
      selectedUsers,
      includeActive,
      includeOnHold,
      includeFinished,
      includeInitialRequests,
      includeChangeRequests,
      isDropdownVisible,
      clientId,
      projectId,
    } = this.state;

    const { projects, clients, orderedActiveUsers, organisationDetails } = this.props;

    let dateRangePresets = getMonthlyDateRangePresets();

    let createdAtStart = null;
    let createdAtEnd = null;
    if (
      this.state.createdAtStart &&
      this.state.createdAtEnd &&
      typeof this.state.createdAtStart === "string" &&
      typeof this.state.createdAtEnd === "string" &&
      this.state.createdAtStart.length <= 10 &&
      this.state.createdAtEnd.length <= 10
    ) {
      createdAtStart = moment(this.state.createdAtStart, "DD-MM-YYYY");
      createdAtEnd = moment(this.state.createdAtEnd, "DD-MM-YYYY");
    }

    const filterMenu = (
      <Menu>
        <Menu.Item>
          <Checkbox
            checked={includeActive}
            onChange={(e) => this.setState({ includeActive: e.target.checked }, this.onChange)}
          >
            Include active
          </Checkbox>
        </Menu.Item>

        <Menu.Item>
          <Checkbox
            checked={includeOnHold}
            onChange={(e) => this.setState({ includeOnHold: e.target.checked }, this.onChange)}
          >
            Include on hold
          </Checkbox>
        </Menu.Item>

        <Menu.Item>
          <Checkbox
            checked={includeFinished}
            onChange={(e) => this.setState({ includeFinished: e.target.checked }, this.onChange)}
          >
            Include finished
          </Checkbox>
        </Menu.Item>

        <Menu.Item>
          <Checkbox
            checked={includeInitialRequests}
            onChange={(e) => this.setState({ includeInitialRequests: e.target.checked }, this.onChange)}
          >
            Include initial requests
          </Checkbox>
        </Menu.Item>

        <Menu.Item>
          <Checkbox
            checked={includeChangeRequests}
            onChange={(e) => this.setState({ includeChangeRequests: e.target.checked }, this.onChange)}
          >
            Include change requests
          </Checkbox>
        </Menu.Item>

        {/* <Menu.Item>
          <Checkbox
            checked={includeArchived}
            onChange={(e) => this.setState({ includeArchived: e.target.checked }, this.onChange)}
          >
            Include archived
          </Checkbox>
        </Menu.Item> */}

        <Menu.Divider />

        <Menu.Item key="created-in-range">
          <div className="created-in-range-container">
            <Typography.Text className="top-label">Created in range:</Typography.Text>
            <br />
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              dropdownClassName="task-created-date-range-picker"
              value={[
                typeof createdAtStart === "string" ? moment(createdAtStart) : createdAtStart,
                typeof createdAtEnd === "string" ? moment(createdAtEnd) : createdAtEnd,
              ]}
              onChange={this.changeDateFilterRange}
              ranges={dateRangePresets}
            />
          </div>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item className="flex-menu-item">
          <Typography.Text className="left-label">Client:</Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder={`Choose a ${getSimpleLabel("client")}`}
            className="request-filter-client"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(clientId) => {
              this.setState({ clientId }, this.onChange);
            }}
            value={clientId}
          >
            {clients.map((client, i) => (
              <Select.Option key={i} value={client.id}>
                {client.name}
              </Select.Option>
            ))}
          </Select>
        </Menu.Item>

        <Menu.Item className="flex-menu-item">
          <Typography.Text className="left-label">
            {getLabel({
              id: "Project",
              defaultValue: "Project",
            })}
            :
          </Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder={`Choose a ${getLabel({
              id: "project",
              defaultValue: "project",
            })}`}
            className="request-filter-project"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(projectId) => {
              this.setState({ projectId }, this.onChange);
            }}
            value={projectId}
          >
            {projects
              .filter((project) => !project.isArchived && !project.id.includes("TEMPLATES"))
              .map((project, i) => (
                <Select.Option key={i} value={project.id}>
                  {project.title}
                </Select.Option>
              ))}
          </Select>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="request-filters">
        <Input
          className="filter-input"
          placeholder={`Filter ${getSimpleLabel("request")} list`}
          allowClear
          prefix={<SearchOutlined />}
          onChange={(e) => this.setState({ containsText: e.target.value }, this.onChange)}
          value={containsText}
        />
        {!organisationDetails.settings?.request?.hideAssignedTo && (
          <AvatarList
            users={orderedActiveUsers}
            onClick={this.onUserSelected}
            selectedUsers={selectedUsers}
            containerWidthToSubtract={160 + 120 + 120 + 140 + 100}
          />
        )}

        <Dropdown
          trigger={["click"]}
          overlayClassName="request-filters-dropdown-overlay"
          overlay={filterMenu}
          open={isDropdownVisible}
          onVisibleChange={(isDropdownVisible) => this.setState({ isDropdownVisible })}
        >
          <Button className="filter-button">
            Filters
            <DownOutlined />
          </Button>
        </Dropdown>
        <Button className="export" onClick={this.props.export} data-cy="export-to-csv-button">
          Export to CSV
        </Button>
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: RequestFilters,
    subscriptions: ["organisationDetails", "users", "clients", "projects"],
  })
);
