import React from "react";
import moment from "moment";
import cx from "classnames";
import _ from "lodash";
import { TwitterPicker } from "react-color";
import { Modal, Tooltip, Tag } from "antd";
import {
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined,
  DoubleRightOutlined,
  CopyOutlined,
  EditOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";

import { DragHandleIcon } from "common/icons";
import { callGraphQLSimple } from "common/apiHelpers";
import { getReadableStatus } from "common/helpers";
import { add1DPointsToBlock, dateAndHoursToValue1D, taskIdToColor } from "../timelineHelpers";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";
import { getTimelineBlockColor, hasCustomFunction } from "common/naming";
import { DEFAULT_TIMELINE_BLOCK_PROPERTIES } from "../TimelinePage";
import { processIdForDisplay } from "common/shared";
import { getSimpleLabel } from "common/labels";

import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";

import "./TimelineBlock.scss";

const THRESHOLD_FOR_DRAG_RESIZE = 5;

const COLOR_OPTIONS = [
  "#004c6d",
  "#ff0000",
  "#ffffff",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
];

export default class TimelineBlock extends React.Component {
  state = {
    isRenaming: false,
    dragHandle: null,
    dragStartCoordinates: null,
    newDuration: null,
    newStartDate: null,
    newStartHours: null,
    isSetColorModalOpen: false,
    isColorPickerOpen: false,
    newColor: undefined,
    colorBasedOnCustomOrganisationLogic: undefined,
  };

  constructor(props) {
    super(props);
    this.throttledOnWindowMouseMove = _.throttle(this.onWindowMouseMove, 100);
  }

  componentDidMount() {
    this.computeColorBasedOnCustomOrganisationLogic();
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.throttledOnWindowMouseMove);
    window.removeEventListener("mouseup", this.stopRenaming);
    window.removeEventListener("touchmove", this.throttledOnWindowMouseMove);
    window.removeEventListener("touchend", this.onWindowMouseUp);
  }

  startResize = (e, handle) => {
    this.props.onResizeStart();

    window.addEventListener("touchmove", this.throttledOnWindowMouseMove);
    window.addEventListener("touchend", this.onWindowMouseUp);

    window.addEventListener("mouseup", this.onWindowMouseUp);
    window.addEventListener("mousemove", this.throttledOnWindowMouseMove);

    this.setState({
      dragHandle: handle,
      dragStartCoordinates: e.touches
        ? {
            x: e.touches[0].pageX,
            y: e.touches[0].pageY,
          }
        : {
            x: e.pageX,
            y: e.pageY,
          },
    });
  };

  startRenaming = () => {
    this.setState({
      isRenaming: true,
    });

    window.addEventListener("mouseup", this.stopRenaming);
  };

  onRename = (newName) => {
    this.stopRenaming();
    this.props.onRenameBlock(newName);
  };

  stopRenaming = () => {
    this.setState({
      isRenaming: false,
    });
    window.removeEventListener("mouseup", this.stopRenaming);
  };

  componentDidUpdate(_, prevState) {
    if (this.state.isRenaming && !prevState.isRenaming) {
      document.querySelector(`.timeline-block[data-task-id="${this.props.block.taskId}"] input`).focus();
    }

    this.computeColorBasedOnCustomOrganisationLogic();
  }

  computeColorBasedOnCustomOrganisationLogic = async () => {
    if (!hasCustomFunction("getTimelineBlockColor", this.props.organisationDetails?.id)) {
      return;
    }

    const { organisationDetails, projectsById, clientsById, block } = this.props;

    const task = this.getTask();
    let project;
    let client;

    if (task) {
      project = projectsById?.[task.projectId];
      client = clientsById?.[project?.clientId];
    }

    let colorBasedOnCustomOrganisationLogic = await getTimelineBlockColor({
      timelineBlock: block,
      task,
      project,
      client,
      organisationDetails,
      organisation: organisationDetails.id,
    });

    if (colorBasedOnCustomOrganisationLogic !== this.state.colorBasedOnCustomOrganisationLogic) {
      this.setState({ colorBasedOnCustomOrganisationLogic });
    }
  };

  resetDragState = async (params) => {
    const { includeReflow = false } = params || {};
    this.setState({
      newDuration: null,
      newStartDate: null,
      newStartHours: null,
      dragStartCoordinates: null,
      moveDeltaX: null,
      moveDeltaY: null,
      dragHandle: null,
    });
    if (includeReflow) {
      await callGraphQLSimple({
        message: "Failed to update block",
        mutation: "updateTimelineBlock",
        variables: {
          input: this.props.getPropertiesFromBlock({
            block: this.props.block,
            properties: DEFAULT_TIMELINE_BLOCK_PROPERTIES,
          }),
        },
        // displayError: false,
      });
      this.props.reflowTimeline({
        startingBlock: this.props.block,
      });
    }
  };

  onWindowMouseMove = (e) => {
    if (window.isModalVisible) {
      return;
    }
    e.stopPropagation();

    const { snapCoefficientDays, hoursInADay, block } = this.props;
    const { dragStartCoordinates, dragHandle } = this.state;

    if (!dragStartCoordinates) {
      return;
    }

    let currentCoordinates = {};
    if (e.touches) {
      currentCoordinates = {
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
      };
    } else {
      currentCoordinates = {
        x: e.pageX,
        y: e.pageY,
      };
    }

    let moveDeltaX = currentCoordinates.x - dragStartCoordinates.x;
    let moveDeltaY = currentCoordinates.y - dragStartCoordinates.y;

    let newState = {
      moveDeltaX,
      moveDeltaY,
    };

    if (dragHandle === "down" || dragHandle === "up") {
      // nothing to do
    } else {
      if (dragHandle === "end") {
        let newDuration = block.durationHours + this.pixelsToHours(moveDeltaX);
        newDuration =
          (Math.round((newDuration / hoursInADay) * (1 / snapCoefficientDays)) / (1 / snapCoefficientDays)) *
          hoursInADay;
        if (newDuration < snapCoefficientDays * hoursInADay) {
          newDuration = snapCoefficientDays * hoursInADay;
        }
        newState.newDuration = newDuration;
      } else if (dragHandle === "start") {
        let newDuration = block.durationHours + this.pixelsToHours(-moveDeltaX);

        newDuration =
          (Math.round((newDuration / hoursInADay) * (1 / snapCoefficientDays)) / (1 / snapCoefficientDays)) *
          hoursInADay;
        if (newDuration < snapCoefficientDays * hoursInADay) {
          newDuration = snapCoefficientDays * hoursInADay;
        }

        let durationDifferenceHoursTotal = Math.abs(newDuration - block.durationHours);
        if (moveDeltaX < 0) {
          if (durationDifferenceHoursTotal <= block.startHours) {
            newState.newStartDate = block.startDate;
            newState.newStartHours = block.startHours - durationDifferenceHoursTotal;
          } else {
            const durationDifferenceHours1 = durationDifferenceHoursTotal - block.startHours;
            const durationDifferenceFullDays = Math.floor(durationDifferenceHours1 / hoursInADay);
            const durationDifferenceHours2 = durationDifferenceHours1 - durationDifferenceFullDays * hoursInADay;
            newState.newStartHours = hoursInADay - durationDifferenceHours2;
            const daysToSubtract = 1 + durationDifferenceFullDays;
            newState.newStartDate = moment(block.startDate).subtract(daysToSubtract, "days").format("YYYY-MM-DD");
          }
        } else {
          if (durationDifferenceHoursTotal + block.startHours < hoursInADay) {
            newState.newStartDate = block.startDate;
            newState.newStartHours = block.startHours + durationDifferenceHoursTotal;
          } else {
            const durationDifferenceHours1 = durationDifferenceHoursTotal - (hoursInADay - block.startHours);
            const durationDifferenceFullDays = Math.floor(durationDifferenceHours1 / hoursInADay);
            const durationDifferenceHours2 = durationDifferenceHours1 - durationDifferenceFullDays * hoursInADay;
            newState.newStartHours = durationDifferenceHours2;
            const daysToAdd = 1 + durationDifferenceFullDays;
            newState.newStartDate = moment(block.startDate).add(daysToAdd, "days").format("YYYY-MM-DD");
          }
        }
        newState.newDuration = newDuration;
      }
    }

    this.setState(newState);
  };

  onWindowMouseUp = async () => {
    const { dragStartCoordinates, newDuration, newStartDate, newStartHours, moveDeltaX, moveDeltaY, dragHandle } =
      this.state;
    this.stopRenaming();
    window.removeEventListener("touchmove", this.throttledOnWindowMouseMove);
    window.removeEventListener("touchend", this.onWindowMouseUp);
    window.removeEventListener("mousemove", this.throttledOnWindowMouseMove);
    window.removeEventListener("mouseup", this.onWindowMouseUp);

    const {
      context,
      timelineBlocks,
      block,
      isTaskDetailsModalVisible,
      isLocked,
      snapCoefficientDays,
      hoursInADay,
      onResizeEnd,
      showLockedModal,
      organisationDetails,
    } = this.props;
    if (window.isModalVisible || isTaskDetailsModalVisible) {
      return;
    }

    if (
      (dragHandle === "down" || dragHandle === "up") &&
      dragStartCoordinates &&
      moveDeltaY &&
      Math.abs(moveDeltaY) > THRESHOLD_FOR_DRAG_RESIZE
    ) {
      await this.handleDragEndDown();
      return;
    } else if (dragStartCoordinates && moveDeltaX && Math.abs(moveDeltaX) > THRESHOLD_FOR_DRAG_RESIZE) {
      if (onResizeEnd && typeof onResizeEnd === "function") {
        onResizeEnd();
      }
      if (isLocked) {
        try {
          await showLockedModal();
        } catch (e) {
          this.resetDragState();
          // it means the user has said no
          return;
        }
      }

      let updatedBlock = {
        durationHours: Math.max(newDuration, snapCoefficientDays * hoursInADay),
      };

      if (newStartDate !== null && newStartDate !== undefined) {
        updatedBlock.startDate = newStartDate;
      }
      if (newStartHours !== null && newStartHours !== undefined) {
        updatedBlock.startHours = newStartHours;
      }

      let updatedContext = {
        ...context,
        timelineBlocks: timelineBlocks.map((crtBlock) => {
          if (crtBlock.id !== block.id) {
            return crtBlock;
          }

          return {
            ...crtBlock,
            ...updatedBlock,
          };
        }),
      };

      this.props.setProps(
        {
          context: updatedContext,
        },
        () => this.resetDragState({ includeReflow: true })
      );
      if (!organisationDetails.settings?.timeline?.usesPhysicalBlockInteraction) {
        await callGraphQLSimple({
          message: "Failed to update timeline block",
          queryName: "updateTimelineBlock",
          variables: {
            input: {
              id: block.id,
              ...updatedBlock,
              createdAt: undefined,
            },
          },
        });
      }
    } else {
      this.resetDragState();
      if (onResizeEnd && typeof onResizeEnd === "function") {
        onResizeEnd();
      }
    }
  };

  handleDragEndDown = async () => {
    const { block, addBlockToUser, onResizeEnd, validUsers, rowIndex } = this.props;
    let rowsSpanned = this.getRowsSpannedWithDrag();

    if (rowsSpanned > 0) {
      let startIndex = rowIndex + 1;
      let endIndex = rowIndex + 1 + rowsSpanned;

      for (let i = startIndex; i < endIndex; i++) {
        let crtUser = validUsers[i];
        await addBlockToUser({
          e: undefined,
          newBlock: {
            ...block,
            id: String(Date.now()) + String(Math.floor(Math.random() * 10000)),
            userId: crtUser.id,
          },
          selectedUser: crtUser,
          draggableType: "pseudo-task",
          waitForResponse: false,
        });
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
    }

    this.resetDragState();

    if (onResizeEnd && typeof onResizeEnd === "function") {
      onResizeEnd();
    }
  };

  pixelsToHours = (pixels) => {
    return pixels / this.props.hourBlockWidth;
  };

  onFreeze = async () => {
    const { block } = this.props;
    await callGraphQLSimple({
      message: "Failed to update block",
      mutation: "updateTimelineBlock",
      variables: {
        input: {
          id: block.id,
          isFixed: true,
        },
      },
    });
  };

  onUnfreeze = async () => {
    const { block } = this.props;
    await callGraphQLSimple({
      message: "Failed to update block",
      mutation: "updateTimelineBlock",
      variables: {
        input: {
          id: block.id,
          isFixed: false,
        },
      },
    });
  };

  displaySetColorModal = () => {
    const { isSetColorModalOpen, isColorPickerOpen, newColor } = this.state;
    const { block } = this.props;

    if (!isSetColorModalOpen) {
      return null;
    }

    return (
      <Modal
        title="Set block colour"
        open={true}
        className="timeline-block-color-modal"
        onCancel={() => {
          this.setState({
            isColorPickerOpen: false,
            isSetColorModalOpen: false,
            newColor: undefined,
          });
        }}
        onOk={() => {
          this.setState({
            isSetColorModalOpen: false,
            isColorPickerOpen: false,
            newColor: undefined,
          });
          callGraphQLSimple({
            message: "Failed to update timeline block",
            queryName: "updateTimelineBlock",
            variables: {
              input: {
                id: block.id,
                userChosenColor: newColor,
              },
            },
          });
        }}
      >
        <InfoItem
          inline
          label="New colour"
          value={
            <>
              <div
                className={cx("color-box", {
                  transparent: block.userChosenColor === "transparent",
                })}
                style={{ backgroundColor: newColor }}
                onClick={() =>
                  this.setState({
                    isColorPickerOpen: !isColorPickerOpen,
                  })
                }
              />
              {isColorPickerOpen ? (
                <TwitterPicker
                  onChange={(newColor) => {
                    this.setState({
                      isColorPickerOpen: false,
                      newColor: newColor.hex === "#123456" ? "transparent" : newColor.hex,
                    });
                  }}
                  colors={[...COLOR_OPTIONS, "#123456"]}
                />
              ) : null}
            </>
          }
        />
      </Modal>
    );
  };

  getBlockCoordinates = () => {
    const { newDuration, newStartDate, newStartHours } = this.state;
    const { block, planningStartDate, planningEndDate, hourBlockWidth, dayCellWidth, rowTop, rowHeight } = this.props;

    let startDate = moment(block.startDate);
    if (newStartDate) {
      startDate = moment(newStartDate);
    }
    let startHours = block.startHours;
    if (newStartHours !== null) {
      startHours = newStartHours;
    }

    let startDaysSincePlanningStart = startDate.diff(planningStartDate, "days");

    let left = startDaysSincePlanningStart * dayCellWidth + startHours * hourBlockWidth;

    let durationHours = block.durationHours;

    if (newDuration) {
      durationHours = newDuration;
    }

    const blockBounds = add1DPointsToBlock({ block, axisStartDate: moment(planningStartDate) });

    if (blockBounds.endDate.isAfter(planningEndDate, "day")) {
      durationHours = blockBounds.endDate.diff(blockBounds.startDate, "days") * TIMELINE_DEFAULT_HOURS_IN_A_DAY;
    }

    let width = durationHours * hourBlockWidth;

    if (block.taskId) {
      width -= 2;
      left += 1;
    }

    return {
      width,
      left,
      durationHours,
      startHours,
      top: rowTop,
      height: rowHeight,
    };
  };

  displayTooltipTitle = ({ task, project }) => {
    const { block, organisationDetails, onRemoveBlock, onCopyBlock, onOpenTask } = this.props;

    let info = null;
    if (block.isPseudoTask) {
      info = (
        <>
          <span>{block.taskId}</span>
        </>
      );
    } else {
      if (task) {
        info = (
          <>
            <Tag color="white">{processIdForDisplay(task.id)}</Tag>
            {project && <span>{project.title}</span>}
            <span>{task.title}</span>
            {!organisationDetails.settings?.task?.hideTaskDueDates && task.dueDate && (
              <span>Due on {moment(task.dueDate).format("DD/MM/YYYY")}</span>
            )}
            {task.isUnderReview && <span>Under review</span>}
          </>
        );
      } else {
        info = (
          <>
            <span>{getSimpleLabel("Task")} not found</span>
          </>
        );
      }
    }

    return (
      <div data-cy="block-context-menu" data-block-id={block.id}>
        <div className="menu-section">
          {task && (
            <div onClick={onOpenTask} key="open" className="menu-item">
              <DoubleRightOutlined /> <b>Open {getSimpleLabel("task")}</b>
            </div>
          )}

          {organisationDetails.settings?.timeline?.usesPhysicalBlockInteraction &&
            !block.isFixed &&
            !block.isPseudoTask && (
              <div className="menu-item" onClick={this.onFreeze} key="freeze">
                <LockOutlined /> Freeze block
              </div>
            )}
          {block.isFixed && (
            <div className="menu-item" onClick={this.onUnfreeze} key="unfreeze">
              <UnlockOutlined /> Unfreeze block
            </div>
          )}

          {block.isPseudoTask && (
            <div className="menu-item" onClick={this.startRenaming} key="rename">
              <EditOutlined /> Rename
            </div>
          )}

          {(!task || !organisationDetails.settings?.timeline?.usesColoredBlocks) && (
            <div
              className="menu-item"
              onClick={() => {
                this.setState({
                  isSetColorModalOpen: true,
                  newColor: block.userChosenColor,
                });
              }}
              key="change-color"
            >
              <BgColorsOutlined /> Change colour
            </div>
          )}

          <div className="menu-item" onClick={onCopyBlock} key="copy">
            <CopyOutlined /> Copy block
          </div>

          <div className="menu-item" onClick={onRemoveBlock} key="remove">
            <DeleteOutlined /> Remove block
          </div>
        </div>

        <div className="info-section">
          <b>
            {block.durationHours} hour{block.durationHours === 1 ? "" : "s"}
          </b>
          {info}
        </div>
      </div>
    );
  };

  getRowsSpannedWithDrag = () => {
    const { moveDeltaY } = this.state;
    if (moveDeltaY === null || moveDeltaY === undefined || moveDeltaY <= THRESHOLD_FOR_DRAG_RESIZE) {
      return 0;
    }

    let rowsSpanned = Math.ceil(moveDeltaY / this.props.rowHeight);
    return rowsSpanned;
  };

  displayDragToCopyBox = () => {
    let rowsSpanned = this.getRowsSpannedWithDrag();

    return (
      <div
        className="drag-to-copy-box"
        style={{
          height: rowsSpanned * this.props.rowHeight,
          top: this.props.rowHeight - 16,
          opacity: rowsSpanned > 0 ? 1 : 0,
        }}
      />
    );
  };

  displayTimelineBlock = ({ task, project, left, width, isTouchDevice, isInteractive, isRestricted }) => {
    const {
      block,
      axisStartDate,
      isDraggingBlock,
      dragStartCoordinates,
      showProjectTitles,
      organisationDetails,
      isOutOfWorkingHours,
      approvalStatus,
      setSelectedBlockForTooltip,
    } = this.props;

    const { isRenaming } = this.state;

    let customClassNames = [];
    let color;

    let dueDate = task?.dueDate;
    let blockIsOverdue = false;
    let blockIsDueOnTheDay = false;
    if (dueDate) {
      let dueDate1D = dateAndHoursToValue1D({
        date: dueDate,
        hours: TIMELINE_DEFAULT_HOURS_IN_A_DAY,
        axisStartDate,
      });

      const blockBounds = add1DPointsToBlock({ block, axisStartDate });
      blockIsOverdue = blockBounds.end1D > dueDate1D;

      if (blockBounds.endHours === 0) {
        blockBounds.endDate.subtract(1, "day");
      }
      if (moment(dueDate).isSame(blockBounds.endDate, "day")) {
        blockIsDueOnTheDay = true;
      }
    }

    if (block.userChosenColor && block.userChosenColor !== "undefined" && block.userChosenColor !== "null") {
      color = block.userChosenColor;
    } else {
      if (this.state.colorBasedOnCustomOrganisationLogic) {
        color = this.state.colorBasedOnCustomOrganisationLogic;
      } else {
        if (organisationDetails.settings?.timeline?.usesColoredBlocks) {
          customClassNames.push("default");

          if (task?.isUnderReview) {
            customClassNames.push("under-review");
          }
          if (blockIsOverdue) {
            customClassNames.push("overdue");
          }
          if (blockIsDueOnTheDay) {
            customClassNames.push("due-on-the-day");
          }
          if (task?.isArchived) {
            customClassNames.push("archived");
          }
          if (task?.isFinished) {
            customClassNames.push("finished");
          }
        } else {
          color = taskIdToColor(block.taskId);
        }

        if (!task && !block.isPseudoTask) {
          color = "#ddd";
        }
      }
    }

    let className = cx("timeline-block", customClassNames, approvalStatus, {
      "is-renaming": isRenaming,
      "is-interactive": isInteractive,
      "not-interactive": !isInteractive,
      "is-restricted": isRestricted,
      "is-out-of-working-hours": isOutOfWorkingHours,
      "is-cypress": window.Cypress,
      "is-resizing": this.state.dragHandle,
      unconfirmed: task && !task.isConfirmed && organisationDetails.settings?.general?.usesTaskConfirmation,
      "pseudo-task":
        block.isPseudoTask &&
        (!block.userChosenColor || block.userChosenColor === "undefined" || block.userChosenColor === "null"),
      "disable-events": isDraggingBlock || dragStartCoordinates,
      "without-project-title": organisationDetails?.settings?.task?.automaticallyCreateProject || !showProjectTitles,
      new: moment(block.createdAt).isAfter(moment().subtract(10, "seconds")),
      "is-on-top": this.props.isOnTop,
      "is-fixed": block.isFixed,
    });

    let blockProps = {
      "data-task-id": block.taskId,
      "data-task-revision-id": block.taskRevisionId,
      "data-task-status": block.taskStatus,
    };
    if (window.Cypress && !isOutOfWorkingHours) {
      blockProps = {
        ...blockProps,
        "data-cy": "timeline-block",
        "data-duration-hours": block.durationHours,
        "data-block-id": block.id,
        "data-offset-x": left || 0,
      };
    }

    return (
      <div
        className={className}
        style={{
          left: left || 0,
          width: width || 0,
        }}
        {...blockProps}
        onClick={(e) => {
          e.stopPropagation();
          if (this.props.selectedBlockForTooltip?.id === block.id) {
            setSelectedBlockForTooltip(undefined);
          } else {
            setSelectedBlockForTooltip(block);
          }
        }}
        onMouseDown={(e) => {
          // if we don't do this, the whole timeline will pan when we drag or resize the block
          e.stopPropagation();
        }}
      >
        {isOutOfWorkingHours ? (
          <div className="empty-background" />
        ) : (
          <>
            <div
              className="background"
              style={{
                backgroundColor: customClassNames.length > 0 ? undefined : color,
              }}
            />
            {block.isFixed && <LockOutlined className="fixed-block-icon" />}

            {showProjectTitles && task && (
              <span className="project-title">
                {!organisationDetails?.settings?.task?.automaticallyCreateProject ? project?.title : ""}
              </span>
            )}
            {this.displayDragHandle()}
            {this.displayTaskIdAndTitle({
              task,
              width,
            })}

            {this.displayResizeHandles({ isTouchDevice })}
            {(this.state.dragHandle === "down" || this.state.dragHandle === "up") && this.displayDragToCopyBox()}
          </>
        )}
        {this.displaySetColorModal()}
      </div>
    );
  };

  displayDragHandle = () => {
    const { block, user, onDragStart } = this.props;

    return (
      <div
        className="drag-handle"
        draggable
        data-cy="drag-handle"
        data-task-id={block.taskId}
        onDragStart={(e) => {
          const dragImageElement = e.target.parentNode;
          if (!dragImageElement) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }

          e.dataTransfer.setDragImage(dragImageElement, 0, 0);
          e.dataTransfer.setData("task-id", block.taskId);
          e.dataTransfer.setData("task-revision-id", block.taskRevisionId);
          e.dataTransfer.setData("task-status", block.taskStatus);
          e.dataTransfer.setData("is-pseudo-task", block.isPseudoTask);
          e.dataTransfer.setData("user-chosen-color", block.userChosenColor);
          e.dataTransfer.setData("block-id", block.id);
          e.dataTransfer.setData("user", user.id);
          e.dataTransfer.setData("draggable-type", "block");
          e.dataTransfer.setData("block-duration-hours", block.durationHours);

          setTimeout(() => {
            onDragStart();
          }, 100);
        }}
      />
    );
  };

  displayTaskIdAndTitle = ({ task, width }) => {
    const { organisationDetails, block, showTaskIDs, showTaskTitles, zoomLevel, approvalStatus } = this.props;
    const { isRenaming } = this.state;

    let taskIdStyle = {};
    if (width < 40) {
      taskIdStyle.opacity = 0;
    } else if (width < 60) {
      taskIdStyle.fontSize = 7;
    } else if (width < 70) {
      taskIdStyle.fontSize = 9;
    }

    let taskIdToDisplay = block.taskId;

    if (
      organisationDetails?.settings?.general?.hideOrganisationIdInTags &&
      taskIdToDisplay.startsWith(`${organisationDetails?.id}-`)
    ) {
      taskIdToDisplay = taskIdToDisplay.replace(`${organisationDetails?.id}-`, "");
    }

    let taskTitleText = task?.title || `${getSimpleLabel("Task")} not found`;

    let hasTaskRevision =
      organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks &&
      block.taskRevisionId &&
      task?.revisions;

    if (hasTaskRevision) {
      let revision = task.revisions.items.find((x) => x.id === block.taskRevisionId);
      if (revision) {
        taskTitleText = `${revision.name} - ${taskTitleText}`;
      }
    }

    let hasTaskStatus = organisationDetails.settings?.timeline?.planTaskStatusesInsteadOfTasks && block.taskStatus;

    if (hasTaskStatus) {
      taskTitleText = `${getReadableStatus(block.taskStatus)} - ${taskTitleText}`;
    }

    return (
      <span className="task-id-and-title">
        {(showTaskIDs || block.isPseudoTask) && isRenaming ? (
          <Input
            className={cx("task-id", { small: zoomLevel <= 2 })}
            defaultValue={block.taskId}
            onChange={this.onRename}
            fullWidth
            ref={this.inputRef}
          />
        ) : (
          (showTaskIDs || (block.isPseudoTask && showTaskTitles)) &&
          (zoomLevel > 2 || !task || !showTaskTitles) && (
            <>
              <span className={cx("task-id", { small: zoomLevel <= 2 })} style={taskIdStyle}>
                {taskIdToDisplay}
              </span>
              {approvalStatus && (
                <span
                  className={cx("approval-status", {
                    small: zoomLevel <= 2,
                  })}
                >
                  ({approvalStatus})
                </span>
              )}
            </>
          )
        )}

        {!block.isPseudoTask && showTaskTitles && (
          <span className={cx("task-title", { small: zoomLevel <= 2 })}>{taskTitleText}</span>
        )}
      </span>
    );
  };

  displayResizeHandles = ({ isTouchDevice }) => {
    const { block, approvalStatus } = this.props;
    if (!block.taskId || approvalStatus) {
      return null;
    }

    let blockDragStartProps = {};
    let blockDragEndProps = {};
    let blockDragToCopyProps = {};

    if (isTouchDevice) {
      blockDragStartProps.onTouchStart = (e) => this.startResize(e, "start");

      blockDragEndProps.onTouchStart = (e) => this.startResize(e, "end");
      blockDragToCopyProps.onTouchStart = (e) => this.startResize(e, "down");
    } else {
      blockDragStartProps.onMouseDown = (e) => this.startResize(e, "start");
      blockDragEndProps.onMouseDown = (e) => this.startResize(e, "end");
      blockDragToCopyProps.onMouseDown = (e) => this.startResize(e, "down");
    }

    return (
      <>
        <div
          className="block-drag-start"
          {...blockDragStartProps}
          onClick={(e) => {
            e.stopPropagation();
          }}
          data-cy="resize-handle-left"
        >
          <DragHandleIcon />
        </div>
        <div
          className="block-drag-end"
          {...blockDragEndProps}
          onClick={(e) => {
            e.stopPropagation();
          }}
          data-cy="resize-handle-right"
        >
          <DragHandleIcon />
        </div>
        {block.isPseudoTask && block.taskId && (
          <div className="drag-to-copy-marker-down" {...blockDragToCopyProps}>
            <DragHandleIcon />
          </div>
        )}
        {/* {block.isPseudoTask && block.taskId && (
          <div
            className="drag-to-copy-marker-up"
            onMouseDown={(e) => this.startResize(e, "up")}
            onTouchStart={(e) => this.startResize(e, "up")}
          >
            <DragHandleIcon />
          </div>
        )} */}
      </>
    );
  };

  getTask = () => {
    const { block, tasksById, tasksWithRevisionsById, isRestricted, isOutOfWorkingHours } = this.props;

    let task;
    let tasksToUse = tasksWithRevisionsById || tasksById;
    if (!block.isPseudoTask && block.taskId && !isRestricted && !isOutOfWorkingHours && tasksToUse) {
      task = tasksToUse[block.taskId];
    }

    return task;
  };

  render() {
    const {
      block,
      filterClientId,
      filterProjectId,
      filterTaskId,
      showPseudoTasks,
      projectsById,
      isDraggingBlock,
      dragStartCoordinates,
      isInteractive = true,
      isRestricted = false,
      isOutOfWorkingHours = false,
      isRectangleInViewport,
      windowWidth,
    } = this.props;

    let isVisible = true;

    let task = this.getTask();

    if (task) {
      if (filterClientId && task.clientId !== filterClientId) {
        isVisible = false;
      } else if (filterProjectId && task.projectId !== filterProjectId) {
        isVisible = false;
      } else if (filterTaskId && task.id !== filterTaskId) {
        isVisible = false;
      }
    } else {
      if (block.isPseudoTask && !showPseudoTasks && !isOutOfWorkingHours) {
        isVisible = false;
      }
    }

    if (!isVisible) {
      return null;
    }

    const { left, top, width, height } = this.getBlockCoordinates();

    // if (isInteractive && !block?.isPseudoTask) {
    if (!isRectangleInViewport({ left, top, width, height })) {
      return null;
    }
    // }

    let project = task && projectsById?.[task.projectId];

    let isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints;

    let wrappedContent = null;

    let timelineBlockElement = this.displayTimelineBlock({
      task,
      project,
      left,
      width,
      isTouchDevice,
      isInteractive,
      isRestricted,
    });

    if (
      isOutOfWorkingHours ||
      !isInteractive ||
      isRestricted ||
      isDraggingBlock ||
      dragStartCoordinates ||
      !block.taskId
    ) {
      wrappedContent = timelineBlockElement;
    } else {
      wrappedContent = (
        <Tooltip
          title={this.displayTooltipTitle({ task, project })}
          mouseEnterDelay={0}
          overlayClassName="timeline-block-tooltip"
          placement={windowWidth < 1000 ? "top" : "bottom"}
          trigger={[]}
          open={this.props.selectedBlockForTooltip?.id === block.id}
        >
          {timelineBlockElement}
        </Tooltip>
      );
    }

    return wrappedContent;
  }
}

// export default React.memo(TimelineBlock, (prevProps, nextProps) => {
//   if (prevProps.isOutOfWorkingHours) {
//     return true;
//   }
//   return false;

//   if (!prevProps.isInteractive || prevProps.block?.isPseudoTask) {
//     return true;
//   }

//   return false;

//   // if (prevProps.block !== nextProps.block || prevProps.tasks !== nextProps.tasks) {
//   //   return false;
//   // }

//   // return true;
// });
