import { useState, useEffect } from "react";
import moment from "moment";
import { Divider } from "antd";
import { withRouter } from "react-router-dom";
import cx from "classnames";

import { getLabel } from "common/helpers";
import { getFilteredTasks } from "common/filterHelpers";
import withSubscriptions from "common/withSubscriptions";
import { IS_NEW_THRESHOLD } from "common/constants";

import Card from "Card/Card";
import TaskListItem from "TaskListItem/TaskListItem";
import TaskFilters from "TaskFilters/TaskFilters";

import "./ProjectItem.scss";

export function ProjectItem({ project, projects, tasks, filter, match, clients, organisationDetails, windowWidth }) {
  const [isNew, setIsNew] = useState(false);
  const [taskFilters, setTaskFilters] = useState({
    sprintId: "all",
    selectedUsers: [],
    includeFinished: true,
  });

  useEffect(() => {
    const createdAtTimestamp = new Date(project.createdAt).getTime();
    const difference = Date.now() - createdAtTimestamp;
    if (difference < IS_NEW_THRESHOLD) {
      setIsNew(true);
    }
  }, [project.createdAt]);

  const tasksInProject = getTasksInProject();

  function getTasksInProject() {
    let filteredTasks = tasks
      .filter((x) => x.projectId === project.id && !x.isExternalReference && !x.isHidden)
      .sort((a, b) => (a.id < b.id ? -1 : 1));

    if (filter && !filter?.includeArchivedTasks) {
      filteredTasks = filteredTasks.filter((x) => !x.isArchived);
    }

    if (filter && !filter?.includeFinishedTasks) {
      filteredTasks = filteredTasks.filter((x) => !x.isFinished);
    }

    if (project.dueDateStart && project.dueDateEnd) {
      filteredTasks = filteredTasks.filter(
        (x) => x.dueDate && moment(x.dueDate).isBetween(project.dueDateStart, project.dueDateEnd)
      );
    }

    let filteredProjectTasks = match.params.projectId
      ? getFilteredTasks({
          projects,
          clients,
          tasks: filteredTasks,
          filter: taskFilters,
          sortBy: "id",
        })
      : filteredTasks;

    return filteredProjectTasks.sort((a, b) => {
      let aParts = a.id.split("-");
      let bParts = b.id.split("-");

      let aTaskNumber = parseInt(aParts[2]);
      let bTaskNumber = parseInt(bParts[2]);

      return aTaskNumber > bTaskNumber ? 1 : -1;
    });
  }

  let displayTasksInProject = "";

  if (tasksInProject.length === 0) {
    displayTasksInProject = `0 ${getLabel({
      organisationDetails,
      id: "tasks",
      defaultValue: "tasks",
    })} in this ${getLabel({
      organisationDetails,
      id: "project",
      defaultValue: "project",
    })}`;
  } else if (tasksInProject.length === 1) {
    displayTasksInProject = `1 ${getLabel({
      organisationDetails,
      id: "task",
      defaultValue: "task",
    })} in this ${getLabel({
      organisationDetails,
      id: "project",
      defaultValue: "project",
    })}`;
  } else {
    displayTasksInProject = `${tasksInProject.length} ${getLabel({
      organisationDetails,
      id: "tasks",
      defaultValue: "tasks",
    })} in this ${getLabel({
      organisationDetails,
      id: "project",
      defaultValue: "project",
    })}`;
  }

  function displayTasks() {
    return (
      <div className="project-task-list">
        <Divider plain orientation="left" className="project-task-list-title">
          {displayTasksInProject}
        </Divider>
        {match.params.projectId ? (
          <div className="project-details-tasks-filter">
            <TaskFilters
              onChange={(filter) => setTaskFilters(filter)}
              includeCreateTask={false}
              defaultFilter={taskFilters}
              previousFilter={taskFilters}
              isDisplayedOnProjectDetailsPage={true}
              cookieName="task-filters-project-item"
              avatarListWidthToSubtract={160 + 100 + 300}
              shortOverlay={true}
            />
          </div>
        ) : null}
        {tasksInProject.map((task) => (
          <TaskListItem
            key={task.id}
            {...task}
            includeClientLogo={false}
            organisationDetails={organisationDetails}
            windowWidth={windowWidth}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <Card
        className={cx("project-item", {
          new: isNew,
        })}
        attributes={{ "data-title": project.title }}
      >
        {displayTasks()}
      </Card>
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ProjectItem,
    subscriptions: ["users", "tasks", "clients", "organisationDetails"],
  })
);
