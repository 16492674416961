import { message } from "antd";
import uniqid from "uniqid";
import { notification } from "antd";
import * as Sentry from "@sentry/react";

import { getLatestRevision, getLatestFileVersion, FILE_TYPES_WITHOUT_ANNOTATION } from "common/shared";
import { callGraphQLSimple, callRest } from "common/apiHelpers";
import { REVIEW_STATUS_READABLE, REVIEW_SECONDARY_STATUS_READABLE } from "common/constants";
import {
  sendReviewResultNotification,
  sendReviewRequestNotification,
  sendExternalReviewResultNotification,
  sendNotificationToReviewer,
} from "common/notificationHelpers";

import { getSimpleLabel } from "common/labels";

export function findCommentRecursive({ currentNode, activityId }) {
  if (currentNode.id === activityId) {
    return currentNode;
  }
  if (currentNode.reviewThread) {
    for (let i = 0; i < currentNode.reviewThread.length; i++) {
      let currentElement = currentNode.reviewThread[i];
      const result = findCommentRecursive({
        currentNode: currentElement,
        activityId,
      });
      if (result) {
        return result;
      }
    }
  }
}

export function markCommentAsResolved({ currentNode, activityId, apiUser }) {
  // console.log("markCommentAsResolved() currentNode = ", currentNode);
  if (currentNode.id === activityId) {
    currentNode.resolved = true;
    currentNode.resolvedBy = apiUser.id;
    currentNode.resolvedAt = new Date().toISOString();

    if (currentNode.type.startsWith("ANNOTATION")) {
      let parsedContent = JSON.parse(currentNode.content);
      parsedContent.resolved = true;
      currentNode.content = JSON.stringify(parsedContent);
    }
    return;
  }
  if (currentNode.reviewThread) {
    for (let i = 0; i < currentNode.reviewThread.length; i++) {
      let currentElement = currentNode.reviewThread[i];
      const result = markCommentAsResolved({
        currentNode: currentElement,
        activityId,
        apiUser,
      });
      if (result) {
        return result;
      }
    }
  }
}

export async function notifyReviewer(notificationType) {
  let { apiUser, users, task, review } = this.props;

  const taskRevision = getLatestRevision(task);
  const reviewer = taskRevision.checkedBy;
  const reviewId = taskRevision.reviewId;

  callGraphQLSimple({
    message: "Failed to record audit item for notification",
    mutation: "createAuditItem",
    variables: {
      input: {
        taskId: task.id,
        projectId: task.projectId,
        fileId: "nothing",
        clientId: task.clientId,
        page: "REVIEW",
        type: "NOTIFY_REVIEWER",
        content: notificationType,
        userId: window.apiUser.id,
        organisation: window.apiUser.organisation,
      },
    },
  });

  const upToDateReview = (
    await callGraphQLSimple({
      message: "Failed to retrieve review details",
      queryName: "getReview",
      variables: {
        id: reviewId,
      },
    })
  ).data.getReview;

  await callGraphQLSimple({
    message: "Failed to add activity item",
    queryName: "updateReview",
    variables: {
      input: {
        id: reviewId,
        reviewThread: [
          ...upToDateReview.reviewThread,
          {
            id: uniqid(),
            type: "COMMENT",
            createdAt: new Date().toISOString(),
            content: REVIEW_SECONDARY_STATUS_READABLE[notificationType],
            author: apiUser.id,
          },
        ],
      },
    },
  });

  if (this.props.request) {
    await callGraphQLSimple({
      message: `Failed to notify reviewer`,
      queryName: "updateRequest",
      variables: {
        input: {
          id: this.props.request.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      },
    });
  } else {
    await callGraphQLSimple({
      message: `Failed to notify reviewer`,
      queryName: "updateTaskRevision",
      variables: {
        input: {
          id: taskRevision.id,
          randomNumber: Math.floor(Math.random() * 100000),
          reviewSecondaryStatus: notificationType,
        },
      },
    });

    await callGraphQLSimple({
      message: `Failed to notify reviewer`,
      queryName: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 100000),
          reviewSecondaryStatus: notificationType,
        },
      },
    });
  }

  sendNotificationToReviewer({
    users,
    apiUser,
    reviewer,
    taskId: task.id,
    taskTitle: task.title,
    clientName: task.client?.name,
    projectTitle: task.project?.title,
    taskRevisionId: taskRevision.id,
    taskRevisionName: taskRevision.name,
    taskRevisionDescription: taskRevision.description,
    reviewSecondaryStatusReadable: REVIEW_SECONDARY_STATUS_READABLE[notificationType],
  });
}

export async function submitReview(reviewStatus, props) {
  if (!props) {
    props = this.props;
  }

  let { apiUser, review, task, users, taskRevision } = props;
  try {
    const now = new Date().toISOString();

    callGraphQLSimple({
      message: "Failed to record audit item for review submission",
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: task.id,
          projectId: task.projectId,
          fileId: "nothing",
          clientId: task.clientId,
          page: "REVIEW",
          type: "REVIEW_SUBMIT",
          content: reviewStatus,
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    // this is because this function gets called both on the task details page, and the review page
    if (!task && review && review.task) {
      task = review.task;
    }

    const reviewId = taskRevision.reviewId;

    const upToDateReview = (
      await callGraphQLSimple({
        message: "Failed to retrieve review details",
        queryName: "getReview",
        variables: {
          id: reviewId,
        },
      })
    ).data.getReview;

    if (!props.isExternalReview) {
      await callGraphQLSimple({
        message: `Failed to update ${getSimpleLabel("task revision")}`,
        queryName: "updateTaskRevision",
        variables: {
          input: {
            id: taskRevision.id,
            reviewSecondaryStatus: null,
            reviewStatus,
            isReadOnly: reviewStatus === "SUCCESS",
            reviewAcceptDate: reviewStatus === "SUCCESS" ? now : null,
          },
        },
      });
      await callGraphQLSimple({
        message: "Failed to update task review status",
        queryName: "updateTask",
        variables: {
          input: {
            id: task.id,
            reviewSecondaryStatus: null,
            reviewStatus,
            isReadOnly: reviewStatus === "SUCCESS",
            isUnderReview: reviewStatus !== "SUCCESS" && reviewStatus !== "CLOSED",
          },
        },
      });

      if (reviewStatus === "SUCCESS") {
        for (let i = 0; i < taskRevision.files.items.length; i++) {
          const fileInTask = taskRevision.files.items[i];
          const file = (
            await callGraphQLSimple({
              message: "Failed to retrieve sheet revision",
              queryName: "getFile",
              variables: {
                id: fileInTask.id,
              },
            })
          ).data.getFile;
          for (let j = 0; j < file.sheets.items.length; j++) {
            const sheet = file.sheets.items[j];
            const latestSheetRevision = sheet.revisions.items[sheet.revisions.items.length - 1];

            // we only want to update who checked the latest sheet revision
            // if it hasn't already been checked by someone else
            if (latestSheetRevision.checkedBy && latestSheetRevision.reviewAcceptDate) {
              continue;
            }

            callGraphQLSimple({
              message: "Failed to update sheet revision",
              queryName: "updateSheetRevision",
              variables: {
                input: {
                  id: latestSheetRevision.id,
                  checkedBy: taskRevision.checkedBy,
                  reviewAcceptDate: now,
                },
              },
            });
          }
        }
      } else if (taskRevision.reviewStatus === "SUCCESS" && reviewStatus === "IN_PROGRESS") {
        // if we're cancelling the approval of the review, we want to remove the checker's name and review approved date
        // from the latest sheet revisions if they were approved as part of the review we're un-approving
        for (let i = 0; i < taskRevision.files.items.length; i++) {
          const fileInTask = taskRevision.files.items[i];
          const file = (
            await callGraphQLSimple({
              message: "Failed to update sheet revision",
              queryName: "getFile",
              variables: {
                id: fileInTask.id,
              },
            })
          ).data.getFile;
          for (let j = 0; j < file.sheets.items.length; j++) {
            const sheet = file.sheets.items[j];
            const latestSheetRevision = sheet.revisions.items[sheet.revisions.items.length - 1];

            if (latestSheetRevision.reviewAcceptDate !== taskRevision.reviewAcceptDate) {
              continue;
            }

            callGraphQLSimple({
              message: "Failed to update sheet revision",
              queryName: "updateSheetRevision",
              variables: {
                input: {
                  id: latestSheetRevision.id,
                  checkedBy: null,
                  reviewAcceptDate: null,
                },
              },
            });
          }
        }
      }
    } else {
      // the task revision ID is actually the ID of the activity item
      let activityItem = (
        await callGraphQLSimple({
          message: "Failed to retrieve review activity item details",
          query: "getActivityItem",
          variables: {
            id: taskRevision.id,
          },
        })
      ).data.getActivityItem;

      let updatedContent;
      try {
        updatedContent = JSON.parse(activityItem.content);
      } catch (e) {
        message.error("Failed to update review status");
        return;
      }

      updatedContent = {
        ...updatedContent,
        reviewSecondaryStatus: null,
        reviewStatus,
        isReadOnly: reviewStatus === "SUCCESS",
        isUnderReview: reviewStatus !== "SUCCESS" && reviewStatus !== "CLOSED",
      };

      await callGraphQLSimple({
        message: "Failed to update review status",
        mutation: "updateActivityItem",
        variables: {
          input: {
            id: taskRevision.id,
            content: JSON.stringify(updatedContent),
          },
        },
      });

      await callGraphQLSimple({
        message: "Failed to update review status",
        mutation: "createActivityItem",
        variables: {
          input: {
            parentId: task.id,
            author: apiUser.id,
            content: JSON.stringify({
              type: "REVIEW_STATUS_CHANGE",
              reviewStatus,
              reviewId: review?.id,
              taskRevisionId: taskRevision.id,
              taskFilesSentActivityItemId: taskRevision.id,
            }),
            organisation: apiUser.organisation,
          },
        },
      });
    }

    await callGraphQLSimple({
      message: "Failed to submit review",
      queryName: "updateReview",
      variables: {
        input: {
          id: reviewId,
          reviewThread: [
            ...upToDateReview.reviewThread,
            {
              id: uniqid(),
              type: "STATUS_CHANGE",
              createdAt: now,
              content: reviewStatus,
              author: apiUser.id,
            },
          ],
        },
      },
    });

    if (!props.isExternalReview) {
      if (reviewStatus === "IN_PROGRESS") {
        sendReviewRequestNotification({
          users,
          reviewer: taskRevision.checkedBy,
          apiUser,
          taskId: task.id,
          taskTitle: task.title,
          clientName: task.client?.name,
          projectTitle: task.project?.title,
          taskRevisionName: taskRevision.name,
          taskRevisionDescription: taskRevision.description,
          taskRevisionId: taskRevision.id,
        });
      } else {
        sendReviewResultNotification({
          users,
          apiUser,
          taskId: task.id,
          taskTitle: task.title,
          clientName: task.client?.name,
          projectTitle: task.project?.title,
          taskRevisionId: taskRevision.id,
          taskRevisionName: taskRevision.name,
          taskRevisionDescription: taskRevision.description,
          reviewStatusReadable: REVIEW_STATUS_READABLE[reviewStatus].label,
          taskAssignedTo: task.assignedTo,
        });
      }
      await annotateAllFilesInTaskRevision({
        task,
        taskRevision,
        apiUser,
      });
    } else {
      let updateRequestInput = {
        id: task.id,
        itemSubscription: Math.floor(Math.random() * 100000),
        reviewStatus,
        status: "INFORMATION_SUBMITTED",
      };

      await callGraphQLSimple({
        message: "Failed to update review status",
        mutation: "updateRequest",
        variables: {
          input: updateRequestInput,
        },
      });
      sendExternalReviewResultNotification({
        users,
        apiUser,
        requestId: task.id,
        requestTitle: task.title,
        requestAssignedTo: task.assignedTo,
        clientName: task.client?.name,
        projectTitle: task.project?.title,
        requestAssignedTo: task.assignedTo,
        reviewStatusReadable: REVIEW_STATUS_READABLE[reviewStatus].label,
        fakeTaskRevisionId: taskRevision.id,
      });
    }
  } catch (e) {
    notification.error({
      message: (
        <>
          <b>Failed to submit review, either just in part or fully. </b>
          <br />
          Please try again. If the error persists, please contact our support team.
        </>
      ),
      description: e.message,
    });
    // send to Sentry with a custom message
    let sentryMessage = `Review submission failed. Task ID: ${task?.id}, review status: ${reviewStatus}`;
    Sentry.captureException(e, {
      extra: {
        message: sentryMessage,
        isExternalReview: props.isExternalReview,
        organisation: window.apiUser?.organisation,
      },
    });
  }
}

export async function annotateAllFilesInTaskRevision({ task, taskRevision, apiUser }) {
  let promises = [];
  for (let i = 0; i < taskRevision.files.items.length; i++) {
    const fileInTaskRevision = taskRevision.files.items[i];

    if (fileInTaskRevision.isArchived || FILE_TYPES_WITHOUT_ANNOTATION.includes(fileInTaskRevision.type)) {
      continue;
    }
    promises.push(
      new Promise(async (resolve) => {
        const fileId = fileInTaskRevision.id;
        try {
          // we retrieve this data rather than using it from the task revision because we don't have enough depth in the
          // task revision schema to reach the exports for each sheet, which is required for the annotation process
          const file = (
            await callGraphQLSimple({
              message: "Could not retrieve file data",
              queryName: "getFile",
              variables: {
                id: fileId,
              },
            })
          ).data.getFile;

          const latestFileVersion = getLatestFileVersion(file);

          await annotate({
            task,
            fileId,
            taskRevisionId: taskRevision.id,
            fileVersion: latestFileVersion,
            apiUser,
            customId: latestFileVersion.customId,
            sheetNames: file.sheets.items.filter((sheet) => sheet.includeInPublish).map((sheet) => sheet.name),
            fileType: file.type,
          });
        } catch (e) {
          console.error(e);
          // if annotate call fails, there's nothing we can do
        }

        resolve();
      })
    );
  }

  try {
    await Promise.all(promises);
  } catch (e) {
    console.error(e);
    // if any of the promises fail, there's nothing we can do
  }
}

export async function annotate({ task, fileId, taskRevisionId, fileVersion, apiUser, customId, sheetNames, fileType }) {
  await callRest({
    route: "/annotate",
    method: "POST",
    message:
      "Failed to annotate file PDF. This is probably because the file version has just been created and there is no PDF for it yet.",
    body: {
      eventId: task.id,
      sourceKey: fileVersion.exports[0].rawKey,
      publishedKey: fileVersion.exports[0].key,
      organisation: apiUser.organisation,
      clientLogo: task.client.key,
      taskTitle: task.title,
      projectTitle: task.project.title,
      taskRevisionId,
      fileType,
      fileId,
      fileVersionId: fileVersion.id,
      customId,
      taskId: task.id,
      sheetNames,
    },
  });
}
