import React from "react";
import moment from "moment";
import cx from "classnames";
import Card from "Card/Card";
import _ from "lodash";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Button, Alert, Typography, notification, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import cookie from "js-cookie";

import { callGraphQLSimple } from "common/apiHelpers";
import { downloadBlob } from "common/helpers";
import getS3File from "common/getS3File";
import { callRest } from "common/apiHelpers";
import {
  COOKIE_NAME_QUOTE_PREVIEW,
  COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE,
  MIN_DOCUMENT_FORM_WIDTH,
} from "common/constants";
import { KEY_TYPES, encodeKey, getTemplateFromOrganisation } from "common/shared";
import { updatePurchaseOrder, deletePurchaseOrder } from "graphql/mutations";
import withSubscriptions from "common/withSubscriptions";
import {
  displayInsertAttachmentModal,
  displayInsertAttachmentPickerModal,
  displayReportUserListModal,
  displayFields,
  displayModalContainingFields,
  computeHiddenFormFields,
} from "ReportPage/Report/reportHelpers";
import { processLambdaPdfInserts, processReportPageMapping } from "common/documentRenderHelpers";
import { recordActivityItem } from "common/purchaseOrderHelpers";
import { getSimpleLabel } from "common/labels";

import ReviewTarget from "ReviewTarget/ReviewTarget";
import Input from "Input/Input";
import DocumentOutput from "DocumentOutput/DocumentOutput";
import InfoItem from "InfoItem/InfoItem";
import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";
import RequestPurchaseOrderReviewModal from "Modals/RequestPurchaseOrderReviewModal/RequestPurchaseOrderReviewModal";
import SendPurchaseOrderModal from "Modals/SendDocumentModal/SendPurchaseOrderModal";
import PurchaseOrderActivity from "./PurchaseOrderActivity/PurchaseOrderActivity";
import PurchaseOrderMetadata from "./PurchaseOrderMetadata/PurchaseOrderMetadata";
import PurchaseOrderActions from "./PurchaseOrderActions/PurchaseOrderActions";
import PurchaseOrderReviewSummary from "./PurchaseOrderReviewSummary/PurchaseOrderReviewSummary";
import PurchaseOrderLineItemsCard from "./PurchaseOrderLineItemsCard/PurchaseOrderLineItemsCard";
import DocumentReview from "DocumentReview/DocumentReview";

import "./PurchaseOrderDetailsPage.scss";

export class PurchaseOrderDetailsPage extends React.Component {
  state = {
    isDescriptionOpen: false,
    description: "",
    dataUri: null,
    form: null,
    formPreview: null,
    numberForPreviewRefresh: 0,
    isSaving: false,
    isCalculating: false,
    savedAt: moment(),
    fieldUnderEditName: null,
    fieldUnderEditSelectionStart: null,
    subFieldUnderEditIndex: null,
    fieldUnderEditValue: "",
    isAttachmentPickerOpen: false,
    isReportUserListModalOpen: false,
    isCreatingTaskFromLineItemId: null,
    isCreateTaskModalVisible: false,
    isSendingPurchaseOrder: false,
    isDownloadingPurchaseOrder: false,
    isInsertAttachmentsModalOpen: false,
    sendStatus: null,
    selectedLineItemId: null,
    isPreviewEnabled: false,
    isAddPurchaseOrderLineItemToTaskModalVisible: false,
    isRequestReviewModalVisible: false,
    hasNewComment: false,
    newCommentFieldName: null,
    areCommentsVisible: true,
    isUnderReview: false,
    isSendPurchaseOrderModalVisible: false,
    approvedPdfData: null,
    hasApprovedPdf: null,
    isApprovedPdfVisible: false,
    isCorrupted: false,
    corruptedReason: null,
    dateTimeToCheckFormAgainst: moment().toISOString(),
    hiddenFormFields: {},
  };

  constructor(props) {
    super(props);
    this.debouncedInnerSaveUserFields = _.debounce(this.saveUserFields, 1000);

    this.debouncedSaveUserFields = () => {
      computeHiddenFormFields.call(this);
      this.debouncedInnerSaveUserFields();
    };
    this.debouncedChangeAttribute = _.debounce(this.changeAttribute, 500);
  }

  async componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.showPreloader();
    this.intervalCheckFormWidth = setInterval(this.checkFormWidth, 3000);
    setTimeout(this.checkFormWidth, 1000);

    this.setState({ isUnderReview: this.props.purchaseOrder.isUnderReview });

    const isPreviewEnabled = cookie.get(COOKIE_NAME_QUOTE_PREVIEW);
    if (isPreviewEnabled === "true") {
      this.setState({ isPreviewEnabled: true });
    } else if (isPreviewEnabled === "false") {
      this.setState({ isPreviewEnabled: false });
    }

    const areCommentsVisible = cookie.get(COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE);
    if (areCommentsVisible === "true") {
      this.setState({ areCommentsVisible: true });
    } else if (areCommentsVisible === "false") {
      this.setState({ areCommentsVisible: false });
    }

    const { purchaseOrder } = this.props;

    const projectFolder = await encodeKey({
      type: KEY_TYPES.PROJECT_FOLDER,
      data: {
        organisation: purchaseOrder.organisation,
        projectId: purchaseOrder.projectId,
      },
    });

    this.setState({
      projectFolder,
    });

    let project = this.props.projects.find((x) => x.id === purchaseOrder.projectId);
    let client = this.props.clients.find((x) => x.id === purchaseOrder.clientId);
    let supplier = this.props.suppliers.find((x) => x.id === purchaseOrder.supplierId);

    if (!project) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("project")} found.`,
      });
      this.props.hidePreloader();
      return;
    }

    if (!client) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("client")} found.`,
      });
      this.props.hidePreloader();
      return;
    }

    if (!supplier) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("supplier")} found.`,
      });
      this.props.hidePreloader();
      return;
    }

    this.loadFile();

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: purchaseOrder.projectId,
          fileId: purchaseOrder.id,
          clientId: purchaseOrder.clientId,
          page: "INVOICE_DETAILS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
    this.fetchApprovedVersion();
  }

  componentDidUpdate(prevProps) {
    const { purchaseOrder } = this.props;
    if (JSON.stringify(prevProps.purchaseOrder) !== JSON.stringify(purchaseOrder)) {
      this.refreshPurchaseOrderPreview();
    }
    if (prevProps.purchaseOrder.isUnderReview !== purchaseOrder.isUnderReview) {
      this.setState({ isUnderReview: purchaseOrder.isUnderReview });
    }
    this.isReviewed = this.isDisabled();

    if (this.state.hasApprovedPdf && !purchaseOrder.reviewApprovedAt) {
      this.setState({
        approvedPdfData: undefined,
        hasApprovedPdf: undefined,
        isApprovedPdfVisible: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
    if (this.intervalCheckFormWidth) {
      clearInterval(this.intervalCheckFormWidth);
    }
  }

  checkFormWidth = () => {
    const formElement = document.querySelector(".reviewable-content");
    if (!formElement) {
      return;
    }

    // if the width of the form is below the threshold

    if (formElement.offsetWidth < MIN_DOCUMENT_FORM_WIDTH && this.state.isPreviewEnabled) {
      this.setState({ isPreviewEnabled: false });
    }
  };

  fetchApprovedVersion = async () => {
    const { purchaseOrder } = this.props;

    if (purchaseOrder.reviewApprovedAt && purchaseOrder.exports && purchaseOrder.exports.length > 0) {
      const publicPdfUrl = await getS3File(
        purchaseOrder.exports[0].key.replace("public/", ""),
        purchaseOrder.exports[0].latestS3VersionId
      );
      const pdfDataBlob = (await axios.get(publicPdfUrl, { responseType: "blob" })).data;
      const pdfData = await new Response(pdfDataBlob).arrayBuffer();

      this.setState({ approvedPdfData: pdfData, hasApprovedPdf: true });
    } else {
      this.setState({ hasApprovedPdf: false });
    }
  };

  getPotentialReviewers = () => {
    const { users, purchaseOrder, apiUser } = this.props;

    let potentialReviewers = users.filter(
      (user) => user.purchaseOrderReviewLimit && user.purchaseOrderReviewLimit > purchaseOrder.total
    );
    if (!potentialReviewers.some((user) => user.id === apiUser.id)) {
      if (
        purchaseOrder.author === apiUser.id &&
        apiUser.purchaseOrderCreationLimit &&
        apiUser.purchaseOrderCreationLimit >= purchaseOrder.total
      ) {
        potentialReviewers.unshift(apiUser);
      }
    }
    return potentialReviewers;
  };

  loadFile = async () => {
    const { purchaseOrder } = this.props;

    if (!purchaseOrder.fileKey) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("purchase order")} file found.`,
      });
      this.props.hidePreloader();
      return;
    }

    const filePublicURL = await getS3File(purchaseOrder.fileKey.replace("public/", ""));
    const purchaseOrderFileData = (await axios.get(filePublicURL)).data;

    this.setState(
      {
        form: purchaseOrderFileData,
        formPreview: purchaseOrderFileData,
      },
      async () => {
        await computeHiddenFormFields.call(this);
        this.props.hidePreloader();
      }
    );
  };

  saveUserFields = async () => {
    const { form } = this.state;
    const { purchaseOrder } = this.props;
    this.setState({
      isSaving: true,
      isSaveError: false,
      userHasChangedSomething: true,
    });
    try {
      await Storage.put(purchaseOrder.fileKey.replace("public/", ""), JSON.stringify(form));
      callGraphQLSimple({
        message: "Failed to update purchase order details",
        mutation: "updatePurchaseOrder",
        variables: {
          input: {
            id: purchaseOrder.id,
            savedAt: new Date().toISOString(),
          },
        },
      });
      // this.loadEntirePdfVersions();
      this.setState({
        formPreview: form,
        savedAt: moment(),
        isSaving: false,
        dateTimeToCheckFormAgainst: moment().add(3, "seconds").toISOString(),
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to save purchase order:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      this.setState({ isSaving: false, isSaveError: true });
    }
  };

  isDisabled = () => {
    const { purchaseOrder } = this.props;

    if (
      purchaseOrder.status !== "DRAFT" ||
      purchaseOrder.isArchived ||
      purchaseOrder.secondReviewApprovedAt ||
      purchaseOrder.reviewApprovedAt
    ) {
      return true;
    }

    return false;
  };

  uploadPurchaseOrderPDF = async (dataUri) => {
    const { purchaseOrder } = this.props;
    const { hasApprovedPdf } = this.state;

    if (hasApprovedPdf || moment(purchaseOrder.reviewApprovedAt).diff(moment(), "seconds") > 10) {
      return;
    }

    if (
      !window.lambdaPdfInserts ||
      !window.lambdaPdfInserts[window.reportRenderCycle] ||
      Object.keys(window.lambdaPdfInserts[window.reportRenderCycle]).length === 0
    ) {
      setTimeout(() => this.uploadPurchaseOrderPDF(dataUri), 500);
      return;
    }

    const purchaseOrderPdfBlob = await (await fetch(dataUri)).blob();
    let newMetadata = {
      inserts: processLambdaPdfInserts(window.lambdaPdfInserts[window.reportRenderCycle]),
      assets: window.lambdaPdfAssets,
      pageMapping: processReportPageMapping(window.reportPageMapping[window.reportRenderCycle]),
      pageNumbersToSkipBorders: window.lambdaPdfPageNumbersToSkipBorders,
    };

    // if (this.state.userHasChangedSomething) {
    const purchaseOrderPdfFile = new File([purchaseOrderPdfBlob], "");
    await Storage.put(purchaseOrder.fileKey.replace("public/", "").replace(".json", "_raw.pdf"), purchaseOrderPdfFile, {
      contentType: "application/pdf",
    });

    const newMetadataString = JSON.stringify(newMetadata, null, 2);
    let oldMetadataString = JSON.stringify(JSON.parse(JSON.stringify(purchaseOrder.metadata)), null, 2);

    if (newMetadataString !== oldMetadataString) {
      callGraphQLSimple({
        message: "Failed to update purchase order details",
        mutation: "updatePurchaseOrder",
        variables: {
          input: {
            id: purchaseOrder.id,
            metadata: newMetadata,
          },
        },
      });
    }

    // }
  };

  onDataUri = async (dataUri) => {
    if (this.state.dataUri === dataUri) {
      return;
    }
    this.setState({ dataUri });
    this.uploadPurchaseOrderPDF(dataUri);
  };

  recordActivityItemForAttributeChange({ fieldName, value, content }) {
    const { users, apiUser } = this.props;
    let purchaseOrder = { ...this.props.purchaseOrder, [fieldName]: value };

    let type;
    if (fieldName === "status") {
      if (value === "DRAFT") {
        type = "STATUS_CHANGED";
      } else {
        type = value;
      }

      recordActivityItem({
        purchaseOrder,
        type,
        author: apiUser.id,
        users,
        content,
      });
    }
  }

  changeAttribute = async ({ fieldName, value, includeRecalculation, activityItemContent }) => {
    const { purchaseOrder } = this.props;
    this.recordActivityItemForAttributeChange({
      fieldName,
      value,
      content: activityItemContent,
    });

    const updatedPurchaseOrder = (
      await callGraphQLSimple({
        message: "Failed to update purchase order details",
        mutation: "updatePurchaseOrder",
        variables: {
          input: {
            id: purchaseOrder.id,
            [fieldName]: value,
          },
        },
      })
    ).data.updatePurchaseOrder;

    if (includeRecalculation) {
      this.recalculateAmounts(updatedPurchaseOrder);
    }
  };

  deletePurchaseOrder = async () => {
    const { purchaseOrder, history, apiUser } = this.props;

    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: `Confirm delete ${getSimpleLabel("purchase order")}`,
          maskClosable: true,
          content: <>Are you sure you want to delete this {getSimpleLabel("purchase order")}?</>,
          okText: "Delete",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQLSimple({
      message: `Failed to delete ${getSimpleLabel("purchase order")}`,
      queryName: "deletePurchaseOrder",
      variables: {
        input: {
          id: purchaseOrder.id,
        },
      },
    });

    await callGraphQLSimple({
      message: "Failed to record activity item",
      mutation: "createDocumentActivityItem",
      variables: {
        input: {
          documentId: purchaseOrder.id,
          total: purchaseOrder.total,
          type: "DELETED",
          organisation: purchaseOrder.organisation,
          author: apiUser.id,
        },
      },
    });

    history.push("/purchase-orders");
  };

  displayInputLabel = ({ fieldData, fieldName }) => {
    return (
      <Typography.Paragraph className="field-label">
        <Typography.Text className="field-name">{fieldData.label ? fieldData.label : fieldName}</Typography.Text>{" "}
      </Typography.Paragraph>
    );
  };

  displayPurchaseOrderFields = () => {
    const { purchaseOrder } = this.props;
    return (
      <Card title="Purchase order details" className="purchase-order-fields-card" withSpace>
        <InfoItem
          label="Title"
          value={
            <ReviewTarget name="purchaseOrderTitle" {...this} {...this.props} visible={purchaseOrder.isUnderReview}>
              <Input
                data-cy="purchase-order-details-title"
                defaultValue={purchaseOrder.title}
                disabled={this.isDisabled(purchaseOrder)}
                className="purchase-order-title"
                onChange={(value) => this.changeAttribute({ fieldName: "title", value })}
                fullWidth
                showBorder
                fireOnChangeWithoutBlurWithDebounce
                debounceDelay={2000}
                allowEnter={false}
              />
            </ReviewTarget>
          }
        />

        <div>{displayFields.call(this, { showHiddenByModal: false })}</div>
      </Card>
    );
  };

  downloadPurchaseOrder = async () => {
    const { purchaseOrder } = this.props;
    this.setState({ isDownloadingPurchaseOrder: true });
    try {
      if (!purchaseOrder.reviewApprovedAt || !purchaseOrder.exports || purchaseOrder.exports.length === 0) {
        await callRest({
          route: "/annotate",
          method: "POST",
          body: {
            purchaseOrderId: purchaseOrder.id,
            eventId: purchaseOrder.id,
            organisation: purchaseOrder.organisation,
            fileType: "INVOICE",
          },
          includeCredentials: false,
        });
      }
      const updatedPurchaseOrder = (
        await callGraphQLSimple({
          message: "Failed to fetch purchase order details",
          query: "getPurchaseOrder",
          variables: {
            id: purchaseOrder.id,
          },
        })
      ).data.getPurchaseOrder;
      await this.downloadPDF(updatedPurchaseOrder.exports[0].key, updatedPurchaseOrder.exports[0].latestS3VersionId);
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to download PDF:
            <br />
            {e.response?.data?.error || e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
    this.setState({ isDownloadingPurchaseOrder: false });
  };

  archivePurchaseOrder = async () => {
    const { purchaseOrder } = this.props;
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm archive purchas order",
          maskClosable: true,
          content: <>Are you sure you want to archive this purchase order?</>,
          okText: "Archive",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQLSimple({
      message: "Failed to archive purchaseOrder",
      queryName: "updatePurchaseOrder",
      variables: {
        input: {
          id: purchaseOrder.id,
          isArchived: true,
        },
      },
    });
  };

  restorePurchaseOrder = async () => {
    const { purchaseOrder } = this.props;
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm restore purchase order",
          maskClosable: true,
          content: <>Are you sure you want to restore this purchase order?</>,
          okText: "Restore",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQLSimple({
      message: "Failed to archive purchaseOrder",
      queryName: "updatePurchaseOrder",
      variables: {
        input: {
          id: purchaseOrder.id,
          isArchived: false,
        },
      },
    });
  };

  checkPurchaseOrderCanBeSent = async () => {
    const { purchaseOrder, suppliers } = this.props;

    const supplier = suppliers.find((x) => x.id === purchaseOrder.supplierId);

    if (purchaseOrder.reviewStatus !== "SUCCESS") {
      Modal.info({
        title: "Approval required",
        maskClosable: true,
        content: <>Before you can send this purchase order, it must first be reviewed.</>,
      });
      return;
    }
    if (!purchaseOrder.assignedTo) {
      Modal.info({
        title: "Assignee required",
        maskClosable: true,
        content: <>Before you can send this purchase order, you must first assign it to a user.</>,
      });
      return;
    }

    const supplierContact = supplier.contacts?.find((x) => x.id === purchaseOrder.supplierContact);
    const supplierAddress = supplier.addresses?.find((x) => x.id === purchaseOrder.supplierAddress);

    if (!supplierContact) {
      Modal.info({
        title: "Supplier contact required",
        maskClosable: true,
        content: <>Before you can send this purchase order, you must first assign it to a supplier contact.</>,
      });
      return;
    }

    if (!supplierAddress) {
      Modal.info({
        title: "Supplier address required",
        maskClosable: true,
        content: <>Before you can send this purchase order, you must first assign it to a supplier address.</>,
      });
      return;
    }

    if (!supplierContact) {
      Modal.error({
        title: "No supplier contact",
        maskClosable: true,
        content: <>The supplier contact {purchaseOrder.supplierContact} does not exist anymore.</>,
      });
      return;
    }

    if (!supplierContact.email) {
      Modal.error({
        title: "Supplier contact has no email",
        maskClosable: true,
        content: <>Purchase order cannot be sent to a supplier contact without an email address.</>,
      });
      return;
    }

    if (!supplierContact.firstName || !supplierContact.lastName) {
      try {
        await new Promise((resolve, reject) => {
          let message;
          if (!supplierContact.firstName && !supplierContact.lastName) {
            message = (
              <>
                The supplier contact <b>{purchaseOrder.supplierContact}</b> does not either a first or a last name. Do
                you want to continue?
              </>
            );
          } else if (!supplierContact.firstName) {
            message = (
              <>
                The supplier contact <b>{purchaseOrder.supplierContact}</b> does not have a first name. Do you want to
                continue?
              </>
            );
          } else if (!supplierContact.lastName) {
            message = (
              <>
                The supplier contact <b>{purchaseOrder.supplierContact}</b> does not have a last name. Do you want to
                continue?
              </>
            );
          }
          Modal.confirm({
            title: "Missing supplier contact details",
            maskClosable: true,
            content: message,
            onOk: () => resolve(),
            onCancel: () => reject(),
          });
        });
      } catch (e) {
        // user chose to cancel
        return;
      }
    }

    this.setState({ isSendPurchaseOrderModalVisible: true });
  };

  downloadPDF = async (fileKey, versionId, readableDate) => {
    try {
      const publicUrl = await getS3File(fileKey.replace("public/", ""), versionId);
      await this.downloadEntirePdfFromPublicUrl(publicUrl, fileKey, readableDate);
    } catch (e) {
      console.error("Error downloading PDF:", e);
      notification.error({
        message: "Could not download PDF",
      });
    }
  };

  downloadEntirePdfFromPublicUrl = async (publicUrl, fileKey, readableDate) => {
    const fileBlob = (
      await axios({
        url: publicUrl,
        method: "GET",
        responseType: "blob", // Important
      })
    ).data;

    await downloadBlob({
      blob: fileBlob,
      fileName: fileKey.split("/").slice(-1)[0],
    });
  };

  openCommentBox = (name) => {
    this.setState({
      hasNewComment: true,
      newCommentFieldName: name,
    });
  };

  recordCommentActivityItem = async ({ content, actionType }) => {
    const { purchaseOrder, apiUser, clients, users, suppliers } = this.props;
    let actionTypeReadable = `${actionType.toLowerCase()}d`;
    if (actionType === "EDIT") {
      actionTypeReadable = "edited";
    }
    await recordActivityItem({
      purchaseOrder,
      type: "REVIEW_ACTIVITY",
      author: apiUser.id,
      clients,
      users,
      suppliers,
      content: `${apiUser.firstName} ${apiUser.lastName} ${actionTypeReadable} a comment: \n"${content}"`,
    });
  };

  refreshPurchaseOrder = async () => {
    const { purchaseOrder } = this.props;
    callGraphQLSimple({
      message: "Failed to update purchase order details",
      mutation: "updatePurchaseOrder",
      variables: {
        input: {
          id: purchaseOrder.id,
          itemSubscription: Math.floor(Math.random() * 100000),
          // lastUpdateAuthorId: window.authorId,
        },
      },
    });
  };

  refreshPurchaseOrderPreview = () => {
    this.setState({
      numberForPreviewRefresh: this.state.numberForPreviewRefresh + 1,
    });
  };

  recalculateAmounts = async (purchaseOrder) => {
    purchaseOrder = JSON.parse(JSON.stringify(purchaseOrder));
    const { setProps, context, apiUser, users, clients, suppliers } = this.props;
    let updateLineItemPromises = [];

    for (let lineItem of purchaseOrder.lineItems.items) {
      const itemPreTaxAmount = lineItem.isHourly
        ? 0
        : lineItem.quantity * ((lineItem.unitPrice || 0) + (lineItem.checkPrice || 0));
      const taxRate = purchaseOrder.taxRate;
      const itemTaxAmount = itemPreTaxAmount * (taxRate / 100);

      if (lineItem.amount !== itemPreTaxAmount || lineItem.taxAmount !== itemTaxAmount) {
        updateLineItemPromises.push(
          callGraphQLSimple({
            message: "Failed to update line item",
            mutation: "updatePurchaseOrderLineItem",
            variables: {
              input: {
                id: lineItem.id,
                amount: itemPreTaxAmount,
                taxAmount: itemTaxAmount,
              },
            },
          })
        );
      }

      lineItem.amount = itemPreTaxAmount;
      lineItem.taxAmount = itemTaxAmount;
    }

    let subtotal = 0;
    let totalTax = 0;
    let total = 0;

    // // we change this manually to trigger a refresh of the preview
    // purchaseOrder.itemSubscription = Math.floor(Math.random() * 100000);

    purchaseOrder.lineItems.items.forEach((item) => {
      if (!item.isHourly) {
        subtotal += item.amount;
        totalTax += item.taxAmount;
      }
    });
    total = subtotal + totalTax;

    purchaseOrder.subtotal = subtotal;
    purchaseOrder.totalTax = totalTax;
    purchaseOrder.total = total;

    setProps({
      context: {
        ...context,
        purchaseOrder,
      },
      purchaseOrder,
    });

    await Promise.all(updateLineItemPromises);

    // if (
    //   purchaseOrder.subtotal !== subtotal ||
    //   purchaseOrder.totalTax !== totalTax ||
    //   purchaseOrder.total !== total
    // ) {
    const latestUpdatedPurchaseOrder = (
      await callGraphQLSimple({
        message: "Failed to update purchase order",
        mutation: "updatePurchaseOrder",
        variables: {
          input: {
            id: purchaseOrder.id,
            subtotal,
            totalTax,
            total,
            // lastUpdateAuthorId: window.authorId,
          },
        },
      })
    ).data.updatePurchaseOrder;
    // refreshPurchaseOrderPreview();
    // } else {
    //   refreshPurchaseOrder();
    // }

    recordActivityItem({
      purchaseOrder: latestUpdatedPurchaseOrder,
      type: "TOTAL_CHANGED",
      author: apiUser.id,
      clients,
      users,
      suppliers,
    });
  };

  displayApprovedVersion = () => {
    const { purchaseOrder, windowWidth, windowHeight } = this.props;
    const { approvedPdfData } = this.state;

    if (!approvedPdfData) {
      return null;
    }

    return (
      <DocumentDetailsModal
        open={true}
        attachment={{
          name: purchaseOrder.id,
          lastModified: purchaseOrder.reviewApprovedAt,
          key: purchaseOrder.exports[0].key,
          type: "PDF",
        }}
        versionId={purchaseOrder.exports[0].latestS3VersionId}
        document={approvedPdfData}
        onClose={() => this.setState({ isApprovedPdfVisible: false })}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
      />
    );
  };

  render() {
    const { purchaseOrder, users, clients, suppliers, organisationDetails, apiUser, setProps, context, projects } =
      this.props;
    const {
      form,
      formPreview,
      areCommentsVisible,
      hasNewComment,
      isUnderReview,
      numberForPreviewRefresh,
      isApprovedPdfVisible,
      hasApprovedPdf,
      isCorrupted,
      corruptedReason,
    } = this.state;

    if (isCorrupted) {
      return (
        <div className={cx("purchase-order-details-page")}>
          <div className="corrupted-message-container">
            <Typography.Text className="corrupted-title">
              It looks like this {getSimpleLabel("purchase order")} is corrupted.
            </Typography.Text>
            <Typography.Text className="corrupted-explanation">Reason: {corruptedReason}</Typography.Text>
            <Button type="primary" icon={<DeleteOutlined />} onClick={() => this.deletePurchaseOrder()}>
              Delete {getSimpleLabel("purchase order")}
            </Button>
          </div>
        </div>
      );
    }

    if (!form) {
      return null;
    }

    const review = purchaseOrder.reviews?.items[0];
    const supplier = suppliers.find((x) => x.id === purchaseOrder.supplierId);
    const project = projects.find((x) => x.id === purchaseOrder.projectId);

    let weHaveComments = false;
    if (review) {
      weHaveComments =
        hasNewComment || review.reviewThread.filter((item) => item.type === "COMMENT" && !item.resolved).length > 0;
    }

    const templateDetails = getTemplateFromOrganisation({
      organisationDetails,
      fileType: "INVOICE",
      templateId: purchaseOrder.templateId,
    });

    const clientDetails = clients.find((client) => client.id === purchaseOrder.clientId);

    let approvedReviewMessage = null;

    if (purchaseOrder.reviewStatus === "SUCCESS") {
      approvedReviewMessage =
        "The review for this purchase order has been approved. If you want to make changes to it, please cancel the approval.";
    }

    const sortedSupplierContacts = (supplier.contacts || []).sort((a, b) => {
      if (a?.id < b?.id) return -1;
      if (a?.id > b?.id) return 1;
      return 0;
    });

    return (
      <div
        className={cx("purchase-order-details-page", {
          "with-preview": this.state.isPreviewEnabled && (!purchaseOrder.reviewApprovedAt || !hasApprovedPdf),
          "with-comments": review && areCommentsVisible && weHaveComments,
          "is-archived": purchaseOrder.isArchived,
        })}
      >
        {displayModalContainingFields.call(this)}
        {isApprovedPdfVisible && this.displayApprovedVersion()}
        <div className="user-input-container">
          <PurchaseOrderActions
            purchaseOrder={purchaseOrder}
            review={review}
            history={this.props.history}
            isPreviewEnabled={this.state.isPreviewEnabled}
            isDownloadingPurchaseOrder={this.state.isDownloadingPurchaseOrder}
            isSendingPurchaseOrder={this.state.isSendingPurchaseOrder}
            hasApprovedPdf={hasApprovedPdf}
            showApprovedPdf={() => this.setState({ isApprovedPdfVisible: true })}
            onPreviewSwitch={(checked) => {
              this.setState({ isPreviewEnabled: checked });
              cookie.set(COOKIE_NAME_QUOTE_PREVIEW, checked ? "true" : "false", { expires: 99999 });
            }}
            onRequestReviewClick={() => {
              this.setState({ isRequestReviewModalVisible: true });
            }}
            archivePurchaseOrder={this.archivePurchaseOrder}
            restorePurchaseOrder={this.restorePurchaseOrder}
            downloadPurchaseOrder={this.downloadPurchaseOrder}
            sendPurchaseOrder={this.checkPurchaseOrderCanBeSent}
            windowWidth={this.props.windowWidth}
            areCommentsVisible={areCommentsVisible}
            onCommentsSwitch={(checked) => {
              this.setState({ areCommentsVisible: checked });
              cookie.set(COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE, checked ? "true" : "false", { expires: 99999 });
            }}
            apiUser={this.props.apiUser}
          />

          <div className="user-input-scroll-container">
            {purchaseOrder.isArchived && (
              <Alert
                showIcon
                className="purchase-order-archive-alert"
                message="This purchaseOrder is archived"
                type="info"
              />
            )}
            {this.state.sendStatus === "SUCCESS" && (
              <Alert showIcon className="send-status-alert" message="Purchase order sent successfully" type="success" />
            )}
            {this.state.sendStatus === "ERROR" && (
              <Alert showIcon className="send-status-alert" message="Purchase order failed to send" type="error" />
            )}
            {purchaseOrder.status === "PAID" && (
              <Alert
                showIcon
                className="paid-status-alert"
                message="This purchaseOrder has been marked as paid. While in this state, you cannot make any changes to it."
                type="info"
              />
            )}
            {approvedReviewMessage && (
              <Alert showIcon className="paid-status-alert" message={approvedReviewMessage} type="info" />
            )}

            <div className="reviewable-content">
              {review && (
                <PurchaseOrderReviewSummary
                  purchaseOrder={purchaseOrder}
                  project={project}
                  supplier={supplier}
                  apiUser={apiUser}
                  users={users}
                  clients={clients}
                  suppliers={suppliers}
                  potentialReviewers={this.getPotentialReviewers()}
                  dateTimeToCheckFormAgainst={this.state.dateTimeToCheckFormAgainst}
                  onSubmitReviewStart={() => {
                    this.setState({ hasApprovedPdf: false });
                  }}
                />
              )}
              <div className="purchase-order-metadata-wrapper">
                <PurchaseOrderMetadata
                  {...this}
                  {...this.props}
                  isDisabled={this.isDisabled()}
                  apiUser={apiUser}
                  users={users}
                  projects={projects}
                  supplier={supplier}
                  purchaseOrder={purchaseOrder}
                  organisationDetails={organisationDetails}
                  clientDetails={clientDetails}
                  sortedSupplierContacts={sortedSupplierContacts}
                  changeAttribute={this.changeAttribute}
                  debouncedChangeAttribute={this.debouncedChangeAttribute}
                  recalculateAmounts={this.recalculateAmounts}
                />
              </div>

              {this.displayPurchaseOrderFields()}
              <PurchaseOrderLineItemsCard
                purchaseOrder={purchaseOrder}
                purchaseOrderForDisplay={purchaseOrder}
                refreshPurchaseOrder={this.refreshPurchaseOrder}
                isUnderReview={isUnderReview}
                organisationDetails={organisationDetails}
                isDisabled={this.isDisabled()}
                users={users}
                clients={clients}
                setProps={setProps}
                context={context}
                recalculateAmounts={this.recalculateAmounts}
              />

              <PurchaseOrderActivity purchaseOrder={purchaseOrder} users={users} />
            </div>
            {areCommentsVisible && review && weHaveComments && (
              <DocumentReview
                parent={purchaseOrder}
                parentType="purchaseOrder"
                {...this}
                {...this.props}
                form={form}
                hasNewComment={this.state.hasNewComment}
                newCommentFieldName={this.state.newCommentFieldName}
                newCommentLineItemIndex={this.state.newCommentLineItemIndex}
                onNewCommentClose={() => {
                  this.setState({
                    hasNewComment: false,
                    newCommentFieldName: null,
                  });
                }}
                recordCommentActivityItem={this.recordCommentActivityItem}
              />
            )}
          </div>
        </div>

        {this.state.isPreviewEnabled && (!purchaseOrder.reviewApprovedAt || !hasApprovedPdf) && (
          <div
            className={cx("pdf-preview-container", {
              enabled: this.state.isPreviewEnabled,
            })}
          >
            <DocumentOutput
              apiUser={apiUser}
              purchaseOrder={purchaseOrder}
              project={project}
              users={users}
              clients={clients}
              suppliers={suppliers}
              supplier={supplier}
              supplierContact={supplier.contacts?.find((x) => x.id === purchaseOrder.supplierContact)}
              supplierAddress={supplier.addresses?.find((x) => x.id === purchaseOrder.supplierAddress)}
              organisationDetails={organisationDetails}
              previewData={formPreview}
              onDataUri={this.onDataUri}
              projectFolder={this.state.projectFolder}
              setIsLoading={this.props.setIsLoading}
              templateDetails={templateDetails}
              numberForRefresh={numberForPreviewRefresh}
            />
          </div>
        )}
        {displayInsertAttachmentPickerModal.call(this)}
        {displayInsertAttachmentModal.call(this)}
        {displayReportUserListModal.call(this)}
        {this.state.isRequestReviewModalVisible && (
          <RequestPurchaseOrderReviewModal
            purchaseOrder={purchaseOrder}
            users={users}
            clients={clients}
            projects={projects}
            suppliers={suppliers}
            apiUser={apiUser}
            onClose={() => this.setState({ isRequestReviewModalVisible: false }, this.refreshPurchaseOrder)}
            potentialReviewers={this.getPotentialReviewers()}
          />
        )}
        {this.state.isSendPurchaseOrderModalVisible && (
          <SendPurchaseOrderModal
            purchaseOrder={purchaseOrder}
            supplier={supplier}
            form={form}
            onClose={() => this.setState({ isSendPurchaseOrderModalVisible: false })}
            setSendStatus={(sendStatus) => this.setState({ sendStatus })}
            changePurchaseOrderAttribute={this.changeAttribute}
            approvedPdfData={this.state.approvedPdfData}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: PurchaseOrderDetailsPage,
    subscriptions: [
      "purchaseOrder",
      "tasks",
      "projects",
      "suppliers",
      "clients",
      "timesheetBlocks",
      "users",
      "timesheetTags",
      "organisationDetails",
    ],
  })
);
