import { html2json } from "html2json";
import moment from "moment";

import { getSimpleLabel } from "common/labels";

const fields = [
  {
    id: "taskId",
    fieldTypes: ["textfield"],
    label: "ID",
    value: ({ task }) => task.id,
  },
  {
    id: "taskNumber",
    fieldTypes: ["textfield"],
    label: "Task number (assuming regular task ID format)",
    value: ({ task }) => task.id.split("-")[2],
  },
  {
    id: "taskInitials",
    fieldTypes: ["textfield"],
    label: "Initials",
    value: ({ task }) => {
      return task?.initials;
    },
  },
  {
    id: "taskTitle",
    fieldTypes: ["textfield"],
    label: "Title",
    value: ({ task }) => task.title,
  },
  {
    id: "taskCreatedAtDate",
    fieldTypes: ["textfield"],
    label: "Creation date",
    isDate: true,
    value: ({ task, dateFormat }) => moment(task.createdAt).format(dateFormat),
  },
  {
    id: "taskAuthorityLevelNumber",
    fieldTypes: ["textfield"],
    label: "Authority level - number",
    value: ({ task }) => {
      if (task.catLevel === undefined || task.catLevel === null) {
        return "";
      }
      return task.catLevel;
    },
  },
  {
    id: "taskDescription",
    fieldTypes: ["textfield"],
    label: "Description",
    value: async ({ task }) => {
      let descriptionTemplateString = "";

      let parsedDescription;
      try {
        parsedDescription = JSON.parse(task.subtitle);
      } catch (e) {
        const descriptionJson = await html2json(task.subtitle);
        let descriptionTemplateString = "";
        descriptionJson.child.forEach((child) => {
          child.child.forEach((innerChild) => {
            if (descriptionTemplateString.length > 0) {
              descriptionTemplateString += "\n";
            }
            descriptionTemplateString += `${innerChild.text}`;
          });
        });
      }

      parsedDescription?.forEach((paragraph) => {
        paragraph.children.forEach((child) => {
          if (child.children) {
            child.children.forEach((innerChild) => {
              if (descriptionTemplateString.length > 0) {
                descriptionTemplateString += "\n";
              }
              descriptionTemplateString += `${innerChild.text}\n`;
            });
          } else {
            if (descriptionTemplateString.length > 0) {
              descriptionTemplateString += "\n";
            }
            descriptionTemplateString += `${child.text}\n`;
          }
        });
      });
      return descriptionTemplateString;
    },
  },
  {
    id: "taskCreatedByPosition",
    fieldTypes: ["textfield"],
    label: "Created by position",
    value: ({ task, users }) => {
      const createdByDetails = users.find((user) => user.id === task.author);
      if (!createdByDetails) {
        return "";
      }
      return `${createdByDetails.position}`;
    },
  },
  {
    id: "taskCreatedByInitials",
    fieldTypes: ["textfield"],
    label: "Created by initials",
    value: ({ task, users }) => {
      const createdByDetails = users.find((user) => user.id === task.author);
      if (!createdByDetails) {
        return "";
      }
      return `${createdByDetails.firstName[0]}${createdByDetails.lastName[0]}`;
    },
  },
  {
    id: "taskCreatedByFullName",
    fieldTypes: ["textfield"],
    label: "Created by full name",
    value: ({ task, users }) => {
      const createdByDetails = users.find((user) => user.id === task.author);
      if (!createdByDetails) {
        return "";
      }
      return `${createdByDetails.firstName} ${createdByDetails.lastName}`;
    },
  },
  {
    id: "taskAssigneeInitials",
    fieldTypes: ["textfield"],
    label: "Assignee initials",
    value: ({ task, users }) => {
      const assignedTo = users.find((user) => user.id === task.assignedTo);
      if (!assignedTo) {
        return "";
      }
      return `${assignedTo.firstName[0]}${assignedTo.lastName[0]}`;
    },
  },
  {
    id: "taskAssigneePosition",
    fieldTypes: ["textfield"],
    label: "Assignee position",
    value: ({ task, users }) => {
      const assignedTo = users.find((user) => user.id === task.assignedTo);
      if (!assignedTo) {
        return "";
      }
      return `${assignedTo.position}`;
    },
  },

  {
    id: "taskAssigneeFullName",
    fieldTypes: ["textfield"],
    label: "Assignee full name",
    value: ({ task, users }) => {
      const assignedTo = users.find((user) => user.id === task.assignedTo);
      if (!assignedTo) {
        return "";
      }
      return `${assignedTo.firstName} ${assignedTo.lastName}`;
    },
  },
  {
    id: "taskRevisions",
    fieldTypes: ["repeatFor"],
    label: "Each task revision in task",
    repeatForFieldName: "taskRevision",
    value: ({ task }) => task.revisions.items,
  },
  {
    id: "taskRevisionsDueThisMonth",
    fieldTypes: ["repeatFor"],
    label: "Each task revision due this month",
    repeatForFieldName: "taskRevision",
    value: ({ task }) =>
      task.revisions.items.filter((revision) => {
        if (!revision.dueDate) {
          return false;
        }
        return moment(revision.dueDate).isSame(moment(), "month");
      }),
  },
  {
    id: "taskRevisionsDueLastMonthAndFinishedLastMonth",
    fieldTypes: ["repeatFor"],
    label: "Each task revision due last month and finished last month",
    repeatForFieldName: "taskRevision",
    value: ({ task }) => {
      return task.revisions.items.filter((revision) => {
        if (!revision.dueDate) {
          return false;
        }
        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();

        let includeRevision =
          revision.dueDate &&
          revision.dueDate >= startAt &&
          revision.dueDate <= endAt &&
          revision.reviewAcceptDate >= startAt &&
          revision.reviewAcceptDate <= endAt;

        return includeRevision;
      });
    },
  },
  {
    id: "latestTaskRevision",
    fieldTypes: ["repeatFor"],
    label: "Latest task revision",
    repeatForFieldName: "taskRevision",
    value: ({ task }) => {
      return task.revisions.items.slice(-1)[0];
    },
  },
  {
    id: "taskDueDate",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Due date",
    value: ({ task, dateFormat, addDays }) => {
      if (!task) {
        return "";
      } else {
        if (!task.dueDate) {
          return "";
        } else {
          return moment(task.dueDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "taskIsDesignForCat2Check",
    fieldTypes: ["textfield"],
    label: "Is design task for Cat 2 check",
    value: ({ task }) => {
      let cat2CheckRelationship = (task.linkedTasks || []).find((x) => x.relationship === "NEEDS_CAT_2_CHECK");
      return !!cat2CheckRelationship;
    },
  },
  {
    id: "taskIsCat2Check",
    fieldTypes: ["textfield"],
    label: "Is Cat 2 check",
    value: ({ task }) => {
      let cat2CheckRelationship = (task.linkedTasks || []).find((x) => x.relationship === "CAT_2_CHECK_FOR");
      return !!cat2CheckRelationship;
    },
  },
  {
    id: "nameOfCat2Check",
    fieldTypes: ["textfield"],
    label: "Cat 2 check task title",
    value: async ({ task }) => {
      const cat2CheckTaskDetails = await getCat2CheckTaskDetails(task);
      if (!cat2CheckTaskDetails) {
        return;
      }

      return cat2CheckTaskDetails.title;
    },
  },
  {
    id: "currentUserIsCat2Checker",
    fieldTypes: ["textfield"],
    label: "Current user is Cat 2 checker",
    value: async ({ apiUser, task }) => {
      const cat2CheckTaskDetails = await getCat2CheckTaskDetails(task);
      if (!cat2CheckTaskDetails) {
        return;
      }

      return cat2CheckTaskDetails.assignedTo === apiUser.id;
    },
  },
  {
    id: "assigneeOfCat2CheckFullName",
    fieldTypes: ["textfield"],
    label: "Cat 2 check assignee full name",
    value: async ({ task, users }) => {
      const cat2CheckTaskDetails = await getCat2CheckTaskDetails(task);
      if (!cat2CheckTaskDetails) {
        return;
      }

      let assignedTo = cat2CheckTaskDetails.assignedTo;
      if (!assignedTo) {
        return;
      }

      const assigneeDetails = users.find((user) => user.id === assignedTo);
      if (!assigneeDetails) {
        return;
      }

      return `${assigneeDetails.firstName} ${assigneeDetails.lastName}`;
    },
  },
  {
    id: "assigneeOfCat2CheckPosition",
    fieldTypes: ["textfield"],
    label: "Cat 2 check assignee position",
    value: async ({ task, users }) => {
      const cat2CheckTaskDetails = await getCat2CheckTaskDetails(task);
      if (!cat2CheckTaskDetails) {
        return;
      }

      let assignedTo = cat2CheckTaskDetails.assignedTo;
      if (!assignedTo) {
        return;
      }

      const assigneeDetails = users.find((user) => user.id === assignedTo);
      if (!assigneeDetails) {
        return;
      }

      return `${assigneeDetails.position}`;
    },
  },
  {
    id: "assigneeOfCat2CheckSignature",
    fieldTypes: ["textfield"],
    label: "Cat 2 check assignee signature",
    value: async ({ task, users }) => {
      const cat2CheckTaskDetails = await getCat2CheckTaskDetails(task);
      if (!cat2CheckTaskDetails) {
        return;
      }

      let assignedTo = cat2CheckTaskDetails.assignedTo;
      if (!assignedTo) {
        return;
      }

      const assigneeDetails = users.find((user) => user.id === assignedTo);
      if (!assigneeDetails) {
        return;
      }

      return {
        image: assigneeDetails.signature,
        firstName: assigneeDetails.firstName,
        lastName: assigneeDetails.lastName,
      };
    },
  },
  {
    id: "approvedDateOfCat2Check",
    fieldTypes: ["textfield"],
    label: "Cat 2 check approved at",
    isDate: true,
    value: async ({ task, dateFormat, addDays }) => {
      const cat2CheckTaskDetails = await getCat2CheckTaskDetails(task);

      if (!cat2CheckTaskDetails) {
        return;
      }

      let latestTaskRevision = cat2CheckTaskDetails.revisions?.items?.slice(-1)[0];
      if (!latestTaskRevision) {
        return;
      }

      if (!latestTaskRevision.reviewAcceptDate) {
        return "";
      } else {
        return moment(latestTaskRevision.reviewAcceptDate).add(addDays, "day").format(dateFormat);
      }
    },
  },
  {
    id: "approvedDateOfCat2CheckOrToday",
    fieldTypes: ["textfield"],
    label: "Cat 2 check approved at or today",
    isDate: true,
    value: async ({ task, dateFormat, addDays }) => {
      const cat2CheckTaskDetails = await getCat2CheckTaskDetails(task);
      if (!cat2CheckTaskDetails) {
        return;
      }

      let latestTaskRevision = cat2CheckTaskDetails.revisions?.items?.slice(-1)[0];
      if (!latestTaskRevision) {
        return;
      }

      if (!latestTaskRevision.reviewAcceptDate) {
        return moment().add(addDays, "day").format(dateFormat);
      } else {
        return moment(latestTaskRevision.reviewAcceptDate).add(addDays, "day").format(dateFormat);
      }
    },
  },

  {
    id: "taskAttachments",
    fieldTypes: ["repeatFor"],
    label: "Each attachment file in task",
    repeatForFieldName: "attachment",
    value: async ({ task }) => {
      return [];
      // const prefix = `public/${task.organisation}/projects/${task.projectId}/attachments/${task.id}/`;
      // const response = await callRest({
      //   method: "GET",
      //   route: `/s3-list?prefix=${btoa(prefix)}&withDelimiter=false`,
      //   includeCredentials: false,
      // });
      // const result = response.Contents.map((attachment) => {
      //   return {
      //     ...attachment,
      //     Key: attachment.Key.replace(prefix, ""),
      //   };
      // }).filter((attachment) => attachment.Key && attachment.Key.length > 0);
      // console.log(result);
      // return result;
    },
  },
];

async function getCat2CheckTaskDetails(task) {
  let cat2CheckRelationship = (task.linkedTasks || []).find((x) => x.relationship === "NEEDS_CAT_2_CHECK");

  if (!cat2CheckRelationship) {
    return undefined;
  }

  let cat2CheckTaskDetails;
  if (global.isBrowser) {
    cat2CheckTaskDetails = (
      await window.callGraphQLSimple({
        message: `Failed to fetch Cat 2 check ${getSimpleLabel("task")}`,
        queryCustom: "getTaskSimple",
        variables: {
          id: cat2CheckRelationship.taskId,
        },
      })
    ).data.getTask;
  } else {
    cat2CheckTaskDetails = (
      await global.callGraphQLSimple({
        message: `Failed to fetch Cat 2 check ${getSimpleLabel("task")}`,
        queryName: "getTaskSimple",
        variables: {
          id: cat2CheckRelationship.taskId,
        },
      })
    ).data.getTask;
  }

  if (!cat2CheckTaskDetails) {
    return undefined;
  }

  return cat2CheckTaskDetails;
}

export function getFields({ organisationDetails }) {
  let extraFields = [];

  if (organisationDetails?.customFields && organisationDetails.customFields.length > 0) {
    organisationDetails.customFields.forEach((fieldDefinition) => {
      extraFields.push({
        id: `customTaskField_${fieldDefinition.id}`,
        fieldTypes: ["textfield"],
        label: fieldDefinition.label,
        value: ({ task }) => {
          if (!task || !task.customFields) {
            return "";
          }
          let targetField = task.customFields.find((field) => field.id === fieldDefinition.id);
          if (!targetField) {
            return "";
          }
          return targetField.value;
        },
      });
    });
  }

  return [...fields, ...extraFields];
}

export const label = "Task";
