import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

export default async function changeProjectStatus({ projectId, status }) {
  await callGraphQLSimple({
    message: `Failed to change ${getSimpleLabel("project")} status`,
    queryName: "updateProject",
    variables: {
      input: {
        id: projectId,
        status,
      },
    },
  });
}
