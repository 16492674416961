import { useState } from "react";
import { Storage } from "aws-amplify";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Button, Typography, Space, message } from "antd";
import { readAndCompressImage } from "browser-image-resizer";

import { callGraphQLSimple } from "common/apiHelpers";

import Upload from "Upload/Upload";

import "./UploadAvatarModal.scss";

export default function UploadAvatarModal({
  onClose,
  user,
  stockItem,
  parentType = "user",
  organisationDetails = undefined,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ avatar }) {
    setIsLoading(true);

    let fileKey;
    let maxWidth;
    let maxHeight;

    if (parentType === "organisation") {
      fileKey = `${organisationDetails.id}/avatars/organisation/${Date.now()}_${Math.floor(Math.random() * 10000)}`;
      maxWidth = 700;
      maxHeight = 700;
    } else if (parentType === "stockItem") {
      fileKey = `${stockItem.organisation}/avatars/${Date.now()}_${Math.floor(Math.random() * 10000)}`;
      maxWidth = 200;
      maxHeight = 200;
    } else {
      fileKey = `${user.organisation}/avatars/${Date.now()}_${Math.floor(Math.random() * 10000)}`;
      maxWidth = 200;
      maxHeight = 200;
    }
    // max width and height different for each case
    let resizedAvatar = await readAndCompressImage(avatar, {
      quality: 1,
      maxWidth,
      maxHeight,
      mimeType: avatar.type,
    });

    Storage.put(fileKey, resizedAvatar, {
      contentType: "image/png",
    })
      .then(({ key }) => {
        form.setFieldsValue({ avatarKey: key });
        if (parentType === "user") {
          triggerUpdateUser({ avatarKey: key });
        } else if (parentType === "stockItem") {
          triggerUpdateStockItem({ avatarKey: key });
        } else {
          triggerUpdateOrganisation({ logoKey: key });
        }
      })
      .catch((err) => console.log(err));
  }

  async function triggerUpdateUser({ avatarKey }) {
    try {
      await callGraphQLSimple({
        message: "Failed to change the avatar",
        queryName: "updateUser",
        variables: {
          input: {
            id: user.id,
            avatarKey,
          },
        },
      });

      message.success({
        content: <Typography.Text>Avatar has been set</Typography.Text>,
        className: "update-user-success",
      });

      onClose();
    } catch (err) {
      console.log("error updating user:", err);
      message.error("Failed to change the avatar");
    }
  }

  async function triggerUpdateStockItem({ avatarKey }) {
    try {
      await callGraphQLSimple({
        message: "Failed to change the image",
        queryName: "updateStockItem",
        variables: {
          input: {
            id: stockItem.id,
            avatarKey,
          },
        },
      });

      message.success({
        content: <Typography.Text>Avatar has been set</Typography.Text>,
        className: "update-user-success",
      });

      onClose();
    } catch (err) {
      console.log("error updating stock item:", err);
      message.error("Failed to change the avatar");
    }
  }

  async function triggerUpdateOrganisation({ logoKey }) {
    try {
      await callGraphQLSimple({
        message: "Failed to change the logo",
        queryName: "updateOrganisation",
        variables: {
          input: {
            id: organisationDetails.id,
            logoKey,
          },
        },
      });

      message.success({
        content: <Typography.Text>Organisation logo has been set</Typography.Text>,
        className: "update-user-success",
      });

      onClose();
    } catch (err) {
      console.log("error updating organisation:", err);
      message.error("Failed to change the organisation logo");
    }
  }

  async function deleteUserAvatar() {
    try {
      const apiResponse = await callGraphQLSimple({
        message: "Could not remove the avatar",
        queryName: "updateUser",
        variables: {
          input: {
            id: user.id,
            avatarKey: null,
          },
        },
      });

      message.success({
        content: <Typography.Text>Avatar has been deleted</Typography.Text>,
        className: "update-user-success",
      });

      onClose();
      console.log("apiResponse: ", apiResponse);
    } catch (err) {
      console.log("error deleting avatar:", err);
    }
  }

  async function deleteStockItemAvatar() {
    try {
      const apiResponse = await callGraphQLSimple({
        message: "Could not remove the image",
        queryName: "updateStockItem",
        variables: {
          input: {
            id: user.id,
            avatarKey: null,
          },
        },
      });

      message.success({
        content: <Typography.Text>Image has been deleted</Typography.Text>,
        className: "update-user-success",
      });

      onClose();
      console.log("apiResponse: ", apiResponse);
    } catch (err) {
      console.log("error deleting stock item avatar:", err);
    }
  }

  async function deleteOrganisationLogo() {
    try {
      const apiResponse = await callGraphQLSimple({
        message: "Could not remove the organisation logo",
        queryName: "updateOrganisation",
        variables: {
          input: {
            id: organisationDetails.id,
            logoKey: null,
          },
        },
      });

      message.success({
        content: <Typography.Text>Logo has been deleted</Typography.Text>,
        className: "update-user-success",
      });

      onClose();
      console.log("apiResponse: ", apiResponse);
    } catch (err) {
      console.log("error deleting organisation logo:", err);
    }
  }

  function confirmDeleteUserAvatar() {
    Modal.confirm({
      title: "Confirm remove avatar",
      icon: <ExclamationCircleOutlined />,
      className: "remove-avatar-modal",
      content: <>Are you sure you want to remove your existing avatar?</>,
      onOk: deleteUserAvatar,
    });
  }

  function confirmDeleteStockItemAvatar() {
    Modal.confirm({
      title: "Confirm remove image",
      icon: <ExclamationCircleOutlined />,
      className: "remove-avatar-modal",
      content: <>Are you sure you want to remove the existing?</>,
      onOk: deleteStockItemAvatar,
    });
  }

  function confirmDeleteOrganisationLogo() {
    Modal.confirm({
      title: "Confirm remove logo",
      icon: <ExclamationCircleOutlined />,
      className: "remove-avatar-modal",
      content: <>Are you sure you want to remove the existing organisation logo?</>,
      onOk: deleteOrganisationLogo,
    });
  }

  async function checkImagePath(_, file, callback) {
    return new Promise((resolve, reject) => {
      if (file.name.includes(".jpg") || file.name.includes(".png") || file.name.includes(".jpeg")) {
        resolve();
      } else {
        reject("");
      }
    });
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  let title = "";
  let avatarItemLabel = "";
  let deleteCallBack;

  if (parentType === "user") {
    title = "Change avatar";
    avatarItemLabel = "Avatar";
    deleteCallBack = confirmDeleteUserAvatar;
  } else if (parentType === "stockItem") {
    title = "Change image";
    avatarItemLabel = "Image";
    deleteCallBack = confirmDeleteStockItemAvatar;
  } else {
    title = "Change logo";
    avatarItemLabel = "Logo";
    deleteCallBack = confirmDeleteOrganisationLogo;
  }

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="upload-avatar-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            name="avatar"
            label={avatarItemLabel}
            getValueFromEvent={(e, file) => file}
            rules={[
              { required: true, message: "You must choose an image first" },
              {
                required: true,
                validator: checkImagePath,
                message: "Image must be either .jpg or .png",
              },
            ]}
          >
            <Upload size="normal" fullWidth />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="submit-button">
              Submit
            </Button>
            <Button onClick={deleteCallBack} className="remove-avatar">
              Remove
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}
