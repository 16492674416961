import React from "react";
import moment from "moment";
import cx from "classnames";
import { Tooltip, Typography } from "antd";
import { CheckCircleOutlined, CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

import { getSimpleLabel } from "common/labels";
import { taskIdToColor } from "../timelineHelpers";

import TaskItem from "DashboardPage/TaskItem/TaskItem";
import LazyLoadList from "LazyLoadList/LazyLoadList";

import "./UnplannedTaskList.scss";

export class UnplannedTaskList extends React.Component {
  state = {
    selectedTab: "UNPLANNED",
  };

  async componentDidUpdate(_, prevState) {
    if (prevState.selectedTab !== this.state.selectedTab) {
      this.resetTasksToDisplay();
    }
  }

  resetTasksToDisplay = () => {
    document.querySelector(".unplanned-task-list").scrollTop = 0;
  };

  getUnplannedTasks = () => {
    const { timelineBlocks, planningStartDate, planningEndDate, organisationDetails } = this.props;
    let tasksWithPlanningStatus = this.props.tasks.map((task) => {
      let taskIsPlanned;

      if (organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks) {
        taskIsPlanned = task?.revisions?.items?.every((taskRevision) => {
          return timelineBlocks.some((block) => {
            return (
              block.taskRevisionId === taskRevision.id &&
              moment(block.startDate).isSameOrAfter(planningStartDate) &&
              moment(block.startDate).isBefore(planningEndDate)
            );
          });
        });
      } else if (organisationDetails.settings?.timeline?.planTaskStatusesInsteadOfTasks) {
        taskIsPlanned = organisationDetails.taskStatuses?.every((taskStatus) => {
          return timelineBlocks.some((block) => {
            return (
              block.taskStatus === taskStatus.name &&
              moment(block.startDate).isSameOrAfter(planningStartDate) &&
              moment(block.startDate).isBefore(planningEndDate)
            );
          });
        });
      } else {
        taskIsPlanned = timelineBlocks.some((block) => {
          return (
            block.taskId === task.id &&
            moment(block.startDate).isSameOrAfter(planningStartDate) &&
            moment(block.startDate).isBefore(planningEndDate)
          );
        });
      }

      return { ...task, planned: taskIsPlanned };
    });

    let unplannedTasks = tasksWithPlanningStatus.filter((task) => !task.planned);
    return unplannedTasks;
  };

  render() {
    const { organisationDetails, onOpenTask, tasks, isClosed } = this.props;
    const { selectedTab } = this.state;

    let tasksToDisplay = tasks;
    const unplannedTasks = this.getUnplannedTasks();
    if (selectedTab === "UNPLANNED") {
      tasksToDisplay = unplannedTasks;
    }

    return (
      <div className={cx("unplanned-task-list", { "is-closed": isClosed })} data-cy="unplanned-task-list">
        <Tooltip trigger={["hover", "click"]} title={isClosed ? "Open panel" : "Close panel"}>
          <div
            className="toggle"
            onClick={() => {
              if (isClosed) {
                this.props.onOpen();
              } else {
                this.props.onClose();
              }
            }}
          >
            {isClosed ? <CaretRightOutlined /> : <CaretLeftOutlined />}
          </div>
        </Tooltip>
        {isClosed ? null : (
          <div className="content">
            <div className="tab-container">
              <Typography.Text
                className={cx("tab", { selected: selectedTab === "UNPLANNED" })}
                onClick={() => this.setState({ selectedTab: "UNPLANNED" })}
                data-cy="tab-unplanned-button"
              >
                Unplanned ({unplannedTasks.length} items)
              </Typography.Text>
              <div className="separator" />
              <Typography.Text
                className={cx("tab", { selected: selectedTab === "ALL" })}
                onClick={() => this.setState({ selectedTab: "ALL" })}
                data-cy="tab-all-button"
              >
                All {getSimpleLabel("tasks")} ({tasks.length} items)
              </Typography.Text>
            </div>

            {unplannedTasks.length === 0 && selectedTab === "UNPLANNED" && (
              <div className="no-tasks-to-plan" data-cy="no-tasks-to-plan">
                <Typography.Text className="party-icon">
                  <CheckCircleOutlined />
                </Typography.Text>
                <Typography.Text className="message">
                  You have planned <br />
                  everything!
                </Typography.Text>
              </div>
            )}
            <LazyLoadList
              key={selectedTab}
              list={tasksToDisplay}
              item={(task, index) => {
                let isDraggable =
                  !organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks &&
                  !organisationDetails.settings?.timeline?.planTaskStatusesInsteadOfTasks;
                let dragParams = {};

                if (isDraggable) {
                  dragParams.draggable = true;
                  dragParams.onDragStart = (e) => {
                    var dragImageElement = e.currentTarget.querySelector(".project-and-task-title");
                    if (!dragImageElement) {
                      e.preventDefault();
                      e.stopPropagation();
                      return;
                    }
                    e.dataTransfer.setDragImage(dragImageElement, 0, 0);
                    e.dataTransfer.setData("task-id", task.id);
                    e.dataTransfer.setData("draggable-type", "task");
                  };
                }

                return (
                  <div
                    className="unplanned-task-container"
                    key={task.id}
                    {...dragParams}
                    data-task-id={task.id}
                    data-cy="unplanned-task-item"
                    data-task-title={task.title}
                  >
                    <TaskItem
                      task={task}
                      index={index}
                      organisationDetails={organisationDetails}
                      draggable={false}
                      idColor={taskIdToColor(task.id)}
                      includeDueDate={true}
                      onClick={onOpenTask}
                      isTimelinePage
                      timelineBlocks={this.props.timelineBlocks}
                      isUnplannedTaskList={selectedTab === "UNPLANNED"}
                      planningStartDate={this.props.planningStartDate}
                      planningEndDate={this.props.planningEndDate}
                    />
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default React.memo(UnplannedTaskList);
