import { useEffect, useState } from "react";
import { message, Typography } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";

import ReviewCommentBox from "../ReviewCommentBox/ReviewCommentBox";
import Switch from "Switch/Switch";
import ReviewActivityList from "ReviewPage/ReviewActivityList/ReviewActivityList";

import "./ReviewConversation.scss";

export function ReviewConversation({
  task,
  review,
  externalReview,
  isExternalReview,
  apiUser,
  users,
  taskRevision,
  reviewIsActive,
  request,
}) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [oldItemCount, setOldItemCount] = useState(0);
  const [includeResolved, setIncludeResolved] = useState(true);

  let userIsReviewer = apiUser.id === taskRevision.checkedBy;
  let userIsAuthor = apiUser.id === taskRevision.author;

  // scroll to the bottom when new items come in and on first render
  useEffect(() => {
    const newItemCount = review.reviewThread.length;

    if (isFirstRender || newItemCount > oldItemCount) {
      setTimeout(() => {
        try {
          const activityList = document.querySelector(".activity-list");
          activityList.scrollTop = activityList.scrollHeight;
        } catch (e) {
          // nothing we can do, ignoring this
        }
      }, 0);
    }
    setIsFirstRender(false);
    setOldItemCount(newItemCount);
  }, [isFirstRender, review, oldItemCount]); // eslint-disable-line

  async function getUpToDateReview() {
    const upToDateReview = (
      await callGraphQLSimple({
        message: "Failed to retrieve review details",
        queryName: "getReview",
        variables: {
          id: review.id,
        },
      })
    ).data.getReview;

    return upToDateReview;
  }

  async function onSubmitComment(commentBody) {
    const upToDateReview = await getUpToDateReview();
    await callGraphQLSimple({
      message: "Failed to submit comment",
      queryName: "updateReview",
      variables: {
        input: {
          id: upToDateReview.id,
          reviewThread: [
            ...upToDateReview.reviewThread,
            {
              id: String(new Date().toISOString()) + String(Math.floor(Math.random() * 1000)),
              type: "COMMENT",
              createdAt: new Date().toISOString(),
              content: commentBody,
              author: apiUser.id,
            },
          ],
        },
      },
    });

    if (request) {
      await callGraphQLSimple({
        displayError: false,
        queryName: "updateRequest",
        variables: {
          input: {
            id: request.id,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        },
      });
    } else {
      if (!isExternalReview) {
        await callGraphQLSimple({
          displayError: false,
          queryName: "updateTaskRevision",
          variables: {
            input: {
              id: taskRevision.id,
              randomNumber: Math.floor(Math.random() * 100000),
            },
          },
        });
      }
    }

    message.success(<Typography.Text>Your comment has been added</Typography.Text>);
  }

  function displayActivity() {
    let compiledThread = [...review.reviewThread, ...(externalReview?.reviewThread || [])].sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 1
    );
    let itemsToDisplay = compiledThread.filter(
      (x) => !x.type.includes("ANNOTATION") || x.type === "ANNOTATION_TEXT" || x.type === "ANNOTATION_LEADER_LINE"
    );

    if (!includeResolved) {
      itemsToDisplay = itemsToDisplay.filter((x) => !x.resolved);
    }
    return (
      <ReviewActivityList
        items={itemsToDisplay}
        users={users}
        task={task}
        reviewIsActive={reviewIsActive}
        taskRevision={taskRevision}
        includeResolved={includeResolved}
        apiUser={apiUser}
        review={review}
        externalReview={externalReview}
        isExternalReview={isExternalReview}
        request={request}
      />
    );
  }

  return (
    <div className="review-conversation">
      <Typography.Title level={3} className="activity-title">
        All review activity so far
        <Switch
          className="include-resolved"
          checked={includeResolved}
          onChange={(checked) => {
            setIncludeResolved(checked);
          }}
          label="Include resolved"
          size="small"
        />
      </Typography.Title>
      {displayActivity()}

      <ReviewCommentBox
        users={users}
        task={task}
        apiUser={apiUser}
        onSubmitComment={onSubmitComment}
        userIsReviewer={userIsReviewer}
        userIsAuthor={userIsAuthor}
        taskRevision={taskRevision}
      />
    </div>
  );
}

export default withSubscriptions({
  Component: ReviewConversation,
  subscriptions: ["users"],
});
