import { useEffect } from "react";
import { Typography, Table } from "antd";
import { Link, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { useGetSetState } from "react-use";

import { Request, Project, Client } from "common/types";
import { REQUEST_STATUSES } from "common/constants";
import { truncateText, processIdForDisplay } from "common/shared";
import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { isAuthorised } from "common/permissions";

import ClientLogo from "ClientLogo/ClientLogo";
import Avatar from "Avatar/Avatar";
import DashboardItemTags from "DashboardItemTags/DashboardItemTags";

import "./RequestsTable.scss";

interface Props extends RouteComponentProps {
  // Your custom props here, e.g.
  filteredRequests: Request[];
  projects: Project[];
  clients: Client[];
  organisationDetails: any;
  apiUser: any;
  users: any;
}

type TableRow = Request & {
  client: Client | undefined;
  project: Project | undefined;
};

const localStoragePrefix = "requests-table";

export function RequestsTable({ organisationDetails, filteredRequests, projects, clients, apiUser, users }: Props) {
  const [getState, setState] = useGetSetState({
    sortOrderByColumn: {},
  });
  useEffect(() => {
    getValuesFromLocalStorage();
  }, []);

  async function getValuesFromLocalStorage() {
    const localStorageValue = await window.localDatabase.getItem(`${localStoragePrefix}-${organisationDetails.id}`);
    let newState = {};

    if (localStorageValue) {
      try {
        const localStorageValueParsed = JSON.parse(localStorageValue);
        const { sortOrderByColumn } = localStorageValueParsed;
        newState = { sortOrderByColumn };
      } catch (e) {
        console.error("Failed to parse local database value for RequestsTable:", localStorageValue);
      }
    }

    setState(newState);
  }

  function onTableChange(_, __, sorter) {
    const { columnKey, order } = sorter;
    const newSortOrderByColumn = {};

    if (columnKey) {
      newSortOrderByColumn[columnKey] = order;
    }

    const newValues = {
      sortOrderByColumn: newSortOrderByColumn,
    };

    window.localDatabase.setItem(`${localStoragePrefix}-${organisationDetails.id}`, JSON.stringify(newValues));
    setState(newValues);
  }

  const { sortOrderByColumn } = getState();

  const columns = [
    {
      title: "Request",
      key: "title",
      align: "left",
      render: (_, row) => {
        return (
          <Link to={`/requests/${row.id}`}>
            {processIdForDisplay(row.id)} - {truncateText(row.title)}
          </Link>
        );
      },
      sortOrder: sortOrderByColumn["title"],
      sorter: {
        compare: (a, b) => {
          if (!a.title && b.title) {
            return -1;
          }
          if (a.title && !b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Status",
      key: "status",
      render: (_, row) => {
        if (!row.status) {
          return "(status not found)";
        }
        let statusDetails = REQUEST_STATUSES.find((status) => status.value === row.status);
        let content: any = null;
        if (!statusDetails) {
          content = "(status not found)";
        } else {
          content = statusDetails.label;
        }

        return content;
      },
      sortOrder: sortOrderByColumn["status"],
      sorter: {
        compare: (a, b) => {
          if (!a.status && b.status) {
            return -1;
          }
          if (a.status && !b.status) {
            return 1;
          }
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }

          return 0;
        },
      },
    },
    {
      title: getSimpleLabel("requests-page-tags"),
      key: "reviewStatus",
      width: 140,
      align: "center",
      render: (_, row) => {
        return (
          <DashboardItemTags
            item={{ ...row }}
            organisationDetails={organisationDetails}
            skipCatLevel={true}
            skipInstructed={true}
            skipCustomFields={true}
          />
        );
      },
    },
    {
      title: "Latest form name",
      key: "latestFormName",
      dataIndex: "latestFormName",
      align: "left",
      sortOrder: sortOrderByColumn["latestFormName"],
      render: (latestFormName) => {
        return <span>{truncateText(latestFormName, 80)}</span>;
      },
      sorter: {
        compare: (a, b) => {
          if (!a.latestFormName && b.latestFormName) {
            return -1;
          }
          if (a.latestFormName && !b.latestFormName) {
            return 1;
          }
          if (a.latestFormName < b.latestFormName) {
            return -1;
          }
          if (a.latestFormName > b.latestFormName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Project",
      key: "project",
      align: "left",
      render: (_, row) => {
        if (!row.project) {
          return "";
        }

        if (isAuthorised(["PROJECT_DETAILS.VIEW"])) {
          return <Link to={`/projects/${row.project.id}`}>{truncateText(row.project.title, 40)}</Link>;
        } else {
          return <Typography.Text>{truncateText(row.project.title, 40)}</Typography.Text>;
        }
      },
      sortOrder: sortOrderByColumn["project"],
      sorter: {
        compare: (a, b) => {
          if (!a.project && !b.project) {
            return 0;
          }
          if (!a.project && b.project) {
            return -1;
          }
          if (a.project && !b.project) {
            return 1;
          }
          if (a.project.title < b.project.title) {
            return -1;
          }
          if (a.project.title > b.project.title) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: getSimpleLabel("Client"),
      key: "client",
      align: "left",
      width: 150,

      render: (_, row) => {
        if (!row.client) {
          return "";
        }
        return <ClientLogo client={row.client} size="small" includeLink={isAuthorised(["CLIENT_DETAILS.VIEW"])} />;
      },
      sortOrder: sortOrderByColumn["client"],
      sorter: {
        compare: (a, b) => {
          if (!a.client && !b.client) {
            return 0;
          }
          if (!a.client && b.client) {
            return -1;
          }
          if (a.client && !b.client) {
            return 1;
          }
          if (a.client.name < b.client.name) {
            return -1;
          }
          if (a.client.name > b.client.name) {
            return 1;
          }
          return 0;
        },
      },
    },

    {
      title: "Requested on",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (createdAt) => {
        return moment(createdAt).format("DD-MM-YYYY");
      },
      sortOrder: sortOrderByColumn["createdAt"],
      sorter: {
        compare: (a, b) => {
          if (a.createdAt < b.createdAt) {
            return -1;
          }
          if (a.createdAt > b.createdAt) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Updated on",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 120,
      render: (updatedAt) => {
        return moment(updatedAt).format("DD-MM-YYYY");
      },
      sortOrder: sortOrderByColumn["updatedAt"],
      sorter: {
        compare: (a, b) => {
          if (a.updatedAt < b.updatedAt) {
            return -1;
          }
          if (a.updatedAt > b.updatedAt) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Desired date",
      dataIndex: "requestedForDate",
      key: "requestedForDate",
      width: 120,
      render: (requestedForDate) => {
        return moment(requestedForDate).format("DD-MM-YYYY");
      },
      sortOrder: sortOrderByColumn["requestedForDate"],
      sorter: {
        compare: (a, b) => {
          if (!a.requestedForDate && b.requestedForDate) {
            return -1;
          }
          if (a.requestedForDate && !b.requestedForDate) {
            return 1;
          }
          if (a.requestedForDate < b.requestedForDate) {
            return -1;
          }
          if (a.requestedForDate > b.requestedForDate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Requested by",
      key: "requestedBy",
      dataIndex: "requestedBy",
      sortOrder: sortOrderByColumn["requestedBy"],
      align: "left",
      render: (requestedBy) => {
        return <Avatar user={users.find((x) => x.id === requestedBy)} showLabel />;
      },
      sorter: {
        compare: (a, b) => {
          if (!a.requestedBy && b.requestedBy) {
            return -1;
          }
          if (a.requestedBy && !b.requestedBy) {
            return 1;
          }
          if (a.requestedBy < b.requestedBy) {
            return -1;
          }
          if (a.requestedBy > b.requestedBy) {
            return 1;
          }
          return 0;
        },
      },
    },
    !organisationDetails.settings?.request?.hideAssignedTo && {
      title: "Assigned to",
      key: "assignedTo",
      align: "left",
      render: (_, row) => {
        return <Avatar user={users.find((x) => x.id === row.assignedTo)} showLabel />;
      },
      sortOrder: sortOrderByColumn["assignedTo"],
      sorter: {
        compare: (a, b) => {
          if (!a.assignedTo && b.assignedTo) {
            return -1;
          }
          if (a.assignedTo && !b.assignedTo) {
            return 1;
          }
          if (a.assignedTo < b.assignedTo) {
            return -1;
          }
          if (a.assignedTo > b.assignedTo) {
            return 1;
          }
          return 0;
        },
      },
    },
  ].filter((x) => x);

  const dataSource: TableRow[] = filteredRequests.map((request) => {
    const project = projects.find((project) => project.id === request.projectId);
    const client = clients.find((client) => client.id === project?.clientId);
    return {
      ...request,
      client,
      project,
    };
  });

  return (
    <Table
      className="requests-table no-hover"
      columns={columns}
      dataSource={[...dataSource].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))}
      pagination={{ pageSize: 100, hideOnSinglePage: true }}
      bordered={true}
      tableLayout="fixed"
      onChange={onTableChange}
    />
  );
}

export default withSubscriptions({
  Component: RequestsTable,
  subscriptions: ["users", "clients", "projects"],
});
