import React, { useEffect } from "react";
// import { Menu, Dropdown } from "antd";
import { useGetSetState } from "react-use";
import { CopyOutlined, PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import cx from "classnames";
import TimelineBlock from "TimelinePage/TimelineBlock/TimelineBlock";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";

import "./TimelineRow.scss";

const THRESHOLD_FOR_DRAG = 5;

export default function TimelineRow({
  user,
  userDragHighlight,
  planningStartDate,
  planningEndDate,
  isDraggingBlock,
  timelineBlocks,
  projectsById,
  dragState,
  setTimelineState,
  pasteBlock,
  blockInClipboard,
  onRowDrop,
  onRowDragOver,
  addBlockToUser,
  timelineRef,
  eventForLastClick,
  showLockedModal,
  removeBlock,
  renameBlock,
  getPropertiesFromBlock,
  organisationDetails,
  reflowTimeline,
  holidays,
  getNonWorkingDaysForUser,
  propsForTimelineBlock,
  tasksById,
  style,
  isRectangleInViewport,
  users,
  validUsers,
  rowIndex,
}) {
  const [getState, setState] = useGetSetState({
    lastInternalMouseX: null,
    lastInternalMouseY: null,
    lastExternalMouseX: null,
    lastExternalMouseY: null,
    isContextMenuVisible: false,
  });

  useEffect(() => {
    window.addEventListener("click", onWindowClick);
    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, []);

  function onWindowClick(e) {
    let target = e.target;
    try {
      if (target.classList.contains("user-row") && target.dataset?.userId === user.id) {
        return;
      }
    } catch (e) {
      debugger;
    }

    if (!getState().isContextMenuVisible) {
      return;
    }

    setState({
      isContextMenuVisible: false,
    });
  }

  const axisStartDate = moment(planningStartDate);
  function setSelectedBlockForTooltip(block) {
    setTimelineState({
      selectedBlockForTooltip: block,
    });
  }

  function getPropsForTimelineBlock() {
    return {
      ...propsForTimelineBlock,
      isRectangleInViewport,
      rowTop: style.top,
      rowHeight: style.height,
      user,
      users,
      tasksById,
      userDragHighlight,
      planningStartDate,
      planningEndDate,
      isDraggingBlock,
      timelineBlocks,
      projectsById,
      dragState,
      pasteBlock,
      blockInClipboard,
      onRowDrop,
      onRowDragOver,
      timelineRef,
      eventForLastClick,
      showLockedModal,
      removeBlock,
      renameBlock,
      organisationDetails,
      reflowTimeline,
      holidays,
      getNonWorkingDaysForUser,
      axisStartDate,
      getPropertiesFromBlock,
      addBlockToUser,
      validUsers,
      rowIndex,
      setSelectedBlockForTooltip,
    };
  }

  function displayMaintenanceIntervalsForStockItem(stockItem) {
    return (stockItem.maintenanceIntervals || []).map((maintenanceInterval, i) => {
      let durationHours =
        (moment(maintenanceInterval.endDate)
          .endOf("day")
          .diff(moment(maintenanceInterval.startDate).startOf("day"), "days") +
          1) *
        TIMELINE_DEFAULT_HOURS_IN_A_DAY;
      return (
        <TimelineBlock
          {...getPropsForTimelineBlock()}
          key={i}
          block={{
            id: maintenanceInterval.id,
            startDate: maintenanceInterval.startDate,
            organisation: stockItem.organisation,
            taskId: maintenanceInterval.content,
            startHours: 0,
            durationHours,
            isPseudoTask: true,
          }}
          isOnTop
          isRestricted={true}
          isInteractive={false}
        />
      );
    });
  }

  function displayHolidaysForUser() {
    let holidayDaysToDisplay = [];
    const holidaysForUser = holidays.filter((holiday) => holiday.userId === user.id && holiday.status !== "REJECTED");

    holidaysForUser.forEach((holiday) => {
      holiday.days.forEach((holidayDay) => {
        let day = holidayDay.day?.split("T")[0];
        if (day >= planningStartDate && day <= planningEndDate) {
          holidayDaysToDisplay.push({ ...holidayDay, holiday });
        }
      });
    });

    return holidayDaysToDisplay.map((holidayDay, i) => {
      let startHours = 0;
      let durationHours = 0;

      if (holidayDay.endHours) {
        startHours = holidayDay.startHours;
        durationHours = holidayDay.endHours - holidayDay.startHours;
      } else {
        startHours = 0;
        durationHours = TIMELINE_DEFAULT_HOURS_IN_A_DAY;
      }

      return (
        <TimelineBlock
          {...getPropsForTimelineBlock()}
          key={i}
          block={{
            id: `${holidayDay.holiday.id}-${holidayDay.day}`,
            startDate: holidayDay.day,
            startHours,
            durationHours,
            organisation: holidayDay.holiday.organisation,
            taskId: holidayDay.holiday.isSick ? "SICK DAY" : "HOLIDAY",
            isPseudoTask: true,
            createdAt: holidayDay.holiday.createdAt,
          }}
          isRestricted={true}
          approvalStatus={holidayDay.holiday.status}
          isInteractive={false}
        />
      );
    });
  }

  function displayNonWorkingDaysForUser() {
    let nonWorkingDaysToDisplay = getNonWorkingDaysForUser({
      user,
      axisStartDate: planningStartDate,
      axisEndDate: planningEndDate,
    });

    return nonWorkingDaysToDisplay.map((day, i) => {
      return (
        <TimelineBlock
          {...getPropsForTimelineBlock()}
          key={i}
          block={{
            id: `${day}-${i}`,
            startDate: day,
            startHours: 0,
            durationHours: TIMELINE_DEFAULT_HOURS_IN_A_DAY,
            organisation: user.organisation,
            taskId: "",
            isPseudoTask: true,
          }}
          isOutOfWorkingHours={true}
          isRestricted={true}
          isInteractive={false}
          onTouchStart={() => {
            setTimelineState({ isTouchDown: true });
          }}
        />
      );
    });
  }

  const { lastInternalMouseX, lastInternalMouseY, isContextMenuVisible } = getState();

  let dragProps = {};

  // if (!user.isStockItem) {
  dragProps = {
    ...dragProps,
    onDrop: (e) => onRowDrop(e, user),
    onDragOver: (e) => onRowDragOver(e, user),
  };
  // }

  let hasContextMenu = true;

  // if (user.isStockItem) {
  // hasContextMenu = false;
  // }

  let timelineBlocksForUser = timelineBlocks.filter(
    (block) =>
      block.userId === user.id &&
      moment(block.startDate).isSameOrAfter(planningStartDate) &&
      moment(block.startDate).isSameOrBefore(planningEndDate)
  );

  return (
    <div
      className={cx("user-row", {
        "drag-highlight": userDragHighlight === user.id,
      })}
      style={style}
      {...dragProps}
      data-cy="user-row"
      data-user-id={user.id}
      onMouseDown={(e) => {
        dragState.lastX = e.pageX;

        setState({
          lastExternalMouseX: e.pageX,
          lastExternalMouseY: e.pageY,
        });

        setTimelineState({
          startTimelineScrollLeft: timelineRef.current ? timelineRef.current.scrollLeft : null,
          dragStartCoordinates: { x: e.pageX, y: e.pageY },
        });
      }}
      onClick={(e) => {
        // if the event happened inside a row-menu element or one of its children, do not trigger this

        setTimelineState({
          selectedUserForLastClick: user,
          eventForLastClick: e,
        });

        if (e.target.closest(".row-menu")) {
          return;
        }

        const { lastInternalMouseX, lastExternalMouseX, lastExternalMouseY, isContextMenuVisible } = getState();

        const currentExternalMouseX = e.pageX;

        setState({
          lastInternalMouseX: e.nativeEvent.offsetX,
          lastInternalMouseY: e.nativeEvent.offsetY,
        });

        const currentInternalMouseX = e.nativeEvent.offsetX;

        if (!isContextMenuVisible) {
          if (
            Math.abs(lastExternalMouseX - currentExternalMouseX) > THRESHOLD_FOR_DRAG ||
            Math.abs(lastExternalMouseY - e.pageY) > THRESHOLD_FOR_DRAG
          ) {
            return;
          }
          setState({
            isContextMenuVisible: true,
          });
        } else {
          if (Math.abs(lastInternalMouseX - currentInternalMouseX) < THRESHOLD_FOR_DRAG) {
            return;
          }

          setState({
            isContextMenuVisible: false,
          });
        }
      }}
    >
      {hasContextMenu && isContextMenuVisible && lastInternalMouseX && lastInternalMouseY && (
        <div
          data-cy="timeline-row-context-menu"
          data-user-id={user.id}
          className={cx("row-menu")}
          style={{
            top: lastInternalMouseY,
            left: lastInternalMouseX,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setState({
              isContextMenuVisible: false,
            });
          }}
        >
          {blockInClipboard && (
            <div
              className="menu-item"
              onClick={() =>
                pasteBlock({
                  e: eventForLastClick,
                  selectedUser: user,
                })
              }
            >
              <CopyOutlined /> Paste block
            </div>
          )}
          {!organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks &&
            !organisationDetails.settings?.timeline?.planTaskStatusesInsteadOfTasks && (
              <div
                className="menu-item"
                onClick={(e) => {
                  e.stopPropagation();
                  setTimelineState({
                    isNewTimelineBlockModalVisible: true,
                    isContextMenuVisible: false,
                  });
                }}
              >
                <PlusCircleOutlined /> New block
              </div>
            )}
          <div className="menu-item">Cancel</div>
        </div>
      )}

      {!user.isStockItem && displayHolidaysForUser(user)}
      {user.isStockItem && displayMaintenanceIntervalsForStockItem(user)}
      {!user.isStockItem && displayNonWorkingDaysForUser(user)}
      {timelineBlocksForUser.map((block) => (
        <TimelineBlock
          {...getPropsForTimelineBlock()}
          key={block.id}
          block={block}
          onResizeStart={() => setTimelineState({ isResizingBlock: true })}
          onResizeEnd={() => setTimelineState({ isResizingBlock: false })}
          onRemoveBlock={() => removeBlock(block)}
          onRenameBlock={(name) => renameBlock({ block, name })}
          onCopyBlock={(e) => {
            setTimelineState({ blockInClipboard: block });
          }}
          onOpenTask={() =>
            setTimelineState({
              selectedBlock: block,
              isTaskDetailsModalVisible: true,
            })
          }
          onDragStart={() => setTimelineState({ isDraggingBlock: true })}
        />
      ))}
    </div>
  );
}
