import cx from "classnames";
import { Droppable } from "react-beautiful-dnd";
import { Typography } from "antd";

import "./Lane.scss";

export default function Lane({ lane, viewType, showTitle = true, children }) {
  let itemCountLabel;
  if (lane.allItemCount === undefined || lane.allItemCount === null || lane.allItemCount === lane.itemCount) {
    itemCountLabel = (
      <>
        ({lane.itemCount} item{lane.itemCount !== 1 ? "s" : ""})
      </>
    );
  } else {
    itemCountLabel = `(${lane.itemCount} visible item${lane.itemCount !== 1 ? "s" : ""}, ${
      lane.allItemCount - lane.itemCount
    } more
        hidden by filters)`;

    if (!showTitle) {
      itemCountLabel = itemCountLabel.substring(1, itemCountLabel.length - 1);
    }
  }

  return (
    <div
      className={cx("lane", (viewType || "").toLowerCase())}
      data-cy={`lane`}
      data-title={`${lane.label.toLowerCase().split(" ").join("-")}`}
    >
      <Typography.Paragraph className="lane-title" data-cy="lane-title">
        {showTitle ? lane.label : ""} <Typography.Text className="item-count">{itemCountLabel}</Typography.Text>
        {lane.suffix || null}
      </Typography.Paragraph>

      <Droppable droppableId={lane.status}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={{ overflowY: "auto" }}>
            <div className="inner-list">
              {children}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
}
