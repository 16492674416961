import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { message } from "antd";

import withSubscriptions from "common/withSubscriptions";
import changeTaskStatus from "common/changeTaskStatus";

/* example automation object:
  {
    "trigger": "ADD_REQUEST_FORM_TO_TASK_REVISION",
    "action": "CHANGE_TASK_STATUS",
    "actionParams": {
      "newStatus": "FOR_APPROVAL"
    }
  }
*/

export function AutomationListener({ organisationDetails }) {
  useEffect(() => {
    window.dispatchAutomationEvent = function (eventName, details) {
      const automations = organisationDetails.variables.items
        .filter((variable) => {
          if (variable.type !== "AUTOMATION") {
            return false;
          }

          try {
            JSON.parse(variable.value);
          } catch {
            message.error("Cannot run automation, invalid JSON in automation value:", variable.value);
            return false;
          }
          return true;
        })
        .map((automation) => {
          return {
            ...automation,
            value: JSON.parse(automation.value),
          };
        });
      let targetAutomations = automations.filter((automation) => {
        return automation.value.trigger === eventName;
      });
      for (let automation of targetAutomations) {
        runAutomation(automation, details);
      }
    };
  }, []);

  async function runAutomation(automation, details) {
    console.log("running automation:", automation.name);
    switch (automation.value.action) {
      case "CHANGE_TASK_STATUS":
        await changeTaskStatusInternal(automation, details);
        break;
      default:
        message.error("Unknown action in automation:", automation.value.action);
        break;
    }
  }

  async function changeTaskStatusInternal(automation, details) {
    await changeTaskStatus({
      taskId: details.taskId,
      status: automation.value.actionParams.newStatus,
      organisationDetails,
    });
  }

  return null;
}

export default withRouter(
  withSubscriptions({
    Component: AutomationListener,
    subscriptions: ["organisationDetails"],
  })
);
