import { useState } from "react";
import { Button, Typography, Tag, Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import ProjectStatusModal from "Modals/ProjectStatusModal/ProjectStatusModal";

import "./ProjectStatusList.scss";

function ProjectStatusList({ organisationDetails, setProps, context }) {
  const [selectedStatus, setSelectedStatus] = useState(); // eslint-disable-line
  const [selectedIndex, setSelectedIndex] = useState(); // eslint-disable-line
  const [isProjectStatusModalVisible, setIsProjectStatusModalVisible] = useState(false);

  const reorder = (startIndex, endIndex) => {
    const result = Array.from(organisationDetails.projectStatuses);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDeleteClick(projectStatus) {
    Modal.confirm({
      title: `Are you sure you want to delete the ${getSimpleLabel("project")} status "${projectStatus.name}"?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await deleteProjectStatus(projectStatus);
      },
    });
  }

  async function deleteProjectStatus(projectStatus) {
    const newProjectStatuses = organisationDetails.projectStatuses?.filter((x) => x.name !== projectStatus.name);
    setProps({
      context: {
        ...context,
        organisation: {
          ...organisationDetails,
          projectStatuses: newProjectStatuses,
        },
        organisationDetails: {
          ...organisationDetails,
          projectStatuses: newProjectStatuses,
        },
      },
    });
    await callGraphQLSimple({
      message: `Could not delete ${getSimpleLabel("project")} status`,
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          projectStatuses: newProjectStatuses,
        },
      },
    });
  }

  async function onDragEnd(result) {
    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedProjectStatuses = reorder(result.source.index, result.destination.index);
    setProps({
      context: {
        ...context,
        organisation: {
          ...organisationDetails,
          projectStatuses: reorderedProjectStatuses,
        },
        organisationDetails: {
          ...organisationDetails,
          projectStatuses: reorderedProjectStatuses,
        },
      },
    });
    await callGraphQLSimple({
      message: `Could not reorder ${getSimpleLabel("project")} statuses`,
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          projectStatuses: reorderedProjectStatuses,
        },
      },
    });
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className="project-status-list" {...provided.droppableProps} ref={provided.innerRef}>
              {organisationDetails.projectStatuses?.map((projectStatus, index) => {
                return (
                  <Draggable
                    key={projectStatus.name}
                    draggableId={projectStatus.name}
                    index={index}
                    data-cy={`projectStatus-${projectStatus}`}
                    className={`draggable-project-status ${projectStatus.name.split(" ").join("")}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="project-status-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {/* <Typography.Text className="type" data-cy="project-status-type">
                          {TASK_STATUS_TYPES_READABLE[projectStatus.type]}
                        </Typography.Text> */}
                        <Typography.Text className="name" data-cy="project-status-name">
                          {projectStatus.name}
                        </Typography.Text>
                        {projectStatus.canSkipReview && (
                          <Tag color="blue" style={{ marginLeft: "0.5rem" }}>
                            Can skip review
                          </Tag>
                        )}
                        <div className="actions">
                          <Button
                            key={`project-status-edit-${projectStatus.name}`}
                            icon={<EditOutlined />}
                            className="edit-project-status"
                            onClick={() => {
                              setSelectedIndex(index);
                              setSelectedStatus(projectStatus);
                              setIsProjectStatusModalVisible(true);
                            }}
                          />
                          <Button
                            key={`project-status-delete-${projectStatus.name}`}
                            icon={<DeleteOutlined />}
                            className="delete-project-status"
                            onClick={() => onDeleteClick(projectStatus)}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isProjectStatusModalVisible && (
        <ProjectStatusModal
          visible={true}
          statusDetails={selectedStatus}
          index={selectedIndex}
          onClose={() => setIsProjectStatusModalVisible(false)}
        />
      )}
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ProjectStatusList,
    subscriptions: ["organisationDetails"],
  })
);
